import React, { useEffect, useState } from 'react';
import ConnectWallet from '../connect-wallet/ConnectWallet';
import { isMobile } from 'react-device-detect';
import { Link } from "react-router-dom";
import { Drawer, Collapse, Select, Popover } from 'antd';
import { useActiveWeb3React } from "../../hooks";
import { CaretDownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import en from "../lang/en/common.json"
import cn from "../lang/cn/common.json"
import ru from "../lang/ru/common.json"
import "../style.scss"
import switchNetworkChain from "../../utils/walletChain";
import { MAPPING_CHAINID } from "../../constants";
import { useLocation } from "react-router-dom";

declare const window: Window & typeof globalThis & { ethereum: any };

let langChange: any = localStorage.getItem("lang") || "cn"

i18next.init({
    interpolation: { escapeValue: false },
    lng: langChange,
    resources: {
        en: {
            common: en
        },
        cn: {
            common: cn
        },
        ru: {
            common: ru
        },
    },
});

const Header = (props: any) => {
    let chainValue = props?.chainHeader?.chain ? props?.chainHeader?.chain : "bep"
    
    const { t, i18n }: any = useTranslation('common');
    const { account, chainId } = useActiveWeb3React();
    const { Option } = Select;
    const [open, setOpen] = useState(false);
    const [valueChain, setValueChain] = useState<any>("56");
    const [langMulti, setLangMulti] = useState<any>(langChange);
    const { Panel } = Collapse;

    // if(chainId == 8453){
    //     chainValue = "base";
    // }

    

    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    }

    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 1);
        });
    }, []);

    const changeLang = (value: any) => {
        window.localStorage.setItem('lang', value);
        i18n.changeLanguage(`${value}`)
        setLangMulti(value)
    }

    useEffect(() => {
        setLangMulti(window.localStorage.getItem('lang'))
        i18n.changeLanguage(window.localStorage.getItem('lang'))
    }, [langMulti]);

    const changeNetwork = async (chainId: any, value: any) => {
        setValueChain(value.value);
        try {
            await window.ethereum.enable();
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: `0x${parseInt(chainId).toString(16)}` }],
            });
        } catch (error) {
            //@ts-ignore
            
            if (error.code === 4902) {
                try {
                    if (Number(value.value) === 81457) {
                        await window.ethereum.request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    chainName: "Blast",
                                    chainId: "0x13e31",
                                    rpcUrls: ["https://rpc.blast.io"],
                                    nativeCurrency: {
                                        name: "Blast",
                                        symbol: "ETH",
                                        decimals: 18,
                                    },
                                },
                            ],
                        });
                    } else if (Number(value.value) === 56) {
                        await window.ethereum.request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    chainName: "BNB Chain Mainnet",
                                    chainId: "0x38",
                                    rpcUrls: ["https://bsc-dataseed.binance.org"],
                                    nativeCurrency: {
                                        name: "BNB",
                                        symbol: "BNB",
                                        decimals: 18,
                                    },
                                },
                            ],
                        });
                    } else if (Number(value.value) === 42161) {
                        await window.ethereum.request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    chainName: "Arbitrum One",
                                    chainId: "0xa4b1",
                                    rpcUrls: ["https://arb1.arbitrum.io/rpc"],
                                    nativeCurrency: {
                                        name: "Arbitrum One",
                                        symbol: "ETH",
                                        decimals: 18,
                                    },
                                },
                            ],
                        });
                    }else if (Number(value.value) === 8453) {
                        await window.ethereum.request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    chainName: "Base",
                                    chainId: "0x2105",
                                    rpcUrls: ["https://base-pokt.nodies.app"],
                                    nativeCurrency: {
                                        name: "Base",
                                        symbol: "ETH",
                                        decimals: 18,
                                    },
                                },
                            ],
                        });
                    }else if (Number(value.value) === 8453) {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                chainName: 'Avalanche Network',
                                chainId: '0xa86a',
                                rpcUrls: ["https://snowtrace.io/"],
                                nativeCurrency: {
                                    name: 'AVAX',
                                    symbol: 'AVAX',
                                    decimals: 18
                                }
                                },
                            ],
                        });
                    }
                    
                } catch (addError) {
                    console.log("addError", addError);
                    // handle "add" error
                }
            }
            console.error(error);
        }
    };

    const fillterChain: any = (() => {

        switch (chainValue) {
            case "bep":
                return 56;
            case "blast":
                return 81457;
            case "arb":
                return 42161;
            case "base":
                return 8453;
            case "avax":
                return 43114;
            default:
                return "---";
        }
    });

    const location = useLocation();
    let pathNow = location.pathname.split("/")
    let pathNetwork = pathNow[1]
    const handleSwitch = () => {
        if (pathNetwork === "ido-details" && account !== undefined) {
            switchNetworkChain(
                MAPPING_CHAINID[chainValue],
                chainValue
            );
        }
        if (account !== undefined) {
            switchNetworkChain(
                MAPPING_CHAINID["bep"],
                "bep"
            );
        }
    }

    const MenuMobile = () => {
        return (
            <>
                <header className={scroll ? "main-header scroll" : "main-header"}>
                    <div className="container">
                        <div className="header-content">
                            <div className="header-l">
                                <div className="logo">
                                    <Link to="/">
                                        <img src="./images/logo.png" alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="header-right">
                                {chainId === fillterChain(chainValue) ?
                                    <>
                                        <div className="ant-select-filter">
                                            <Select
                                                value={chainId ? chainId.toString() : "Select Network"}
                                                onChange={changeNetwork}
                                                suffixIcon={<CaretDownOutlined rev={undefined} />}
                                            >
                                                <Option value="56">
                                                    <img src="./images/bnb.png" alt="" />{" "}
                                                    <span className="text-chain">BNB Chain</span>
                                                </Option>
                                                <Option value="81457">
                                                    <img src="./images/blast.svg" alt="" />{" "}
                                                    <span className="text-chain">Blast Chain</span>
                                                </Option>
                                                <Option value="42161">
                                                    <img src="./images/arb.png" alt="" />{" "}
                                                    <span className="text-chain">Arbitrum</span>
                                                </Option>
                                                <Option value="8453">
                                                    <img src="./images/base.svg" alt="" />{" "}
                                                    <span className="text-chain">Base</span>
                                                </Option>
                                                <Option value="43114">
                                                    <img src="./images/avax.svg" alt="" />{" "}
                                                    <span className="text-chain">Avalanche</span>
                                                </Option>
                                            </Select>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {account ?
                                            <>
                                                <Popover className="pop-ant" content={content} title="Title" trigger="click">
                                                    <button className="btn-danger">
                                                        <img src="./images/danger.png" alt="" /> <span> {t('ido.network', { framework: 'React' })} </span> <CaretDownOutlined rev={undefined} />
                                                    </button>
                                                </Popover>
                                            </>
                                            :
                                            <>
                                                {""}
                                            </>
                                        }
                                    </>
                                }
                                <div className="row-connect">
                                    <ConnectWallet />
                                </div>
                            </div>
                            <div className="header-bar">
                                <button type="button" className="button-bar-click" onClick={showDrawer}>
                                    <img src="./images/bar-menu-open.png" alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </header>
                <Drawer className="drawer-menu" title="Basic Drawer" placement="right" onClose={onClose} open={open}>
                    <div className="menu-group">
                        <div className="top-menu">
                            <div className="logo-drawer">
                                <Link to="/">
                                    <img src="./images/logo.png" alt="" />
                                </Link>
                            </div>
                            <div className="ant-select-filter row">
                                <Select
                                    value={langMulti ? langMulti : "cn"}
                                    onChange={changeLang}
                                    suffixIcon={<CaretDownOutlined rev={undefined} />}
                                >
                                    <Option value="en">
                                        <span className="text-chain">English</span>
                                    </Option>
                                    <Option value="cn">
                                        <span className="text-chain">中文</span>
                                    </Option>
                                    <Option value="ru">
                                        <span className="text-chain">Russian</span>
                                    </Option>
                                </Select>
                            </div>
                            <div className="icon-bar-clone" onClick={onClose}>
                                <img src="./images/bar-menu-close.png" alt="" />
                            </div>
                        </div>
                        <div className="center-menu">
                            <Collapse defaultActiveKey={['1']} onChange={onChange} expandIcon={({ isActive }) => (
                                <img src={isActive ? "./images/minus.png" : "./images/add.png"} alt="" />
                            )}>
                                <Panel header={t('menu.menu-1', { framework: 'React' })} key="1">
                                    <ul className="list-menu-drawer">
                                        <Link onClick={handleClose} to="/ido">
                                            <li>
                                                <div className="li-left">
                                                    <img src="./images/sub-1.png" alt="" />
                                                </div>
                                                <div className="li-right">
                                                    <div className="text">
                                                        {t('menu.menu-1', { framework: 'React' })}
                                                    </div>
                                                    <div className="desc">
                                                        {t('menu.menu-6', { framework: 'React' })}
                                                    </div>
                                                </div>
                                            </li>
                                        </Link>
                                    </ul>
                                </Panel>
                                <Panel header={t('menu.menu-2', { framework: 'React' })} key="2">
                                    <ul className="list-menu-drawer">
                                        <Link onClick={handleClose} to="/ido-pools">
                                            <li>
                                                <div className="li-left">
                                                    <img src="./images/sub-2.png" alt="" />
                                                </div>
                                                <div className="li-right">
                                                    <div className="text">
                                                        {t('menu.menu-2', { framework: 'React' })}
                                                    </div>
                                                    <div className="desc">
                                                        {t('menu.menu-8', { framework: 'React' })}
                                                    </div>
                                                </div>
                                            </li>
                                        </Link>
                                    </ul>
                                </Panel>
                                <Panel header={t('menu.menu-3', { framework: 'React' })} key="3">
                                    <ul className="list-menu-drawer">
                                        <Link onClick={handleClose} to="/start-pool">
                                            <li>
                                                <div className="li-left">
                                                    <img src="./images/sub-3.png" alt="" />
                                                </div>
                                                <div className="li-right">
                                                    <div className="text">
                                                        {t('menu.menu-8', { framework: 'React' })} <span>({t('menu.menu-5', { framework: 'React' })})</span>
                                                    </div>
                                                    <div className="desc">
                                                        {t('menu.menu-8', { framework: 'React' })}
                                                    </div>
                                                </div>
                                            </li>
                                        </Link>
                                        <Link onClick={handleClose} to="/farms">
                                            <li>
                                                <div className="li-left">
                                                    <img src="./images/sub-4.png" alt="" />
                                                </div>
                                                <div className="li-right">
                                                    <div className="text">
                                                        {t('menu.menu-7', { framework: 'React' })} <span>({t('menu.menu-5', { framework: 'React' })})</span>
                                                    </div>
                                                    <div className="desc">
                                                        {t('menu.menu-6', { framework: 'React' })}
                                                    </div>
                                                </div>
                                            </li>
                                        </Link>
                                    </ul>
                                </Panel>
                                <Panel header={t('menu.menu-9', { framework: 'React' })} key="4">
                                    <ul className="list-menu-drawer">
                                        <li onClick={handleClose}>
                                            <div className="li-left">
                                                <img src="./images/g-1.png" alt="" />
                                            </div>
                                            <div className="li-right">
                                                <div className="text">
                                                    {t('menu.menu-10', { framework: 'React' })} <span>({t('menu.menu-5', { framework: 'React' })})</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li onClick={handleClose}>
                                            <div className="li-left">
                                                <img src="./images/g-2.png" alt="" />
                                            </div>
                                            <div className="li-right">
                                                <div className="text">
                                                    {t('menu.menu-11', { framework: 'React' })} <span>({t('menu.menu-5', { framework: 'React' })})</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li onClick={handleClose}>
                                            <div className="li-left">
                                                <img src="./images/g-3.png" alt="" />
                                            </div>
                                            <div className="li-right">
                                                <div className="text">
                                                    {t('menu.menu-12', { framework: 'React' })} <span>({t('menu.menu-5', { framework: 'React' })})</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Panel>
                            </Collapse>
                        </div>
                        <div className="bottom-menu">
                            <div className="menu-social">
                                <div className="item">
                                    <a href="https://twitter.com/fv_launch" target="_blank"><img src="./images/x.png" alt="" /></a>
                                </div>
                                <div className="item">
                                    <a href="https://telegram.me/fvlaunch" target="_blank"><img src="./images/tele.png" alt="" /></a>
                                </div>
                                <div className="item">
                                    <a href="https://www.youtube.com/@FVLaunch" target="_blank"><img src="./images/you.png" alt="" /></a>
                                </div>
                                <div className="item">
                                    <a href="https://linktr.ee/fvlaunch" target="_blank"><img src="./images/lw.png" alt="" /></a>
                                </div>
                            </div>
                            {/* <div className="buy-drawer">
                                <button className="buy-now">
                                    Buy FV
                                </button>
                            </div> */}
                        </div>
                    </div>
                </Drawer>
            </>
        )
    }



    const content = (
        <>
            <div className="box-wrong">
                <div className="title">
                    {t('ido.ido-76', { framework: 'React' })}
                </div>
                <div className="item">
                    <div className="icon">
                        <img src="./images/err.png" alt="" />
                    </div>
                    <div className="text">
                        {t('ido.ido-78', { framework: 'React' })}  {chainId}
                    </div>
                </div>
                <div className="img">
                    <img src="./images/down-row.png" alt="" />
                </div>
                <div className="item">
                    <div className="icon">
                        {chainValue == "avax" && (
                            <>
                            <img src="./images/avax.svg" alt="" />
                            </>
                        )}
                        {chainValue == "base" && (
                            <>
                            <img src="./images/base.svg" alt="" />
                            </>
                        )}
                        {chainValue == "blast" && (
                            <>
                            <img src="./images/blast.svg" alt="" />
                            </>
                        )}
                        {chainValue == "arb" && (
                            <>
                            <img src="./images/arb.png" alt="" />
                            </>
                        )}
                        {chainValue == "bsc" || chainValue == "bep" && (
                            <>
                            <img src="./images/bnb.png" alt="" />
                            </>
                        )}
                        
                    </div>
                    <div className="text res">
                        {chainValue == "avax" && (
                            <>
                                Avalanche
                            </>
                        )}
                        {chainValue == "base" && (
                            <>
                                Base Chain
                            </>
                        )}
                        {chainValue == "bep" || chainValue == "bsc" && (
                            <>
                                BSC Chain
                            </>
                        )}
                        {chainValue == "blast" || chainValue == "arb" && (
                            <>
                                {chainValue} {t('ido.ido-78', { framework: 'React' })}
                            </>
                        )}

                        {/* {chainValue == "base" ? (
                            <>
                                Base Chain
                            </>
                        ):(
                            <>
                                {chainValue === "blast" || chainValue === "arb" ? chainValue : "BNB"} {t('ido.ido-78', { framework: 'React' })}
                            </>
                        )} */}
                        
                    </div>
                </div>
                <div className="group-button">
                    <button className="btn-switch" onClick={handleSwitch}>
                        {t('ido.ido-77', { framework: 'React' })}
                    </button>
                </div>
            </div>
        </>
    );

    return (
        <>
            <header className={scroll ? "main-header scroll" : "main-header"}>
                <div className="container-header">
                    <div className="header-content">
                        {isMobile ?
                            <>
                                <MenuMobile />
                            </>
                            :
                            <>
                                <div className="header-l">
                                    <div className="logo">
                                        <Link to="/">
                                            <img src="./images/logo.png" alt="" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="header-center">
                                    <div className="main-menu">
                                        <ul className="list-menu">
                                            <li>
                                                <Link to="/ido"> {t('menu.menu-1', { framework: 'React' })}</Link>
                                            </li>
                                            <li>
                                                <Link to="/ido-pools">{t('menu.menu-2', { framework: 'React' })}</Link>
                                            </li>
                                            <li>
                                                <a href="#">{t('menu.menu-3', { framework: 'React' })} <img src="./images/down.png" alt="" /></a>
                                                <div className="sub-menu">
                                                    <ul className="list-sub-menu">
                                                        <li>
                                                            <Link to="#" className="a-sub">
                                                                <div className="txt-l">
                                                                    <img src="./images/sub-3.png" alt="" />
                                                                </div>
                                                                <div className="txt-r">
                                                                    <div className="text">
                                                                        {t('menu.menu-3', { framework: 'React' })} <span>({t('menu.menu-5', { framework: 'React' })})</span>
                                                                    </div>
                                                                    <div className="desc">
                                                                        {t('menu.menu-6', { framework: 'React' })}
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" className="a-sub">
                                                                <div className="txt-l">
                                                                    <img src="./images/sub-4.png" alt="" />
                                                                </div>
                                                                <div className="txt-r">
                                                                    <div className="text">
                                                                        {t('menu.menu-7', { framework: 'React' })} <span>({t('menu.menu-5', { framework: 'React' })})</span>
                                                                    </div>
                                                                    <div className="desc">
                                                                        {t('menu.menu-8', { framework: 'React' })}
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <a href="#">{t('menu.menu-9', { framework: 'React' })} <img src="./images/down.png" alt="" /></a>
                                                <div className="sub-menu">
                                                    <ul className="list-sub-menu">
                                                        <li>
                                                            <Link to="#" className="a-sub">
                                                                <div className="txt-l">
                                                                    <img src="./images/g-1.png" alt="" />
                                                                </div>
                                                                <div className="txt-r">
                                                                    <div className="text">
                                                                        {t('menu.menu-10', { framework: 'React' })} <span>({t('menu.menu-5', { framework: 'React' })})</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" className="a-sub">
                                                                <div className="txt-l">
                                                                    <img src="./images/g-2.png" alt="" />
                                                                </div>
                                                                <div className="txt-r">
                                                                    <div className="text">
                                                                        {t('menu.menu-11', { framework: 'React' })} <span>({t('menu.menu-5', { framework: 'React' })})</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" className="a-sub">
                                                                <div className="txt-l">
                                                                    <img src="./images/g-3.png" alt="" />
                                                                </div>
                                                                <div className="txt-r">
                                                                    <div className="text">
                                                                        {t('menu.menu-12', { framework: 'React' })} <span>({t('menu.menu-5', { framework: 'React' })})</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="header-right">
                                    <div className="ant-select-filter row">
                                        <Select
                                            value={langMulti ? langMulti : "cn"}
                                            onChange={changeLang}
                                            suffixIcon={<CaretDownOutlined rev={undefined} />}
                                        >
                                            <Option value="en">
                                                <span className="text-chain">English</span>
                                            </Option>
                                            <Option value="cn">
                                                <span className="text-chain">中文</span>
                                            </Option>
                                            <Option value="ru">
                                                <span className="text-chain">Russian</span>
                                            </Option>
                                        </Select>
                                    </div>
                                    {account ?
                                        <>
                                            {Number(chainId) === fillterChain(chainValue) ?
                                                <>
                                                    <div className="ant-select-filter">
                                                        <Select
                                                            value={chainId ? chainId.toString() : "Select Network"}
                                                            onChange={changeNetwork}
                                                            suffixIcon={<CaretDownOutlined rev={undefined} />}
                                                        >
                                                            <Option value="56">
                                                                <img src="./images/bnb.png" alt="" />{" "}
                                                                <span className="text-chain">BNB Chain</span>
                                                            </Option>
                                                            <Option value="81457">
                                                                <img src="./images/blast.svg" alt="" />{" "}
                                                                <span className="text-chain">Blast Chain</span>
                                                            </Option>
                                                            <Option value="42161">
                                                                <img src="./images/arb.png" alt="" />{" "}
                                                                <span className="text-chain">Arbitrum</span>
                                                            </Option>
                                                            <Option value="8453">
                                                                <img src="./images/base.svg" alt="" />{" "}
                                                                <span className="text-chain">Base</span>
                                                            </Option>
                                                            <Option value="43114">
                                                                <img src="./images/avax.svg" alt="" />{" "}
                                                                <span className="text-chain">Avalanche</span>
                                                            </Option>
                                                        </Select>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <Popover className="pop-ant" content={content} title="Title" trigger="click">
                                                        <button className="btn-danger">
                                                            <img src="./images/danger.png" alt="" /> <span> {t('ido.network', { framework: 'React' })} </span> <CaretDownOutlined rev={undefined} />
                                                        </button>
                                                    </Popover>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            {""}
                                        </>
                                    }
                                    <div className="row-connect">
                                        <ConnectWallet />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </header>
        </>
    )
}
export default Header