import React, { useCallback } from 'react'
import { soushHarvestV3 } from '../utilsST/callHelpers'
import { getSousChefV3Contract } from '../utilsST/contractHelpers'
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { useContract } from "../hooks/useContract";
import sousChefV3Abi from "../config/abi/sousChefV3.json";
import poolsConfig from '../constantsST/pools'

const useSousHarvestV3 = (sousId, pool) => {
	const { account } = useWeb3React()
	const web3 = new Web3 (window.ethereum)
	// const pool = poolsConfig.find((pool) => pool.sousId === sousId);
	const contract = useContract(pool.contractAddress, sousChefV3Abi);
	const handleRewardV3 = useCallback(async () => {
		const txHash = await soushHarvestV3(contract, account)
		return txHash;
	}, [account, web3])
	return { onRewardV3: handleRewardV3 }
}

export default useSousHarvestV3
