import React, { useEffect, useState } from 'react';
import { useActiveWeb3React } from "../../../hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Table , Input} from 'antd';
import { useHookAccount } from '../store';

import type { TabsProps } from 'antd';
import { useTranslation } from "react-i18next";


const RefReferral = () => {
    const { t, i18n }: any = useTranslation('common');
    const { account, chainId }: any = useActiveWeb3React();
    const [state, actions]: any = useHookAccount();
    const { Search } = Input;

    const columns: any = [
        {
            title: t('ref.ref-28', { framework: 'React' }),
            dataIndex: 'wallet',
            key: 'wallet',
        },
        {
            title: t('ref.ref-26', { framework: 'React' }),
            dataIndex: 'level',
            key: 'level'
        },
        {
            title: t('ref.ref-29', { framework: 'React' }),
            dataIndex: 'referrer_adrress',
            key: 'age'
        },
        {
            title: t('ref.ref-30', { framework: 'React' }),
            dataIndex: 'friends',
            key: 'friends',
        },
        {
            title: t('ref.ref-31', { framework: 'React' }),
            dataIndex: 'point',
            key: 'point',
        },
    ];

    let params = {
        ownerAddress: account
    }

    useEffect(() => {
        if (account) {
            actions.getMySystem(params)
        }
    }, [account]);

    const onSearch = (e:any) => {
        let paramsSearch = {
            ownerAddress: e.target.value
        }
        actions.getMySystem(paramsSearch)
    }
    
    return (
        <>
            <div className='ref-block main-table'>
                <div className="ref-filter">
                    <div className='ref-ido-title'>{t('ref.ref-25', { framework: 'React' })} </div>
                    <div className="ref-search">
                        <Search placeholder="Enter by wallet" onChange={onSearch} />
                    </div>
                </div>
                <Table
                    className='table-ido'
                    columns={columns}
                    dataSource={state.dataMySystem}
                />
            </div>
        </>
    )
}
export default RefReferral