export enum STATUS {
    PENDING = 0,
    COMING = 1,
    GOING = 2,
    OPEN = 3,
    CLOSE = 4,
}

export enum TIER {
    Iron = 1,
    Platinum = 2,
    Diamond = 3,
    SupDiamond = 4,
    ExtraSuper = 5
}

export enum NETWORK {
    BRC = 'brc',
    BSC = "bep",
    Poly = "poly",
    Ethereum = "erc",
    Aptos = "apt",
    Avax = "avax",
    Op = "op",
    OKC = "okc",
    Doge = "doge",
    Fantom = "ftm",
    ARB = "arb",
    OPBNB = "opbnb",
    SOL = "sol",
    TRON = "tron",
    SUI = "sui",
    BASE = 'base',
    BLAST = 'blast'
}

declare global {
    interface Window {
        accounts: any;
    }
}
