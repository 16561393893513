import React , { useState , useEffect } from 'react';
import { Tabs } from 'antd';
import TabsUpComing from './TabsUpComing';
import TabsOngoing from './TabsOngoing';
import TabsEnded from './TabsEnded';
import TabsJoined from './TabsJoined';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BottomFunction = () => {
    const { t, i18n }: any = useTranslation('common');
    let location = useLocation();
    let navigate = useNavigate();
    const [defaultKey, setDefaultKey] = useState("Upcoming");
    const tabSplit = location.search.split("=");
    const tabBox = tabSplit[1] === undefined ? "Upcoming" : tabSplit[1];
    function callback(key: any) {
        setDefaultKey(key);
        navigate(`${"/Ido"}?Tab=${key}`);
    }
    useEffect(() => {
        if (tabBox) {
            setDefaultKey(tabBox);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabBox]);
    
    return (
        <>
            <div className="container">
                <div className="row-tabs-ido">
                    <Tabs activeKey={defaultKey} onChange={callback}>
                        <Tabs.TabPane tab={`${t('ido.ido-6', { framework: 'React' })}`} key="Upcoming">
                            <TabsUpComing />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={`${t('ido.ido-7', { framework: 'React' })}`} key="Ongoing">
                            <TabsOngoing />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={`${t('ido.ido-8', { framework: 'React' })}`} key="Ended">
                            <TabsEnded />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={`${t('ido.ido-9', { framework: 'React' })}`} key="Joined">
                            <TabsJoined />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </>
    )
}
export default BottomFunction