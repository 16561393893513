import web3 from "web3";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";

export const convertTokenToWei = (value, decimal) => {
    let amount = web3.utils.toWei(value.toString(), "ether");
    if (decimal !== 18)
        amount = new BigNumber(value)
            .times(new BigNumber(10).pow(decimal))
            .toString();
    return amount;
};

export const convertWeiToToken = (value, decimal) => {
    if (decimal === 18) return web3.utils.fromWei(value.toString(), "ether");
        else if (decimal === 6)
            return web3.utils.fromWei(value.toString(), "picoether");
        else if (decimal === 9)
            return web3.utils.fromWei(value.toString(), "nanoether");
        else if (decimal === 12)
            return web3.utils.fromWei(value.toString(), "microether");
        else if (decimal === 15)
            return web3.utils.fromWei(value.toString(), "milliether");
        else return value.toString() / 10 ** decimal;
};

export const _approveBUSD = async (
    contractBUSD,
    address,
    amount,
    usdDecimals
) => {
    const amountTemp = convertTokenToWei(amount, usdDecimals);
    const result = await contractBUSD.approve(
        address,
        ethers.constants.MaxUint256
    );
    return result;
};

export const _joinPoolNew = async (
    joinPoolContract,
    amount,
    projectId,
    signBusd,
    chainId,
    usdDecimals
) => {
    const amountTemp = await convertTokenToWei(amount, usdDecimals);
    const args = [amountTemp, projectId, projectId, signBusd];
    const gas = await joinPoolContract.estimateGas.join(...args);
    return joinPoolContract.join(...args, { gasLimit: gas });
};

export const _isJoined = async (
    poolJoinContract,
    account,
    projectId,
    roundId
) => {
    try {
        return poolJoinContract.isJoined(account, projectId, projectId);
    } catch (error) {
        return false;
    }
};

export const _refundChange = async (
    claimContract,
    amountBusd,
    item,
    usdDecimals
) => {
    const amount = convertTokenToWei(amountBusd, usdDecimals);
    const args = [amount, item];
    const estimatedGas = await claimContract.estimateGas.refund(...args);
    return claimContract.refund(...args, {
        gasLimit: estimatedGas,
    });
};

export const _isClaimChange = async (claimContract, account) => {
    return claimContract.userRefund(account.toLowerCase());
};

export const _claimTokens = async (
    claimContract,
    item,
    tokenDecimals,
    usdDecimals
) => {
    const amountTemp = convertTokenToWei(item.claimToken, tokenDecimals);
    console.log('amountTemp', amountTemp)
    const refundUsd = convertTokenToWei(item.claimUsd, usdDecimals);
    const args = [amountTemp, refundUsd, item.signToken];
    const gas = await claimContract.estimateGas.claimTokens(...args);
    return claimContract.claimTokens(...args, { gasLimit: gas });
};

export const _isRefundedRC = async (
    claimContract,
    account,
    amount,
    usdDecimals
) => {
    const amountRefund = convertTokenToWei(amount, usdDecimals);
    return claimContract.showRefundBtn(account, amountRefund);
};

export const _showClaimBtn = async (
    claimContract,
    account,
    amount,
    tokenDecimals
) => {
    const amountTemp = convertTokenToWei(amount, tokenDecimals);
    const result = await claimContract.showClaimBtn(account, amountTemp);
    return result;
};

export const _totalClaimed = async (poolContract, account, tokenDecimals) => {
    const res = poolContract && (await poolContract.realClaimed(account));
    return convertWeiToToken(res, tokenDecimals);
};


export const _totalRefunded = async (poolContract, account, usdDecimals) => {
    const res = await poolContract.realRefunded(account);
    return convertWeiToToken(res, usdDecimals);
};

export const _refund = async (
    claimContract,
    amountBusd,
    claimToken,
    signBusd,
    decimals,
    usdDecimals
) => {
    const amount = convertTokenToWei(amountBusd, usdDecimals);
    const claimAmount = convertTokenToWei(claimToken, decimals);
    const args = [amount, claimAmount, signBusd];
    const estimatedGas = await claimContract.estimateGas.refund(...args);
    return claimContract.refund(...args, {
        gasLimit: estimatedGas,
    });
};

export const totalJoinPool = async (poolJoinContract) => {
    const total = poolJoinContract && (await poolJoinContract.totalJoined());
  
    return total !== null ? total.toString() : 0;
};

export const rendererCountDown = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    // Render a countdown
    if (completed) {
      return <span>Finish</span>
    }
    if (days > 1) {
      return (
        <span>
          {days}days : {hours}h : {minutes}m : {seconds}s
        </span>
      );
    } else if (days === 1) {
      return (
        <span>
          {days}day : {hours}h : {minutes}m : {seconds}s
        </span>
      );
    } else {
      return (
        <span>
          {hours}h : {minutes}m : {seconds}s
        </span>
      );
    }
  };


export const getProgressTime = (startTime) => {
    if (!startTime) {
        return Date.now();
    }
    const now = new Date();
    const utcDate = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        0
    );
    const startDate = new Date(startTime);

    const startTS = Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startDate.getHours(),
        startDate.getMinutes(),
        startDate.getSeconds(),
        0
    );
    if (startTS <= utcDate) {
        return Date.now();
    } else {
        let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;

        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600);
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60); // in theory the modulus is not required

        // return `${days} day${days > 1 ? 's' : ''} ${hours}:${minutes}:${seconds}`
        if (days >= 1) {
            return (
                Date.now() +
                days * 24 * 60 * 60 * 1000 +
                hours * 60 * 60 * 1000 +
                minutes * 60 * 1000 +
                seconds * 1000
            );
        } else {
            return (
                Date.now() +
                hours * 60 * 60 * 1000 +
                minutes * 60 * 1000 +
                seconds * 1000
            );
        }
    }
};
