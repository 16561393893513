import tokens from "./tokens";

const poolsConfig = [
  // typePool = 1 --> start pool
  // typePool = 2 --> farm pool
  // typePool = 3 --> IDO pool
  // countdownt= true ---> countdown
  // isDay = true --> day
  // isDay = false --> month

  // typeLP = 1 --> BUSD LP
  // typeLP = 2 --> BNB LP
  // typeLP = 3 --> link LP


  // lock temp
  // {
  //   sousId: 85,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x6d7C8aD9aBBFEE50bB3be2B58D4E08a755576F80",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 13078242,
  //   endBlock: 56422758,
  //   unstakeBlock: 13133984,
  //   tokenPerBlock: 289351900000000000,
  //   isFinished: false,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 14,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x5a42e0B986Af9756d47C263e9b4A54e3958C19dc",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 10474607,
  //   endBlock: 13068000,
  //   tokenPerBlock: 771000000000000000,
  //   isFinished: false,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 1,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.dpet,
  //   contractAddress: "0xc2E393920B8940008f40bb444eB2803Ec15D6F3C",
  //   fees: 0.1,
  //   blockPeriod: 86400,
  //   startBlock: 9489380,
  //   endBlock: 12945380,
  //   tokenPerBlock: 1446700000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 2,
  //   stakingToken: tokens.revomon,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x934ADC046b057316dB855F6133513F4967AC421F",
  //   fees: 0.1,
  //   blockPeriod: 86400,
  //   startBlock: 9550960,
  //   endBlock: 13006960,
  //   tokenPerBlock: 86800000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 3,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.revomon,
  //   contractAddress: "0x27Aecb73aC320780e6fcFA7f815605763e84A05e",
  //   fees: 0.1,
  //   blockPeriod: 86400,
  //   startBlock: 9550960,
  //   endBlock: 13006960,
  //   tokenPerBlock: 43200000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },
  
  // // pool stake new

  // {
  //   sousId: 110,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x72a643471020fce60248d37548C459b87CAB18c9",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 16568414,
  //   endBlock: 20024414,
  //   tokenPerBlock: 231480000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   lockup: 7,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },


  
  // {
  //   sousId: 116,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.istep,
  //   contractAddress: "0x6823c523dB20c60B75e25dab72446998ACD8cEf9",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 18253948,
  //   endBlock: 19549948,
  //   tokenPerBlock: 260000000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   lockup: 7,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },
  // {
  //   sousId: 117,
  //   stakingToken: tokens.istep,
  //   earningToken: tokens.istep,
  //   contractAddress: "0x385716713B1d2E37f9130aA2c70A7f1f63e5E252",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 18253948,
  //   endBlock: 19549948,
  //   tokenPerBlock: 520833300000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   lockup: 7,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },
  // {
  //   sousId: 114,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.mst,
  //   contractAddress: "0x0fdAe9E06E545ceF9235Ce1D042de7E04AFA6741",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 16739466,
  //   endBlock: 20195466,
  //   tokenPerBlock: 28935000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   lockup: 7,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },
  // {
  //   sousId: 112,
  //   stakingToken: tokens.mst,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x2a6848df4DdbD12Aa5ABaff30EC626eC1134fa8E",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 16739466,
  //   endBlock: 20195466,
  //   tokenPerBlock: 57870000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   lockup: 7,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },
  // {
  //   sousId: 107,
  //   stakingToken: tokens.zwz,
  //   earningToken: tokens.zwz,
  //   contractAddress: "0xf50686108d960e90F6e67DE2E74edE622ab11c83",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 15364743,
  //   endBlock: 18820743,
  //   tokenPerBlock: 145000000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   lockup: 15,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },
  // {
  //   sousId: 106,
  //   stakingToken: tokens.zwz,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0xC015A9684Ada72140AB83AA8c0a12Ec4CF3B4F6d",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 15364743,
  //   endBlock: 18820743,
  //   tokenPerBlock: 43000000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   lockup: 15,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },
  // {
  //   sousId: 105,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.zwz,
  //   contractAddress: "0xAF2fE303f104171A48d9Ab7845264B76Ee6aB11c",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 15364743,
  //   endBlock: 18820743,
  //   tokenPerBlock: 43000000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   lockup: 15,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },

  // {
  //   sousId: 102,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.ack,
  //   contractAddress: "0x41d6cbE0bf70a338928e6CC3EfA4AAc615C2A600",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 14817805,
  //   endBlock: 16545805,
  //   tokenPerBlock: 2604166600000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },

  // {
  //   sousId: 103,
  //   stakingToken: tokens.ack,
  //   earningToken: tokens.ack,
  //   contractAddress: "0x9773F18619fD7D2DFc31A7c5f19caaf20BDa2ba4",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 14817805,
  //   endBlock: 18273805,
  //   tokenPerBlock: 34722222000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   lockup: 30,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },
  // {
  //   sousId: 100,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.dal,
  //   contractAddress: "0xEe73bEC4bfCb644E12410f4F5E2BF051E128751A",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 14045129,
  //   endBlock: 15773129,
  //   tokenPerBlock: 1446759259000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },

  // {
  //   sousId: 99,
  //   stakingToken: tokens.dal,
  //   earningToken: tokens.dal,
  //   contractAddress: "0xb05d8707142fF3e52bA2F610D603F22b638116Ec",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 14045129,
  //   endBlock: 50000648,
  //   tokenPerBlock: 2893500000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   lockup: 15,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },

  // {
  //   sousId: 95,
  //   stakingToken: tokens.next,
  //   earningToken: tokens.next,
  //   contractAddress: "0xf8829611f27001aEaF9FC1fce462EE2d5f7899ff",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 13298446,
  //   endBlock: 13946446,
  //   unstakeBlock: 13622446,
  //   tokenPerBlock: 231481500000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   lockup: 15,
  //   chain: 0,
  // },
  // {
  //   sousId: 87,
  //   stakingToken: tokens.ack,
  //   earningToken: tokens.ack,
  //   contractAddress: "0x17B3b8e475791a1c6f6929782950D4216f796472",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 13131077,
  //   endBlock: 14800335,
  //   unstakeBlock: 13957206,
  //   tokenPerBlock: 34722200000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   lockup: 30,
  //   chain: 0,
  // },

  // {
  //   sousId: 76,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.hwl,
  //   contractAddress: "0x881561CFc1d3EFE0F0aB10F3693FDd4B8156caeA",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 12827500,
  //   endBlock: 15400500,
  //   tokenPerBlock: 231481500000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 74,
  //   stakingToken: tokens.hwl,
  //   earningToken: tokens.hwl,
  //   contractAddress: "0xd5C60D1c2C63750C5Dd748392906426439593F16",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 12827500,
  //   endBlock: 15400500,
  //   tokenPerBlock: 578703700000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 73,
  //   stakingToken: tokens.polkaplay,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0xf848F551B4728bb8338835C58299D774477cecF8",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 12780280,
  //   endBlock: 13606409,
  //   tokenPerBlock: 17361100000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 71,
  //   stakingToken: tokens.cogi,
  //   earningToken: tokens.cogi,
  //   contractAddress: "0xa3d0407de3da12593537e0e685E3b8bE2648b092",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 12711686,
  //   endBlock: 15210073,
  //   tokenPerBlock: 96000000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 69,
  //   stakingToken: tokens.cogi,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x95F2DF23Ea5B35314938D70c8bAA8a0F1191212b",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 12699637,
  //   endBlock: 15200024,
  //   tokenPerBlock: 38580200000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 64,
  //   stakingToken: tokens.oly,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0xA4Cac7BD6F7cEa85a20943eB20B690dc807eD421",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 12467320,
  //   endBlock: 15055320,
  //   tokenPerBlock: 77160000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 66,
  //   stakingToken: tokens.oly,
  //   earningToken: tokens.oly,
  //   contractAddress: "0x2f66931d8aa91058B1FCC4Fa38F408dF7dbC56ED",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 12467320,
  //   endBlock: 15055320,
  //   tokenPerBlock: 115740000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },

  // {
  //   sousId: 60,
  //   stakingToken: tokens.tribeone,
  //   earningToken: tokens.tribeone,
  //   contractAddress: "0x9321838064eE23a5e81d8B832eDA8a56435d0C67",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 11767850,
  //   endBlock: 15211850,
  //   unstakeBlock: 12611850,
  //   harvestBlock: 12611850,
  //   tokenPerBlock: 101270000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   lockup: 30,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 16,
  //   stakingToken: tokens.kingdomgame,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x9685ecB565a3305F9f0df5d26A319A7d5aB710Ad",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 10397332,
  //   endBlock: 13853332,
  //   tokenPerBlock: 86800000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 18,
  //   stakingToken: tokens.dpet,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x5F352530a5A93ADDfF95a7C4117c92ebc9198aa4",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 10445592,
  //   endBlock: 13901305,
  //   tokenPerBlock: 145000000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 20,
  //   stakingToken: tokens.revomon,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x1cb255CFb0E6273c2554F1Ca1Cb3C3B37b2CE554",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 10474407,
  //   endBlock: 13930262,
  //   tokenPerBlock: 86800000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 26,
  //   stakingToken: tokens.polkaplay,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x36908fA45f070bff96555C0579079125f2209843",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 10397332,
  //   endBlock: 12125332,
  //   tokenPerBlock: 57800000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   chain: 0,
  // },

  // add farm from >= 500
  // add farm from >= 500
  // add farm from >= 500

  // {
  //   sousId: 29,
  //   stakingToken: tokens.bscslp,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0xCFf637Bc7eb80AcAc69207746a9Ed34A16B0E991",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 10168238,
  //   endBlock: 11861005,
  //   tokenPerBlock: 340000000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typeLP: 1,
  //   typePool: 2,
  //   chain: 0,
  // },
  // {
  //   sousId: 36,
  //   stakingToken: tokens.bscsbnblp,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x3cFedE1dC2134a53383A435d197ED34dB741936c",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 10397332,
  //   endBlock: 13555332,
  //   tokenPerBlock: 434000000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typeLP: 2,
  //   typePool: 2,
  //   chain: 0,
  // },

  // // pool farm new
  // // pool farm new
  // {
  //   sousId: 61,
  //   stakingToken: tokens.bscslp,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x1d80B4d9afC9472Fc379aDc0fFcEDF32483EDe49",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 12054052,
  //   endBlock: 15500052,
  //   tokenPerBlock: 578703700000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typeLP: 1,
  //   typePool: 2,
  //   chain: 0,
  // },
  // {
  //   sousId: 35,
  //   stakingToken: tokens.bscslp,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x31892B9549a9efC7D2EFAE520D1605e8B653F416",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 10397332,
  //   endBlock: 12060332,
  //   tokenPerBlock: 340000000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typeLP: 1,
  //   typePool: 2,
  //   chain: 0,
  // },

  // {
  //   sousId: 97,
  //   stakingToken: tokens.bscsbnblp,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x025cA2cE33BB1E2FBDdab528639F4d497863439b",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 13577058,
  //   endBlock: 17003058,
  //   tokenPerBlock: 231481500000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typeLP: 2,
  //   typePool: 2,
  //   chain: 0,
  // },
  // {
  //   sousId: 109,
  //   stakingToken: tokens.bscslp,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x502fcE58657dCC80Fd3404c852D906CD50aC655e",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 15595007,
  //   endBlock: 19051007,
  //   tokenPerBlock: 289000000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typeLP: 1,
  //   typePool: 2,
  //   chain: 0,
  // },
  // {
  //   sousId: 118,
  //   stakingToken: tokens.istepbusdlp,
  //   earningToken: tokens.istep,
  //   contractAddress: "0xF39ab3E47484459040E3a7A1B877cA39A260c2AC",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 18253948,
  //   endBlock: 19981948,
  //   tokenPerBlock: 694444400000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typeLP: 1,
  //   typePool: 2,
  //   cakeLP: true,
  //   lockup: 7,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },
  // {
  //   sousId: 115,
  //   stakingToken: tokens.bscdbnblp,
  //   earningToken: tokens.bsdium,
  //   contractAddress: "0xC1E2d6F630B1Ea8B0E4C4E80B3410008Fe12f594",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 16990851,
  //   endBlock: 20446851,
  //   tokenPerBlock: 3472200000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typeLP: 2,
  //   typePool: 2,
  //   cakeLP: true,
  //   lockup: 7,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },
  // {
  //   sousId: 108,
  //   stakingToken: tokens.zwzbnblp,
  //   earningToken: tokens.zwz,
  //   contractAddress: "0x18Fc522e13c8dd2E86223CA60a5a5E131f0Ea40c",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 15383381,
  //   endBlock: 18820743,
  //   tokenPerBlock: 145000000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typeLP: 2,
  //   typePool: 2,
  //   cakeLP: true,
  //   lockup: 15,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },
  // {
  //   sousId: 101,
  //   stakingToken: tokens.dalbusdlp,
  //   earningToken: tokens.dal,
  //   contractAddress: "0xB801D820852010B46bCbd2c85E9cde07052FE17A",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 14045129,
  //   endBlock: 50000648,
  //   tokenPerBlock: 4340000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typeLP: 1,
  //   typePool: 2,
  //   cakeLP: true,
  //   lockup: 15,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },
  
  // {
  //   sousId: 38,
  //   stakingToken: tokens.babylp,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x47b79E4553B1b4BE532EbBE41E39b1E6fc93c2E3",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 10397332,
  //   endBlock: 11231332,
  //   tokenPerBlock: 194440000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typeLP: 1,
  //   typePool: 2,
  //   chain: 0,
  // },
  
  // IDO pool
  // IDO pool
  // IDO pool
  {
    sousId: 43,
    stakingToken: tokens.fvl,
    earningToken: tokens.fvl,
    contractAddress: "0x1C3577fe87c870F2954d92eC3513eE37FEd0FFFA",
    fees: 0,
    blockPeriod: 300,
    startBlock: 35658475,
    endBlock: 353121911,
    unstakeBlock: 35658475,
    tokenPerBlock: 100000000000000, // +6
    isFinished: true,
    stakingLimit: 0,
    idoLP: false,
    lockup: 30,
    fixapy: 90,
    typePool: 3,
    chain: 0,
    notPrice: true
  },

  {
    sousId: 44,
    stakingToken: tokens.fvl,
    earningToken: tokens.fvl,
    contractAddress: "0xE7AfB48c045706271C8F0743643371daab99C305",
    fees: 0,
    blockPeriod: 0,
    startBlock: 36261209,
    endBlock: 353121911,
    unstakeBlock: 36261209,
    tokenPerBlock: 0, // +6
    isFinished: false,
    stakingLimit: 0,
    idoLP: false,
    lockup: 0,
    fixapy: 0,
    typePool: 3,
    chain: 0,
    notPrice: true
  },
  {
    sousId: 45,
    stakingToken: tokens.bscs,
    earningToken: tokens.bscs,
    contractAddress: "0x436D7986B7D79038d45A3FDEF00B4573A5b60066",
    fees: 0,
    blockPeriod: 0,
    startBlock: 36261209,
    endBlock: 353121911,
    unstakeBlock: 36261209,
    tokenPerBlock: 0, // +6
    isFinished: false,
    stakingLimit: 0,
    idoLP: false,
    lockup: 0,
    fixapy: 0,
    typePool: 3,
    chain: 0,
    notPrice: true
  },

  // {
  //   sousId: 98,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x0b8c29319e69FE053f27585dA2F6f924E745F6cD",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 13662237,
  //   endBlock: 77006753,
  //   tokenPerBlock: 85000000000000000, // +0
  //   isFinished: true,
  //   stakingLimit: 0,
  //   idoLP: false,
  //   lockup: 45,
  //   typePool: 3,
  //   userLock: true,
  //   chain: 0,
  // },
  // {
  //   sousId: 79,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0xCAf51D8B12B3333774191A08DFCaF5718a2E34B4",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 12872069,
  //   endBlock: 77006753,
  //   tokenPerBlock: 85000000000000000, // +0
  //   isFinished: true,
  //   stakingLimit: 0,
  //   idoLP: false,
  //   lockup: 60,
  //   typePool: 3,
  //   userLock: true,
  //   chain: 0,
  // },
  // {
  //   sousId: 120,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0xD67Fd740e0FbeD8367d9146ba177Ff3b22fbE12C",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 25320047,
  //   endBlock: 40220047,
  //   tokenPerBlock: 185000000000000000, // +0
  //   isFinished: true,
  //   stakingLimit: 0,
  //   idoLP: false,
  //   lockup: 180,
  //   typePool: 3,
  //   userLock: true,
  //   chain: 0,
  // },

  // {
  //   sousId: 47,
  //   stakingToken: tokens.idobscsbnblp,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x4CCc37AE6BAAf1Af67899cF8cf0c809Af31d7e7c",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 11139069,
  //   endBlock: 12873069,
  //   unstakeBlock: 12873069,
  //   tokenPerBlock: 92900000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   disUnstake: false,
  //   disStake: true,
  //   disHarvest: false,
  //   idoLP: true,
  //   lockup: 60,
  //   fixapy: 45,
  //   typePool: 3,
  //   chain: 0,
  // },
  // {
  //   sousId: 48,
  //   stakingToken: tokens.idobscsbusdlp,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0xA4D8348d574FCe65A46b34Efce5952b6158c1787",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 11139069,
  //   endBlock: 12873069,
  //   unstakeBlock: 12873069,
  //   tokenPerBlock: 193000000000000000, // 0.05236
  //   isFinished: true,
  //   stakingLimit: 0,
  //   disUnstake: false,
  //   disStake: true,
  //   disHarvest: false,
  //   idoLP: true,
  //   lockup: 60,
  //   fixapy: 45,
  //   typePool: 3,
  //   chain: 0,
  // },

  // {
  //   sousId: 124,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x4350E20A6dd4fe18942c65a1A764FFA879230541",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 26560432,
  //   endBlock: 34852493,
  //   tokenPerBlock: 0, // +0
  //   isFinished: true,
  //   stakingLimit: 0,
  //   idoLP: false,
  //   lockup: 48,
  //   typePool: 3,
  //   userLock: true,
  //   chain: 0,
  //   birthday: true
  // },
  
  // {
  //   sousId: 80,
  //   stakingToken: tokens.idobscsbnblp,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x3036f3195Cf84a3d95a0dDE8bDf137aE7FE7985E",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 12872069,
  //   endBlock: 16308000648,
  //   tokenPerBlock: 173600000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   idoLP: true,
  //   lockup: 60,
  //   typePool: 3,
  //   userLock: true,
  //   chain: 0,
  // },
  // {
  //   sousId: 81,
  //   stakingToken: tokens.idobscsbusdlp,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x63053C30C2aF5747a0B0527ea6304a1dE7Ccd3A5",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 12872069,
  //   endBlock: 16308000648,
  //   tokenPerBlock: 173600000000000000, // 0.05236
  //   isFinished: true,
  //   stakingLimit: 0,
  //   idoLP: true,
  //   lockup: 60,
  //   typePool: 3,
  //   userLock: true,
  //   chain: 0,
  // },
  
  // {
  //   sousId: 121,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.terz,
  //   contractAddress: "0x9274a9CceEe8e4aF9607680c038741c87B381e65",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 26146010,
  //   endBlock: 27441692,
  //   tokenPerBlock: 413350000000000000, // +0
  //   isFinished: false,
  //   stakingLimit: 0,
  //   idoLP: false,
  //   lockup: 30,
  //   typePool: 1,
  //   userLock: true,
  //   chain: 0,
  //   totalRewards: '2,142,857',
  //   dailyRewards: '47,619'
  // },
  // {
  //   sousId: 122,
  //   stakingToken: tokens.terz,
  //   earningToken: tokens.terz,
  //   contractAddress: "0xdD2659da1CB4163D00DbF8a6D0560526DBEe8e4c",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 26146010,
  //   endBlock: 28737692,
  //   tokenPerBlock: 413350000000000000, // +0
  //   isFinished: false,
  //   stakingLimit: 0,
  //   idoLP: false,
  //   lockup: 30,
  //   typePool: 1,
  //   userLock: true,
  //   chain: 0,
  //   totalRewards: '4,285,714',
  //   dailyRewards: '47,619'
  // },
  // {
  //   sousId: 123,
  //   stakingToken: tokens.terz,
  //   earningToken: tokens.terz,
  //   contractAddress: "0x2ce1CC2FC6280BB01ce6d941c0E695E399fD7DB8",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 26146010,
  //   endBlock: 31330010,
  //   tokenPerBlock: 378900000000000000, // +0
  //   isFinished: false,
  //   stakingLimit: 0,
  //   idoLP: false,
  //   lockup: 60,
  //   typePool: 1,
  //   userLock: true,
  //   chain: 0,
  //   totalRewards: '7,857,143',
  //   dailyRewards: '43,650'
  // },
];
// sousId lớn nhất : 124
//  ido pool :sousId = 124
//  start pool :sousId = 123
//  farm pool :sousId = 101
// contract test 0x1818233Dcb4C0919cF256De06C007DE2D2563111
export default poolsConfig;
