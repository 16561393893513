/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_URL } from "../../constants/index";
import { createStore, createHook, createContainer, createSubscriber } from "react-sweet-state";

const StoreProjects = createStore({
    initialState: {
        upcomingListHome: [],
        reportHome: {},
        dataSub: {},
        endListHome: []
    },
    actions: {
        getUpcomingHome: () =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.post(`${API_URL}launchpad/home_upcoming`);
                setState({ upcomingListHome: res?.data?.data || [] });
            } catch (err) {
                // TODO
            }
            return res;
        },
        getEndHome: () =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.post(`${API_URL}launchpad/home_end`);
                setState({ endListHome: res?.data?.data || [] });
            } catch (err) {
                // TODO
            }
            return res;
        },
        getReport: () =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.get(`${API_URL}launchpad/report`);
                setState({ reportHome: res?.data?.data || {} });
            } catch (err) {
                // TODO
            }
            return res;
        },
        getSubcrise: (params) =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.post(`${API_URL}profile/subscribe_email` , params);
                setState({ dataSub: res?.data?.data || {} });
            } catch (err) {
                // TODO
            }
            return res;
        },
    }
});
export const useHookProjects = createHook(StoreProjects);
export const Container = createContainer(StoreProjects, {
    onInit:
        () =>
            ({ setState }, props) => {
                setState({ ...props });
            },
});
export const Subscriber = createSubscriber(StoreProjects);