import React, { useEffect, useState } from "react";
import { Drawer, Modal } from "antd";
import { useConnectWallet, useActiveWeb3React } from "../../hooks";
import { NEED_A_PLACEHOLDER, WALLET_LIST } from "../../constants/index";
import WalletItem from "./WalletItem";
import { useWeb3React } from "@web3-react/core";
import web3 from "web3";
import "antd/dist/antd.min.css";
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";
import "./style.css";


declare const window: Window & typeof globalThis & { ethereum: any };
const ConnectWallet = (props: any) => {
    const { t, i18n }: any = useTranslation('common');
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const { walletLogin, walletLogout } = useConnectWallet();
    const walletItemClass = "w-1/3 flex-auto mb-0.5 p-0.5 last:bg-transparent";
    const { chainId }: any = useWeb3React();
    const context = useWeb3React();
    const { active } = context;
    const { account } = useActiveWeb3React();
    const [bnbBalance, setBnbBalance] = useState(0);
    const provider = "https://bsc-dataseed.binance.org/";
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };
  
    const onClose = () => {
        setOpen(false);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [isModalVisibleAcc, setIsModalVisibleAcc] = useState<boolean>(false);

    const showModalAccount = () => {
        setIsModalVisibleAcc(true);
    };

    const handleOkAccount = () => {
        setIsModalVisibleAcc(false);
    };

    const handleCancelAccount = () => {
        setIsModalVisibleAcc(false);
    };

    const handleDisconnect = () => {
        walletLogout();
        setIsModalVisibleAcc(false);
        setOpen(false);
    };

    // useEffect(() => {
    //     if(isMobile) {
    //         window.localStorage.setItem('accountStatus', '1');
	// 		window.localStorage.setItem('connectorId', "injected");
    //     }
    // }, []);

    const handleConnect = async (connectorId: string) => {
        if(isMobile) {
            window.localStorage.setItem('accountStatus', '1');
			window.localStorage.setItem('connectorId', connectorId);
        }
        if (connectorId === "tokenpocket") {
            if (typeof window.ethereum.isTokenPocket === 'undefined') {
                window.open("https://chromewebstore.google.com/detail/tokenpocket-web3-nostr-wa/mfgccjchihfkkindfppnaooecgfneiii?hl=en")
            } else {
                try {
                    const rs = await walletLogin(connectorId);
                    setIsModalVisible(false);
                } catch (e) {
                    console.error("Login failed");
                }
            }
        } else {
            try {
                const rs = await walletLogin(connectorId);
                setIsModalVisible(false);
            } catch (e) {
                console.error("Login failed");
            }
        }
    };

    useEffect(() => {
        if (account) {
            w3.eth.getBalance(account, (err, balance) => {
                if (balance) {
                let _balance: any = web3.utils.fromWei(
                    web3.utils.toBN(balance),
                    "ether"
                );
                let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
                setBnbBalance(bnbBl);
                }
            });
        }
    }, [account, chainId]);

    return (
        <>
            {active ? (
                <>
                    <div className="btn-connect" onClick={showDrawer}>
                        <div className="address">
                            {account?.slice(0, 4)}...{account?.slice(-4)}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <button className="btn-connect" onClick={showModal}>
                        {t('wallet.wallet-1', { framework: 'React' })}
                    </button>
                </>
            )}

            <Drawer className="drawer-menu-row" title="Account" placement="right" onClose={onClose} open={open}>
                <div className="top-drawer">
                    <div className="title-profile-dw">
                        {t('wallet.wallet-2', { framework: 'React' })}
                    </div>
                </div>
                <div className="bottom-drawer">
                    <div className="box-drawer">
                        <div className="content-row">
                            <div className="item">
                                <img src="./images/user-wl.svg" alt="" />
                            </div>
                            <div className="item">
                                {account?.slice(0, 4)}...{account?.slice(-4)}
                            </div>
                        </div>
                        <div className="content-row">
                            <div className="item">
                            {t('wallet.wallet-5', { framework: 'React' })}:
                            </div>
                            <div className="item span">
                                {bnbBalance} BNB
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu-li">
                    <a href="/profile">
                        <li>
                            {t('wallet.wallet-7', { framework: 'React' })}
                        </li>
                    </a>
                </div>
                <div className="gr-disconect row">
                    <button
                        type="button"
                        className="btn-connect"
                        onClick={handleDisconnect}
                    >
                        <img src="./images/logout.png" alt="" /> {t('wallet.wallet-6', { framework: 'React' })}
                    </button>
                </div>

            </Drawer> 

            <Modal
                title="Your wallet"
                open={isModalVisibleAcc}
                onOk={handleOkAccount}
                footer={false}
                onCancel={handleCancelAccount}
                className="connect-wallet modal-wallet"
            >
                <div className="gr-disconect">
                    <button
                        type="button"
                        className="btn-connect"
                        onClick={handleDisconnect}
                    >
                        <img src="./images/logout.png" alt="" /> {t('wallet.wallet-6', { framework: 'React' })}
                    </button>
                </div>
            </Modal>

            {!active ? (
                <Modal
                    title={
                        <div className="text-md connect-wallet-title">
                            {!account ? (
                                `${t('wallet.wallet-3', { framework: 'React' })}`
                            ) : (
                                `${t('wallet.wallet-4', { framework: 'React' })}`
                            )}
                        </div>
                    }
                    open={isModalVisible}
                    onOk={handleOk}
                    footer={false}
                    onCancel={handleCancel}
                    className="connect-wallet"
                >
                    <div className="flex flex-wrap">
                        {WALLET_LIST.map((wallet) => {
                            return (
                                <WalletItem
                                    className={`wallet-item`}
                                    key={wallet.title}
                                    onClick={() => handleConnect(wallet.connectorId)}
                                    icon={<wallet.icon width="48px" />}
                                    title={wallet.title}
                                />
                            );
                        })}
                        {NEED_A_PLACEHOLDER && <div className={walletItemClass} />}
                    </div>
                </Modal>
            ) : (
                ""
            )}
        </>
    );
};

export default ConnectWallet;
