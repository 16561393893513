/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_URL } from "../../constants/index";
import { createStore, createHook, createContainer, createSubscriber } from "react-sweet-state";

const StoreProjects = createStore({
    initialState: {
        dataOwner:{},
        linkAccount: {},
        accountLink: {},
        dataRef: {},
        dataUseRef: {},
        dataMySystem: [],
        dataMyIdo: [],
        dataRedeemMarket: [],
        dataLeaderBoard: [],
        dataDashboard: {},
        dataRedeem: {},
        dataGetListIdoFilter: {}
    },
    actions: {
        getOwnerAddress: (params) =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.post(`${API_URL}launchpad/owner`, params);
                setState({ dataOwner: res?.data.data || {} });
            } catch (err) {
                // TODO
            }
            return res;
        },
        linkWalletAccount: (params) =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.post(`${API_URL}profile/link_address_update` , params);
                setState({ linkAccount: res.data || {} });
            } catch (err) {
                // TODO
            }
            return res;
        },
        getWalletAccount: (params) =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.post(`${API_URL}profile/link_address` , params);
                setState({ accountLink: res.data.data || {} });
            } catch (err) {
                // TODO
            }
            return res;
        },
        getRefCode: (params) =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.post(`${API_URL}profile/get_ref_code` , params);
                setState({ dataRef: res.data.data || {} });
            } catch (err) {
                // TODO
            }
            return res;
        },
        getUseRefCode: (params) =>
            async ({ setState }) => {
                let res = null;
                try {
                    res = await axios.post(`${API_URL}referral/use_refcode` , params);
                    setState({ dataUseRef: res.data || {} });
                } catch (err) {
                    // TODO
                }
                return res;
            },
        getMySystem: (params) =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.post(`${API_URL}referral/my_system` , params);
                setState({ dataMySystem: res.data.data || {} });
            } catch (err) {
                // TODO
            }
            return res;
        },
        getMyIdo: (params) =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.post(`${API_URL}referral/ido` , params);
                setState({ dataMyIdo: res.data.data || {} });
            } catch (err) {
                // TODO
            }
            return res;
        },
        getRedeemMarket: (params) =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.post(`${API_URL}referral/marketplace` , params);
                setState({ dataRedeemMarket: res.data.data || {} });
            } catch (err) {
                // TODO
            }
            return res;
        },
        getDashboard: (params) =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.post(`${API_URL}referral/dashboard` , params);
                setState({ dataDashboard: res.data.data || {} });
            } catch (err) {
                // TODO
            }
            return res;
        },
        getLeaderBoard: (params) =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.get(`${API_URL}referral/leaderboard` , params);
                setState({ dataLeaderBoard: res.data.data || {} });
            } catch (err) {
                // TODO
            }
            return res;
        },
        getRedeem: (params) =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.post(`${API_URL}referral/redeem` , params);
                setState({ dataRedeem: res.data.data || {} });
            } catch (err) {
                // TODO
            }
            return res;
        },
        getListIdoFilter: () =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.get(`${API_URL}launchpad/list_idos`);
                setState({ dataGetListIdoFilter: res.data.data || {} });
            } catch (err) {
                // TODO
            }
            return res;
        },
    }
});
export const useHookAccount = createHook(StoreProjects);
export const Container = createContainer(StoreProjects, {
    onInit:
        () =>
            ({ setState }, props) => {
                setState({ ...props });
            },
});
export const Subscriber = createSubscriber(StoreProjects);