import web3 from "web3";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";

export const convertTokenToWei = (value, decimal) => {
    let amount = web3.utils.toWei(value.toString(), "ether");
    if (decimal !== 18)
        amount = new BigNumber(value)
            .times(new BigNumber(10).pow(decimal))
            .toString();
    return amount;
};

export const convertWeiToToken = (value, decimal) => {
    if (decimal === 18) return web3.utils.fromWei(value.toString(), "ether");
        else if (decimal === 6)
            return web3.utils.fromWei(value.toString(), "picoether");
        else if (decimal === 9)
            return web3.utils.fromWei(value.toString(), "nanoether");
        else if (decimal === 12)
            return web3.utils.fromWei(value.toString(), "microether");
        else if (decimal === 15)
            return web3.utils.fromWei(value.toString(), "milliether");
        else return value.toString() / 10 ** decimal;
};

export const _claimTokens = async (contractClaim, address , tokenClaim, claimRoundId, tokenDecimal , signClaim) => {
    const amountTemp = convertTokenToWei(tokenClaim, tokenDecimal);
    const args = [address , amountTemp, claimRoundId, signClaim];
    console.log('args', args)
    const gas = await contractClaim.estimateGas.claimTokens(...args);
    console.log('gas', gas)
    return contractClaim.claimTokens(...args, { gasLimit: gas });
};

export const _totalClaimed = async (poolContract, account , Id) => {
    const res = poolContract && (await poolContract.userClaim(account , Id));
    return res;
};