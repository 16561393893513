import React, { useEffect, useState } from 'react';
import { useActiveWeb3React } from "../../../hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Table } from 'antd';
import { useHookAccount } from '../store';
import moment from 'moment';
import type { TabsProps } from 'antd';
import { useTranslation } from "react-i18next";

const RefLeaderBoard = () => {
    const { t, i18n }: any = useTranslation('common');
    const { account, chainId }: any = useActiveWeb3React();
    const [state, actions]: any = useHookAccount();

    const dataSource = state.dataLeaderBoard?.map((item: any, index: any) => ({
        key: index + 1 === 1 ? <div className="color-first">I</div> : index + 1 === 2 ?  <div className="color-second">II</div> : index + 1 === 3 ? <div className="color-third">III</div> : index + 1,
        ownerAddress : item.ownerAddress,
        totalPoint : <div className="color-ref-point">{item.totalPoint}</div>,
        lastUpdate : moment(item.lastUpdate).format('MMMM Do YYYY, h:mm:ss'),
        // lastUpdate : item.lastUpdate,
    }));

    const columns = [
        {
            title: 'No.',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: t('ref.ref-29', { framework: 'React' }),
            dataIndex: 'ownerAddress',
            key: 'wallet',
        },
        {
            title: t('ref.ref-31', { framework: 'React' }),
            dataIndex: 'totalPoint',
            key: 'point',
        },
        {
            title: t('ref.ref-42', { framework: 'React' }),
            dataIndex: 'lastUpdate',
            key: 'date',
        },
        // {
        //     title: 'Community',
        //     dataIndex: 'community',
        //     key: 'community',
        // }
    ];

    useEffect(() => {
        actions.getLeaderBoard()
    }, []);
    
    return (
        <>
            <div className='ref-block main-table'>     
                <Table className='table-ido' dataSource={dataSource} columns={columns} />;
            </div>
        </>
    )
}
export default RefLeaderBoard