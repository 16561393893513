import * as CryptoJS from 'crypto-js';
import { REACT_KEY } from '../constants';

const encodeData = (data:any) => {
  // const hash = CryptoJS.HmacSHA256(data, KEY_CRYPTOJS)
  const hash = CryptoJS.HmacSHA256(data, REACT_KEY)
  const hashInBase64 = CryptoJS.enc.Base64.stringify(hash)
  return hashInBase64
}
export default encodeData
