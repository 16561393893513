import React, { useEffect, useState } from 'react';
import { useActiveWeb3React } from "../../hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip, message, Modal, Tabs, Select } from 'antd';
import type { TabsProps } from 'antd';
import { useHookAccount } from './store';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const MyAccount = () => {
    const { t, i18n }: any = useTranslation('common');
    const { account, chainId }: any = useActiveWeb3React();
    const [state, actions]: any = useHookAccount();
    const [valueAddress, setValueAddress] = useState("")
    const [editAccount, setEditAccount] = useState(false);
    let communityStore = localStorage.getItem('communityStore');
    const [community, setCommunity] = useState(communityStore ? communityStore : '');
    let hisTab = localStorage.getItem('keyTab');
    const [activeTab, setActiveTab] = useState<any>(hisTab ? hisTab : 'my-account');
    const navigate = useNavigate()

    const params = {
        ownerAddress: account
    }

    useEffect(() => {
        account && actions.getOwnerAddress(params);
    }, [account]);

    const [isModalOpenWarning, setIsModalOpenWarning] = useState(false);
    const [isModalVip, setIsModalVip] = useState(false);

    const handleOk = () => {
        setIsModalOpenWarning(false);
    };

    const handleCancel = () => {
        setIsModalOpenWarning(false);
    };
    const handleOkVip = () => {
        setIsModalVip(false);
    };

    const handleCancelVip = () => {
        setIsModalVip(false);
    };

    const handleClick = () => {
        navigate(`/Ido?Tab=Ongoing`)
    }

    const TierText: any = (() => {
        switch (state.dataOwner.currentTier) {
            case 1:
                return "IRON";
            case 2:
                return "BRONZE";
            case 3:
                return "SILVER";
            case 4:
                return "GOLD";
            case 5:
                return "PLATINUM";
            case 6:
                return "DIAMOND";
            default:
                return "N/A";
        }
    });

    const ImgTier: any = (() => {
        switch (state.dataOwner.currentTier) {
            case 1:
                return <img src="./images/r-1.png" alt="" />;
            case 2:
                return <img src="./images/r-2.png" alt="" />;
            case 3:
                return <img src="./images/r-3.png" alt="" />;
            case 4:
                return <img src="./images/r-4.png" alt="" />;
            case 5:
                return <img src="./images/r-5.png" alt="" />;
            case 6:
                return <img src="./images/res-6.png" alt="" />;
            default:
                return <img src="./images/r-de.png" alt="" />;
        }
    });


    const TierLv1 = (
        <>
            <div className="box-tooltip">
                <div className="title">
                    Current Tier
                </div>
                <div className="level">
                    IRON
                </div>
                <div className="number-stake">
                    {state?.dataOwner?.balance}
                </div>
            </div>
        </>
    )

    const TierLv2 = (
        <>
            <div className="box-tooltip">
                <div className="title">
                    Current Tier
                </div>
                <div className="level">
                    BRONZE
                </div>
                <div className="number-stake">
                    {state?.dataOwner?.balance}
                </div>
            </div>
        </>
    )

    const TierLv3 = (
        <>
            <div className="box-tooltip">
                <div className="title">
                    Current Tier
                </div>
                <div className="level">
                    SILVER
                </div>
                <div className="number-stake">
                    {state?.dataOwner?.balance}
                </div>
            </div>
        </>
    )

    const TierLv4 = (
        <>
            <div className="box-tooltip">
                <div className="title">
                    Current Tier
                </div>
                <div className="level">
                    GOLD
                </div>
                <div className="number-stake">
                    {state?.dataOwner?.balance}
                </div>
            </div>
        </>
    )

    const TierLv5 = (
        <>
            <div className="box-tooltip">
                <div className="title">
                    Current Tier
                </div>
                <div className="level">
                    PLATINUM
                </div>
                <div className="number-stake">
                    {state?.dataOwner?.balance}
                </div>
            </div>
        </>
    )

    const TierLv6 = (
        <>
            <div className="box-tooltip">
                <div className="title">
                    Current Tier
                </div>
                <div className="level">
                    DIAMOND
                </div>
                <div className="number-stake">
                    {state?.dataOwner?.balance}
                </div>
            </div>
        </>
    )

    const handleChange = (e: any) => {
        setValueAddress(e.target.value)
    }

    const [messageRow, setMessageRow] = useState("")
    const [dataMessageRow, setDataMessageRow] = useState(false)

    const handleLinked = async () => {
        const paramsAccount = {
            ownerAddress: account,
            network: "brc",
            linkAddress: valueAddress
        }
        if (valueAddress && account) {
            actions.linkWalletAccount(paramsAccount).then((res: any) => {
                setDataMessageRow(res.data.data)
                if (res.data.data === true) {
                    // message.success({
                    //     type: "success",
                    //     content: "Linked success !!!",
                    //     className: "custom-class",
                    //     duration: 2,
                    // });
                    setIsModalOpenWarning(true)
                } else {
                    setMessageRow(res.data.message)
                    setIsModalOpenWarning(false)
                }
            })
        }
    }

    const handleEdit = () => {
        setEditAccount(true)
    }

    const handleClose = () => {
        setEditAccount(false)
        setMessageRow("")
    }

    const paramsLink = {
        ownerAddress: account,
    }

    useEffect(() => {
        account && actions.getWalletAccount(paramsLink)
    }, [account]);

    const handleModalVip = ()=>{
        setIsModalVip(true);
    }

    
    const onChangeSlt = (value: string) => {
        console.log(`selected ${value}`);
        localStorage.setItem('communityStore', value);
        setCommunity(value);
    };

    return (
        <>
            <div className="box-profile-user mt-30">
                <div className="left">
                    {state.dataOwner?.engleType == 1 && (
                        <>
                            <div className='vip-member' onClick={handleModalVip}>
                                <div className='dflex-left gap-10'>
                                    <img src='../images/vip.svg' />
                                    <div className='vip-info'>
                                        <p>{t('profile.you-our', { framework: 'React' })}</p>
                                        <div className='dflex-left gap-10 vip-title'>
                                            <span>{t('profile.angel-vip', { framework: 'React' })}</span>
                                            <div className='benefits text-white'>{t('profile.benefits', { framework: 'React' })}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    
                    <div className="content-box">
                        <div className="top">
                            <div className="logo-tier-icon">
                                {ImgTier(state.dataOwner?.currentTier)}
                            </div>
                            <div className="background-tier">
                                <img src="./images/bg-tier-pro.png" alt="" />
                            </div>
                            <div className="row-tier">
                                <div className="item">
                                    <img src="./images/logo-tier.png" alt="" />
                                </div>
                                <div className="item">
                                    <span> {t('profile.pro-1', { framework: 'React' })}</span> {state.dataOwner?.currentTier ? TierText(state.dataOwner?.currentTier) : "TBA"}
                                </div>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className={state.dataOwner?.currentTier === 0 ? "list-tier tier-0" : state.dataOwner?.currentTier === 1 ? "list-tier tier-1" : state.dataOwner?.currentTier === 2 ? "list-tier tier-2" : state.dataOwner?.currentTier === 3 ? "list-tier tier-3" : state.dataOwner?.currentTier === 4 ? "list-tier tier-4" : state.dataOwner?.currentTier === 5 ? "list-tier tier-5" : "list-tier tier-6"}>
                                {state.dataOwner?.currentTier === 0 ?
                                    <>
                                        <div className="item">
                                            <img src="./images/tier-1.png" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="./images/tier-2.png" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="./images/tier-3.png" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="./images/tier-4.png" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="./images/tier-5.png" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="./images/res-6.png" alt="" />
                                        </div>
                                    </>
                                    :
                                    <>
                                        {state.dataOwner?.currentTier === 1 ?
                                            <>
                                                <Tooltip title={TierLv1}>
                                                    <div className="item">
                                                        <img src="./images/tier-1.png" alt="" />
                                                    </div>
                                                </Tooltip>
                                                <div className="item">
                                                    <img src="./images/tier-2.png" alt="" />
                                                </div>
                                                <div className="item">
                                                    <img src="./images/tier-3.png" alt="" />
                                                </div>
                                                <div className="item">
                                                    <img src="./images/tier-4.png" alt="" />
                                                </div>
                                                <div className="item">
                                                    <img src="./images/tier-5.png" alt="" />
                                                </div>
                                                <div className="item">
                                                    <img src="./images/res-6.png" alt="" />
                                                </div>
                                            </>
                                            :
                                            <>
                                                {state.dataOwner?.currentTier === 2 ?
                                                    <>
                                                        <div className="item">
                                                            <img src="./images/tier-1.png" alt="" />
                                                        </div>
                                                        <Tooltip title={TierLv2}>
                                                            <div className="item">
                                                                <img src="./images/tier-2.png" alt="" />
                                                            </div>
                                                        </Tooltip>
                                                        <div className="item">
                                                            <img src="./images/tier-3.png" alt="" />
                                                        </div>
                                                        <div className="item">
                                                            <img src="./images/tier-4.png" alt="" />
                                                        </div>
                                                        <div className="item">
                                                            <img src="./images/tier-5.png" alt="" />
                                                        </div>
                                                        <div className="item">
                                                            <img src="./images/res-6.png" alt="" />
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {state.dataOwner?.currentTier === 3 ?
                                                            <>
                                                                <div className="item">
                                                                    <img src="./images/tier-1.png" alt="" />
                                                                </div>
                                                                <div className="item">
                                                                    <img src="./images/tier-2.png" alt="" />
                                                                </div>
                                                                <Tooltip title={TierLv3}>
                                                                    <div className="item">
                                                                        <img src="./images/tier-3.png" alt="" />
                                                                    </div>
                                                                </Tooltip>
                                                                <div className="item">
                                                                    <img src="./images/tier-4.png" alt="" />
                                                                </div>
                                                                <div className="item">
                                                                    <img src="./images/tier-5.png" alt="" />
                                                                </div>
                                                                <div className="item">
                                                                    <img src="./images/res-6.png" alt="" />
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                {state.dataOwner?.currentTier === 4 ?
                                                                    <>
                                                                        <div className="item">
                                                                            <img src="./images/tier-1.png" alt="" />
                                                                        </div>
                                                                        <div className="item">
                                                                            <img src="./images/tier-2.png" alt="" />
                                                                        </div>
                                                                        <div className="item">
                                                                            <img src="./images/tier-3.png" alt="" />
                                                                        </div>
                                                                        <Tooltip title={TierLv4}>
                                                                            <div className="item">
                                                                                <img src="./images/tier-4.png" alt="" />
                                                                            </div>
                                                                        </Tooltip>
                                                                        <div className="item">
                                                                            <img src="./images/tier-5.png" alt="" />
                                                                        </div>
                                                                        <div className="item">
                                                                            <img src="./images/res-6.png" alt="" />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {state.dataOwner?.currentTier === 5 ?
                                                                            <>
                                                                                <div className="item">
                                                                                    <img src="./images/tier-1.png" alt="" />
                                                                                </div>
                                                                                <div className="item">
                                                                                    <img src="./images/tier-2.png" alt="" />
                                                                                </div>
                                                                                <div className="item">
                                                                                    <img src="./images/tier-3.png" alt="" />
                                                                                </div>
                                                                                <div className="item">
                                                                                    <img src="./images/tier-4.png" alt="" />
                                                                                </div>
                                                                                <Tooltip title={TierLv5}>
                                                                                    <div className="item">
                                                                                        <img src="./images/tier-5.png" alt="" />
                                                                                    </div>
                                                                                </Tooltip>
                                                                                <div className="item">
                                                                                    <img src="./images/res-6.png" alt="" />
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className="item">
                                                                                    <img src="./images/tier-1.png" alt="" />
                                                                                </div>
                                                                                <div className="item">
                                                                                    <img src="./images/tier-2.png" alt="" />
                                                                                </div>
                                                                                <div className="item">
                                                                                    <img src="./images/tier-3.png" alt="" />
                                                                                </div>
                                                                                <div className="item">
                                                                                    <img src="./images/tier-4.png" alt="" />
                                                                                </div>
                                                                                <div className="item">
                                                                                    <img src="./images/tier-5.png" alt="" />
                                                                                </div>
                                                                                <Tooltip title={TierLv6}>
                                                                                    <div className="item">
                                                                                        <img src="./images/res-6.png" alt="" />
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row-current-tier">
                            <div className="item">
                                <div className="txt-top">
                                {t('profile.pro-2', { framework: 'React' })}
                                </div>
                                <div className="txt-bottom">
                                    <img src="./images/coin-row.png" alt="" /> {state.dataOwner.staking}
                                </div>
                            </div>
                            {/* <div className="item">
                                <div className="txt-top">
                                    Total FV staked
                                </div>
                                <div className="txt-bottom">
                                    <img src="./images/coin-row.png" alt="" /> {new Intl.NumberFormat('ja-JP').format(state.dataOwner.totalStaked)} FV
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="gr-button">
                        <Link to="/ido-pools">
                            <button className="btn btn-main mar-0-5">
                                {t('profile.pro-5', { framework: 'React' })}
                            </button>
                        </Link>
                    </div>
                </div>
                
                <div className="right">
                    <div className="group-account">
                        <div className="title-account">
                            {t('profile.pro-8', { framework: 'React' })}
                        </div>
                        <div className="box-account">
                            <div className="group-insert">
                                <div className="item-left">
                                    <div className="txt">
                                        {t('profile.pro-9', { framework: 'React' })}
                                    </div>
                                    <div className="input-address">
                                        <Select
                                            className='slt-filter'
                                            placeholder={<>{t('profile.pro-9', { framework: 'React' })}</>}
                                            optionFilterProp="children"
                                            value={community}
                                            onChange={onChangeSlt}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={[
                                                {
                                                    value: 'CMC',
                                                    label: 'CMC',
                                                },
                                                {
                                                    value: 'PLDX',
                                                    label: 'PLDX',
                                                },
                                                {
                                                    value: 'AMC',
                                                    label: 'AMC',
                                                },
                                                {
                                                    value: 'GAT',
                                                    label: 'GAT',
                                                },
                                                {
                                                    value: 'RATS',
                                                    label: 'RATS',
                                                },
                                                {
                                                    value: 'KINGU',
                                                    label: 'KINGU',
                                                },
                                                {
                                                    value: 'GDT',
                                                    label: 'GDT',
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div className="box-account">
                            <div className="group-insert">
                                <div className="item-left">
                                    <div className="txt">
                                        <img src="./images/wallet-2.png" alt="" /> {t('profile.pro-3', { framework: 'React' })}
                                    </div>
                                    <div className="input-address">
                                        <input type="text" placeholder='Enter address' value={`${account?.slice(0, 12)}...${account?.slice(-12)}`} />
                                        <CopyToClipboard
                                            text={`${account}`}
                                            onCopy={() => {
                                                message.success({
                                                    type: "success",
                                                    content: `${t('ido.ido-61', { framework: 'React' })}`,
                                                    className: "custom-class",
                                                    duration: 2,
                                                });
                                            }}
                                        >
                                            <img src="./images/copy.png" alt="" />
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="txt-linked">
                            <img src="./images/link.png" alt="" /> {t('profile.pro-4', { framework: 'React' })}
                        </div>
                        <div className="box-account">
                            <div className="enter-address">
                                <div className="txt">
                                    <img src="./images/icon-bit.png" alt="" /> BTC
                                </div>
                                <div className="gr-add">
                                    <div className="item">
                                        {state.accountLink && state.accountLink[0]?.address ?
                                            <>
                                                {editAccount ?
                                                    <>
                                                        <input type="text" placeholder={t('profile.pro-6', { framework: 'React' })} onChange={handleChange} />
                                                    </>
                                                    :
                                                    <>
                                                        <input type="text" value={`${state.accountLink[0]?.address?.slice(0, 12)}...${state.accountLink[0]?.address?.slice(-12)}`} />
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                <input type="text" placeholder={t('profile.pro-6', { framework: 'React' })} onChange={handleChange} />
                                            </>
                                        }
                                        <div className="row-warning-imput">
                                            {dataMessageRow === false && messageRow !== null && messageRow !== "" ?
                                                <>
                                                    {messageRow}
                                                </>
                                                :
                                                <>
                                                    {""}
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="item">
                                        {state.accountLink && state.accountLink[0]?.address ?
                                            <>
                                                {editAccount ?
                                                    <>
                                                        <button className="btn-copy" onClick={handleClose}>
                                                            <img src="./images/close.png" alt="" />
                                                        </button>
                                                        <button className="btn-copy" onClick={handleLinked}>
                                                            <img src="./images/confirm.png" alt="" />
                                                        </button>
                                                    </>
                                                    :
                                                    <>
                                                        <button className="btn-copy" onClick={handleEdit}>
                                                            <img src="./images/edit.png" alt="" />
                                                        </button>
                                                        <button className="btn-copy">
                                                            <CopyToClipboard
                                                                text={`${state.accountLink[0]?.address}`}
                                                                onCopy={() => {
                                                                    message.success({
                                                                        type: "success",
                                                                        content: `${t('ido.ido-61', { framework: 'React' })}`,
                                                                        className: "custom-class",
                                                                        duration: 3,
                                                                    });
                                                                }}
                                                            >
                                                                <img src="./images/copy-address.png" alt="" />
                                                            </CopyToClipboard>
                                                        </button>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                {valueAddress !== "" ?
                                                    <>
                                                        <button className="btn-add-wl" onClick={handleLinked}>
                                                        {t('profile.pro-7', { framework: 'React' })}
                                                        </button>
                                                    </>
                                                    :
                                                    <>
                                                        <button className="btn-add-wl disble">
                                                        {t('profile.pro-7', { framework: 'React' })}
                                                        </button>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="Link Address successfully!" className="modal-warning" open={isModalOpenWarning} onOk={handleOk} onCancel={handleCancel}>
                <div className="text-row">
                    You have successfully linked a new wallet address to your FVLaunch Profile.
                </div>
                <button className="btn-goto-account res" type="button" onClick={handleCancel}>
                    Stay in Profile
                </button>
                <button className="btn-goto-account res" type="button" onClick={handleClick}>
                    Browse Projects
                </button>
            </Modal>

            <Modal title={t('profile.benefits-for-angel', { framework: 'React' })} className="modal-vip" open={isModalVip} onOk={handleOkVip} onCancel={handleCancelVip}>
                <div className='modal-vip-info'>
                    <p className='p-vip mb-15'>
                        <img src='../images/checker.svg' />
                        <span className='text-blue-light font-16 weight-400'>{t('profile.benefits-1', { framework: 'React' })}</span>
                    </p>
                    <p className='p-vip mb-15'>
                        <img src='../images/checker.svg' />
                        <span className='text-blue-light font-16 weight-400'>{t('profile.benefits-2', { framework: 'React' })}</span>
                    </p>
                    <p className='p-vip mb-15'>
                        <img src='../images/checker.svg' />
                        <span className='text-blue-light font-16 weight-400'>{t('profile.benefits-3', { framework: 'React' })} </span>
                    </p>
                    <p className='p-vip mb-15'>
                        <img src='../images/checker.svg' />
                        <span className='text-blue-light font-16 weight-400'>{t('profile.benefits-4', { framework: 'React' })}</span>
                    </p>
                    <p className='p-vip mb-15'>
                        <img src='../images/checker.svg' />
                        <span className='text-blue-light font-16 weight-400'>{t('profile.benefits-5', { framework: 'React' })}</span>
                    </p>
                    <p className='p-vip mb-15'>
                        <img src='../images/checker.svg' />
                        <span className='text-blue-light font-16 weight-400'>{t('profile.benefits-6', { framework: 'React' })}</span>
                    </p>
                    <p className='p-vip mb-15'>
                        <img src='../images/checker.svg' />
                        <span className='text-blue-light font-16 weight-400'>{t('profile.benefits-7', { framework: 'React' })}</span>
                    </p>
                </div>
            </Modal>
        </>
    )
}
export default MyAccount