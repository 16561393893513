import React, { useEffect, useState } from 'react';
import { useActiveWeb3React } from "../../../hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Spin, message, Modal, Tabs, Row, Col, Select, Table } from 'antd';
import { DownCircleOutlined } from '@ant-design/icons';
import { useHookAccount } from '../store';
import type { TabsProps } from 'antd';
import { useTranslation } from "react-i18next";
import claimRefAbi from "../abi/claimRefAbi.json";
import { useContract } from "../../../hooks/useContract";
import { _claimTokens , _totalClaimed} from '../utils';
import { MAPPING_CHAINID } from '../../../constants';
import switchNetworkChain from "../../../utils/walletChain";

const RefRedeem = () => {
    const { t, i18n }: any = useTranslation('common');
    const { account, chainId }: any = useActiveWeb3React();
    const [state, actions]: any = useHookAccount();
    const [valueModal, setValueModal] = useState("false")
    const [defaultKey, setDefaultKey] = useState("false");

    function callback(key: any) {
        setDefaultKey(key);
        setValueModal(key)
        let params = {
            ownerAddress: account,
            pageIndex: 0,
            pageSize: 0,
            isRedeem: key === "true" ? true : false
        }
        if (account) {
            actions.getRedeemMarket(params)
        }
    }

    let params = {
        ownerAddress: account,
        pageIndex: 0,
        pageSize: 0,
        isRedeem: false
    }

    useEffect(() => {
        if (account) {
            actions.getRedeemMarket(params)
        }
    }, [account]);

    const Item = ({ item }: any) => {
        const [amount, setAmount] = useState()
        const [isModalOpen, setIsModalOpen] = useState(false);
        const contractClaim: any = useContract(item?.contractClaim, claimRefAbi);
        const [loading , setLoading] = useState(false)
        const [totalClaimed , setTotalClaimed] = useState(false)
        console.log('totalClaimed', totalClaimed)
        const onChange = (e: any) => {
            setAmount(e.target.value)
        }

        const showModal = () => {
            setIsModalOpen(true);
        };

        const showModaled = () => {
            setIsModalOpen(false);
        };

        const handleOk = () => {
            setIsModalOpen(false);
        };

        const handleCancel = () => {
            setIsModalOpen(false);
        };

        const handleMax = () => {
            setAmount(item?.tokenClaim)
        }

        const handleWithdraw = () => {
            let paramsRedeem = {
                ownerAddress: account,
                amount: Number(amount),
                voucherId: item?.voucherId
            }
            actions.getRedeem(paramsRedeem)
            message.success({
                type: "success",
                content: "Update success",
                className: "custom-class",
                duration: 2,
            });
            window.location.reload()
        }

        const handleClaim = async () => {
            try {
                if(contractClaim) {
                    setLoading(true)
                    await _claimTokens(
                        contractClaim,
                        item.tokenAddress,
                        item.tokenClaim,
                        item?.claimRoundId,
                        item?.tokenDecimal,
                        item?.signClaim
                    ).then((res) => {
                        res.wait().then((res1: any) => {
                            if (res1 !== null) {
                                if (res.hash !== null) {
                                    message.success({
                                        type: "success",
                                        content: "Claim success",
                                        className: "custom-class",
                                        duration: 2,
                                    });
                                }
                                setLoading(false)
                                window.location.reload()
                            }
                        });

                    })
                }
            } catch (error:any) {
                setLoading(false)
                message.error({
                    type: "error",
                    content: error.error.data.message,
                    className: "custom-class",
                    duration: 2,
                });
            }
        }

        useEffect(() => {
            _totalClaimed(contractClaim, account , item?.claimRoundId).then(
                (res: any) => {
                    setTotalClaimed(res);
                    console.log('res', res)
                }
            );
        }, []);

          useEffect(() => {
            if (account !== undefined)
                switchNetworkChain(
                    MAPPING_CHAINID[item?.tokenNetwork],
                    state.detailsIDO?.network
                );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [state.detailsIDO?.network, account, chainId]);

        return (
            <>
                <Col xs={24} sm={6}>
                    {valueModal === "true" ?
                        <>
                            <div className="redeem-item">
                                <div className='redeem-point' style={{ backgroundImage: `url(${item?.logoUrl})` }}>
                                </div>
                                <div className='redeem-line'>
                                    <span className='font-16 text-white mr-5'>{item?.name}</span> <img src='../images/checker.svg' />
                                </div>
                                <div className='redeem-line text-white'>
                                    <span className='font-20 weight-600 mr-5'>{item?.totalRefPoint}</span>
                                    <span className='text-gray'>{t('ref.ref-31', { framework: 'React' })}</span>
                                </div>
                                <div className='redeem-line'>
                                    <span className='text-gray'>{item?.quantityLeft} {t('ref.ref-37', { framework: 'React' })}</span>
                                </div>
                                <div className="redeem-button">
                                    {loading ?
                                        <>
                                            <button className='redeem-button-claim dis'>
                                                <Spin /> {t('ido.ido-26', { framework: 'React' })}
                                            </button>
                                        </>
                                        :
                                        <>
                                            {Number(totalClaimed) >= Number(item.tokenClaim) ?
                                                <>
                                                    <button className='redeem-button-claim dis'>
                                                        {t('ido.ido-26', { framework: 'React' })}
                                                    </button>
                                                </>
                                                :
                                                <>
                                                    <button className='redeem-button-claim' onClick={handleClaim}>
                                                    {t('ido.ido-59', { framework: 'React' })}
                                                    </button>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className={item?.isRedeem ? "redeem-item" : "redeem-item op-5"} onClick={item?.isRedeem ? showModal : showModaled}>
                                <div className='redeem-point' style={{ backgroundImage: `url(${item?.logoUrl})` }}>
                                </div>
                                <div className='redeem-line'>
                                    <span className='font-16 text-white mr-5'>{item?.name}</span> <img src='../images/checker.svg' />
                                </div>
                                <div className='redeem-line text-white'>
                                    <span className='font-20 weight-600 mr-5'>{item?.totalRefPoint}</span>
                                    <span className='text-gray'>{t('ref.ref-31', { framework: 'React' })}</span>
                                </div>
                                <div className='redeem-line'>
                                    <span className='text-gray'>{item?.quantityLeft} {t('ref.ref-37', { framework: 'React' })}</span>
                                </div>
                            </div>
                        </>
                    }
                </Col>
                {valueModal === "true" ?
                    <>
                        {""}
                    </>
                    :
                    <>
                        <Modal title="Warning!" className="modal-redeem" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                            <div className="body-modal-redeem">
                                <div className="columns">
                                    <div className="colum w-5">
                                        <div className="box-img">
                                            <img src={item?.logoUrl} alt="" />
                                        </div>
                                    </div>
                                    <div className="colum w-7">
                                        <div className="content-redeem-modal">
                                            <div className="txt">
                                                {item?.quantityLeft} {t('ref.ref-37', { framework: 'React' })}
                                            </div>
                                            <div className="title">
                                                {item?.name} <img src='../images/checker.svg' />
                                            </div>
                                            <div className="total-quality">
                                                <div className="top-redeem">
                                                    <div className="txt-left">
                                                    {t('ref.ref-39', { framework: 'React' })}
                                                    </div>
                                                    <div className="txt-right">
                                                        <span>{item?.tokenClaim}</span> {t('ref.ref-38', { framework: 'React' })}
                                                    </div>
                                                </div>
                                                <div className="enter-amount">
                                                    <input type="text" value={amount} onChange={onChange} placeholder='Enter an amount' className='f-control-amount' />
                                                    <button className='btn-bind-max' onClick={handleMax}>
                                                     {t('staking.max', { framework: 'React' })}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="required-point">
                                                <div className="left">
                                                    <div className="txt">
                                                    {t('ref.ref-40', { framework: 'React' })} <span>{item?.totalRefPoint}</span>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    {item?.tokenClaim > item?.totalRefPoint && Number(amount) >= item?.totalRefPoint && item?.tokenClaim >= Number(amount) ?
                                                        <>
                                                            <button className="confirm-btn" onClick={handleWithdraw}>
                                                            {t('ref.ref-41', { framework: 'React' })}
                                                            </button>
                                                        </>
                                                        :
                                                        <>
                                                            <button className="confirm-btn dis">
                                                            {t('ref.ref-41', { framework: 'React' })}
                                                            </button>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </>
                }
            </>
        )
    }

    return (
        <>
            <div className='ref-block main-table'>
                <div className='ref-ido-title'>{t('ref.ref-35', { framework: 'React' })}</div>
                <div className='ref-ido-desc'>{t('ref.ref-36', { framework: 'React' })}</div>
                <div className='slt-filter slt-custom stl-market'>
                    {/* <Select
                        defaultValue={valueModal}
                        style={{ width: 120 }}
                        onChange={handleChange}
                        options={[
                            { value: 'false', label: 'Redeem' },
                            { value: 'true', label: 'Redeemed' }
                        ]}
                    /> */}
                    <Tabs activeKey={defaultKey} onChange={callback}>
                        <Tabs.TabPane tab={t('ref.ref-23', { framework: 'React' })} key="false">

                        </Tabs.TabPane>
                        <Tabs.TabPane tab={t('ref.ref-43', { framework: 'React' })} key="true">
                            
                        </Tabs.TabPane>
                    </Tabs>
                </div>
                <div className='ref-redeem-list'>
                    <Row gutter={30}>
                        {state.dataRedeemMarket.length > 0 ?
                            <>
                                {state.dataRedeemMarket.map((item: any, index: any) => (
                                    <>
                                        <Item item={item} /> 
                                    </>
                                ))}
                            </>
                            :
                            <>
                                <div className="empty-data">
                                    <img src="./images/empty-data-1.png" alt="" />
                                </div>
                            </>
                        }
                    </Row>
                </div>
            </div>
        </>
    )
}
export default RefRedeem
