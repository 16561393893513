import { useEffect, useState } from 'react';
import "./style.scss"
import { useHookProjects } from "./store";
import { message , BackTop, Empty } from 'antd';
import { isMobile } from 'react-device-detect';
import { TypeAnimation } from "react-type-animation";
import BannerTop from "./images/banner-top.png"
import BannerFv from "./images/banner-fv-1.png"
import BannerUp from "./images/banner-up.png"
import BannerPac from "./images/banner-parker.png"
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useActiveWeb3React } from "../../hooks";
import ConnectWallet from '../../component/connect-wallet/ConnectWallet';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import { BaseApi } from '../../config/api/BaseApi';

const HomePage = () => {
    let langApi:any = localStorage.getItem("lang")
    const { t, i18n }: any = useTranslation('common');
    const { account, chainId } = useActiveWeb3React();
    const [state, actions]: any = useHookProjects();
    const [partnerList, setPartnerList] = useState([]);
    const navigate = useNavigate()
    useEffect(() => {
        actions.getUpcomingHome();
        actions.getReport();
        actions.getEndHome();
    }, []);

    // console.table(state.endListHome)

    const [valueSub, setValueSub] = useState("")

    const handleChange = (e: any) => {
        setValueSub(e.target.value)
    }

    const getPartnerList = async ()=>{
        let poolListApi = await BaseApi.getPartnerList();
        console.log('poolListApi', poolListApi?.data?.data);
        if(poolListApi.status == 200){
            setPartnerList(poolListApi?.data?.data);
        }
    }
    useEffect(() => {
        getPartnerList();
    }, []);

    const params = {
        email: valueSub
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 3000,
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const handleClick = () => {
        actions.getSubcrise(params).then((res: any) => {
            if (res.data.data === true) {
                message.success({
                    type: "success",
                    content: "Success !!!",
                    className: "custom-class",
                    duration: 2,
                });
                setValueSub("")
            } else {
                message.error({
                    type: "error",
                    content: res.data.message,
                    className: "custom-class",
                    duration: 2,
                });
            }
        })
    }

    // let titleText = ["FVLaunch Launchpad" , "FVLaunch Staking"]
    // let counter = 0
    // setInterval(function() {
    //     document.title = titleText[counter % titleText.length];
    //     counter++;
    // } , 2000);

    const handleClickOpen = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLScrFmz9q3MS3Ylgo23JETiaV3qA0WV2iehByMEzObxJokbREQ/viewform")
    }

    const Item = ({ item }: any) => {
        console.table(item)
        const handleOpenDetails = () => {
            window.location.replace(`/ido-details?key=${item?.keywork}`)
        }
        return (
            <>
                <div className="colum w-4">
                    <div className="guide-project" onClick={handleOpenDetails}>
                        <div className="bg-project" onClick={handleOpenDetails}>
                            <img src="./images/bg-project.png" alt="" />
                        </div>
                        <div className="box-img"  onClick={handleOpenDetails}>
                            <img src={item?.backgroundUrl} alt="" />
                        </div>
                        <div className="row-bottom">
                            <div className="icon">
                                <img src={item?.logoUrl} alt="" />
                            </div>
                            <div className="title-name"  onClick={handleOpenDetails}>
                                {item?.symbol}
                            </div>
                            <div className="desc"  onClick={handleOpenDetails}>
                                {item?.shortDesc}
                            </div>
                            <ul className="list-info-project">
                                <li>
                                    <div className="txt-l">
                                        {t('home.home-21', { framework: 'React' })}
                                    </div>
                                    <div className="txt-r">
                                        ${new Intl.NumberFormat('ja-JP').format(item?.totalRaised)}
                                    </div>
                                </li>
                                <li>
                                    <div className="txt-l">
                                        {t('home.home-22', { framework: 'React' })}
                                    </div>
                                    <div className="txt-r">
                                        {langApi === "cn" ? item?.startIdo_cn : item?.startIdo ? item?.startIdo : "TBA"}
                                    </div>
                                </li>
                            </ul>
                            <div className="gr-view">
                                {account ?
                                    <>
                                        <button className="btn btn-view-pool" onClick={handleOpenDetails}>
                                            {t('home.home-23', { framework: 'React' })}
                                        </button>
                                    </>
                                    :
                                    <>
                                        <div className="text-center-gr">
                                            <ConnectWallet />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const ItemEnd = ({ item }: any) => {
        const handleOpenDetails = () => {
            navigate(`/ido-details?key=${item?.keywork}`)
        }
        return (
            <>
                <div className={isMobile || state.endListHome.length <= 3 ? "colum w-4" : "colum"}>
                    <div className="guide-project" onClick={handleOpenDetails}>
                        <div className="bg-project">
                            <img src="./images/bg-project.png" alt="" />
                        </div>
                        <div className="box-img">
                            <img src={item?.backgroundUrl} alt="" />
                        </div>
                        <div className="row-bottom">
                            <div className="icon">
                                <img src={item?.logoUrl} alt="" />
                            </div>
                            <div className="title-name">
                                {item?.symbol}
                            </div>
                            <div className="desc">
                                {item?.shortDesc}
                            </div>
                            <ul className="list-info-project">
                                <li>
                                    <div className="txt-l">
                                        {t('home.home-21', { framework: 'React' })}
                                    </div>
                                    <div className="txt-r">
                                        ${new Intl.NumberFormat('ja-JP').format(item?.totalRaised)}
                                    </div>
                                </li>
                            </ul>
                            <div className="gr-view">
                                {account ?
                                    <>
                                        <button className="btn btn-view-pool" onClick={handleOpenDetails}>
                                            {t('home.home-23', { framework: 'React' })}
                                        </button>
                                    </>
                                    :
                                    <>
                                        <div className="text-center-gr">
                                            <ConnectWallet />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <section className="main-banner" style={{ backgroundImage: `url(${BannerTop})` }}>
                <div className="container">
                    <div className="content-banner">
                        <div className="coin-1 animation-scale">
                            <img src="./images/coin-1.png" alt="" />
                        </div>
                        <div className="coin-2 animation-scale">
                            <img src="./images/coin-2.png" alt="" />
                        </div>
                        {isMobile ?
                            <>
                                <div className="title">
                                    {t('home.home-1', { framework: 'React' })}
                                </div>
                            </>
                            :
                            <>
                                <div className="title">
                                    <TypeAnimation
                                        style={{ whiteSpace: 'pre-line', height: '195px', display: 'block' }}
                                        sequence={[
                                            "FV",
                                            1000,
                                            `FVLaunch\n${t('home.home-1', { framework: 'React' })}`,
                                            1000,
                                            "FV",
                                            1000,
                                            `FVLaunch\n${t('home.home-1', { framework: 'React' })}`,
                                            1000,

                                        ]}
                                        wrapper="span"
                                        speed={30}
                                        repeat={Infinity}
                                    />
                                </div>
                            </>
                        }
                        <div className="desc">
                            {t('home.home-2', { framework: 'React' })}
                        </div>
                        <div className="gr-button">
                            <Link to="/ido">
                                <button className="btn btn-main mar-0-5">
                                    {t('home.home-3', { framework: 'React' })}
                                </button>
                            </Link>
                            <button className="btn btn-main mar-0-5" onClick={handleClickOpen}>
                                {t('home.home-4', { framework: 'React' })}
                            </button>
                        </div>
                        <div className="group-content">
                            <div className="columns">
                                <div className="colum w-3">
                                    <div className="guide-tax">
                                        <div className="number">
                                            ${state.reportHome.mktCap}
                                        </div>
                                        <div className="text">
                                            {t('home.home-5', { framework: 'React' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3">
                                    <div className="guide-tax">
                                        <div className="number">
                                            {state.reportHome.ideasFunded}
                                        </div>
                                        <div className="text">
                                            {t('home.home-6', { framework: 'React' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3">
                                    <div className="guide-tax">
                                        <div className="number">
                                            ${state.reportHome.raisedCapital}
                                        </div>
                                        <div className="text">
                                            {t('home.home-7', { framework: 'React' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-3">
                                    <div className="guide-tax">
                                        <div className="number">
                                            {state.reportHome.uniqueParticipants} {t('ido.ido-73', { framework: 'React' })}
                                        </div>
                                        <div className="text">
                                            {t('home.home-8', { framework: 'React' })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="fv-launch">
                <div className="eclip">
                    <img src="./images/eclip.png" alt="" />
                </div>
                <div className="container">
                    <div className="content-fv-launch">
                        {/* <button className="btn-css">
                            Why use FVLaunch ?
                        </button> */}
                        <div className="title">
                            {t('home.home-9', { framework: 'React' })}
                        </div>
                        <div className="group-fv-launch">
                            <div className="columns">
                                <div className="colum w-6">
                                    <div className="guide-fv-launch" style={{ backgroundImage: `url(${BannerFv})` }}>
                                        <div className="img">
                                            <img src="./images/as.png" alt="" />
                                        </div>
                                        <div className="text">
                                            {t('home.home-10', { framework: 'React' })}
                                        </div>
                                        <div className="desc">
                                            {t('home.home-11', { framework: 'React' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-6">
                                    <div className="guide-fv-launch" style={{ backgroundImage: `url(${BannerFv})` }}>
                                        <div className="img">
                                            <img src="./images/fv-2.png" alt="" />
                                        </div>
                                        <div className="text">
                                            {t('home.home-12', { framework: 'React' })}
                                        </div>
                                        <div className="desc">
                                            {t('home.home-13', { framework: 'React' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-6">
                                    <div className="guide-fv-launch" style={{ backgroundImage: `url(${BannerFv})` }}>
                                        <div className="img">
                                            <img src="./images/fv-3.png" alt="" />
                                        </div>
                                        <div className="text">
                                            {t('home.home-14', { framework: 'React' })}
                                        </div>
                                        <div className="desc">
                                            {t('home.home-15', { framework: 'React' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-6">
                                    <div className="guide-fv-launch" style={{ backgroundImage: `url(${BannerFv})` }}>
                                        <div className="img">
                                            <img src="./images/fv-4.png" alt="" />
                                        </div>
                                        <div className="text">
                                            {t('home.home-16', { framework: 'React' })}
                                        </div>
                                        <div className="desc">
                                            {t('home.home-17', { framework: 'React' })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="upcoming-launch" style={{ backgroundImage: `url(${BannerUp})` }}>
                <div className="container">
                    <div className="content-up-coming">
                        <div className="title">
                            <span className="title-big">{t('home.home-18', { framework: 'React' })}</span> <Link to="/ido"><span className="title-small">{t('home.home-19', { framework: 'React' })} <img src="./images/arrow-right.png" alt="" /></span></Link>
                        </div>
                        <div className="row-upcoming">
                            <div className="columns jus-center">
                                {state.upcomingListHome.length === 0 ?
                                    <>
                                        <div className="empty-data">
                                            <img src="./images/empty-data-1.png" alt="" />
                                        </div>
                                    </>
                                    :
                                    <>
                                        {state.upcomingListHome.map((item: any, index: any) => (
                                            <>
                                                <Item item={item} />
                                            </>
                                        ))}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="upcoming-launch" style={{ backgroundImage: `url(${BannerUp})` }}>
                <div className="container">
                    <div className="content-up-coming">
                        <div className="title">
                            <span className="title-big">{t('home.home-20', { framework: 'React' })}</span>
                        </div>
                        <div className="row-upcoming">
                            <div className="columns jus-center">
                                {isMobile ?
                                    <>
                                        {state.endListHome.length === 0 ?
                                            <>
                                                <div className="empty-data">
                                                    <img src="./images/empty-data-1.png" alt="" />
                                                </div>
                                            </>
                                            :
                                            <>
                                                {state.endListHome.map((item: any, index: any) => (
                                                    <>
                                                        <ItemEnd item={item} />
                                                    </>
                                                ))}
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        {state.endListHome.length === 0 ?
                                            <>
                                                <div className="empty-data">
                                                    <img src="./images/empty-data-1.png" alt="" />
                                                </div>
                                            </>
                                            :
                                            <>
                                                {state.endListHome.length > 3 ?
                                                    <>
                                                        <Slider {...settings}>
                                                            {state.endListHome.map((item: any, index: any) => (
                                                                <>
                                                                    <ItemEnd item={item} />
                                                                </>
                                                            ))}
                                                        </Slider>
                                                    </>
                                                    :
                                                    <>
                                                        {state.endListHome.map((item: any, index: any) => (
                                                            <>
                                                                <ItemEnd item={item} />
                                                            </>
                                                        ))}
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <BackTop visibilityHeight={2000}>
                <div className="back-top-icon">
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="vertical-align-top" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M859.9 168H164.1c-4.5 0-8.1 3.6-8.1 8v60c0 4.4 3.6 8 8.1 8h695.8c4.5 0 8.1-3.6 8.1-8v-60c0-4.4-3.6-8-8.1-8zM518.3 355a8 8 0 00-12.6 0l-112 141.7a7.98 7.98 0 006.3 12.9h73.9V848c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V509.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 355z"></path></svg>
                </div>
            </BackTop>
            <section className="partner-backer">
                <div className="container">
                    <div className="row-partner">
                        <div className="title">
                            {t('home.home-24', { framework: 'React' })}
                        </div>
                        <ul className="list-partner">
                            <>
                                {partnerList?.length > 0 ? (
                                    partnerList.map((item:any, index) => {
                                        return(
                                            <li key={`par-`+ index}>
                                                <a href={item?.linkUrl} target='blank'>
                                                    <img src={item?.logoUrl} alt="" />
                                                </a>
                                            </li>
                                        )
                                    })
                                ) : (
                                    <>
                                        <div className="text-center"><Empty /></div>
                                    </>
                                )}
                            </>
                            {/* <li>
                                <a href="https://www.bscs.finance/" target='blank'>
                                    <img src="./images/partner/p-1.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://finstable.co.th/en" target='blank'>
                                    <img src="./images/partner/p-2.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://stepvc.net/" target='blank'>
                                    <img src="./images/partner/p-3.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://unisat.io/" target='blank'>
                                    <img src="./images/partner/p-4.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.bybit.com/en" target='blank'>
                                    <img src="./images/partner/p-10.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://multibit.exchange/" target='blank'>
                                    <img src="./images/partner/p-5.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://cityofdreams.io/" target='blank'>
                                    <img src="./images/partner/p-6.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://b2gc.finstable.co.th/en" target='blank'>
                                    <img src="./images/partner/p-7.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://launch.cheersland.org/#/fundraising" target='blank'>
                                    <img src="./images/partner/p-8.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://cryptodefizone.io/" target='blank'>
                                    <img src="./images/partner/p-9.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.kucoin.com/" target='blank'>
                                    <img src="./images/partner/p-11.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.bitget.com/" target='blank'>
                                    <img src="./images/partner/p-12.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://pancakeswap.finance/" target='blank'>
                                    <img src="./images/partner/p-13.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://biswap.org/" target='blank'>
                                    <img src="./images/partner/p-14.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.gate.io/" target='blank'>
                                    <img src="./images/partner/p-15.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://apeswap.finance/" target='blank'>
                                    <img src="./images/partner/p-16.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.mexc.com/" target='blank'>
                                    <img src="./images/partner/p-17.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.htx.com/" target='blank'>
                                    <img src="./images/partner/p-18.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.thruster.finance/" target='blank'>
                                    <img src="./images/partner/p-19.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.thruster.finance/" target='blank'>
                                    <img style={{ width: '100px', margin: 'auto' }} src="./images/partner/dragonlogo.png" alt="" />
                                </a>
                            </li> */}
                        </ul>
                        <div className="title">
                            {t('ido.ido-85', { framework: 'React' })}
                        </div>
                        <ul className="list-partner">
                            <li>
                                <a href="https://ethereum.org/en/" target='blank'>
                                    <img src="./images/network/x-1.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.binance.com/en" target='blank'>
                                    <img src="./images/network/x-2.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.base.org/" target='blank'>
                                    <img src="./images/network/x-3.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://arbitrum.io/" target='blank'>
                                    <img src="./images/network/x-4.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://polygon.technology/" target='blank'>
                                    <img src="./images/network/x-5.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.brc-20.io/" target='blank'>
                                    <img src="./images/network/x-6.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://aptoslabs.com/" target='blank'>
                                    <img src="./images/network/x-7.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.xiprotocol.io/#/" target='blank'>
                                    <img src="./images/network/x-8.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://polkadot.network/" target='blank'>
                                    <img src="./images/network/x-9.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.optimism.io/" target='blank'>
                                    <img src="./images/network/x-10.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://solana.com/" target='blank'>
                                    <img src="./images/network/x-11.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://tron.network/" target='blank'>
                                    <img src="./images/network/x-12.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://fantom.foundation/" target='blank'>
                                    <img src="./images/network/x-13.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://sui.io/" target='blank'>
                                    <img src="./images/network/x-14.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://dogechain.dog/" target='blank'>
                                    <img src="./images/network/x-15.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://blast.io/en" target='blank'>
                                    <img src="./images/network/x-16.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.avax.network/" target='blank'>
                                    <img src="./images/network/x-17.png" alt="" />
                                </a>
                            </li>
                        </ul>
                        <div className="box-parker" style={{ backgroundImage: `url(${BannerPac})` }}>
                            <div className="parker-content">
                                <div className="logo">
                                    <img src="./images/logo.png" alt="" />
                                </div>
                                <div className="txt">
                                    {t('home.home-25', { framework: 'React' })}
                                </div>
                                <div className="gr-button-parker">
                                    <button className='btn btn-parker' type="button" onClick={handleClickOpen}>
                                        {t('home.home-26', { framework: 'React' })}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="subcrise-mail">
                            <div className="row-mail-l">
                                <div className="txt">
                                    {t('home.home-27', { framework: 'React' })}
                                </div>
                                <div className="desc">
                                    {t('home.home-28', { framework: 'React' })}
                                </div>
                            </div>
                            <div className="row-mail-r">
                                <input onChange={handleChange} type="text" className="f-control" placeholder={`${t('ido.ido-16', { framework: 'React' })}`} /> <button onClick={handleClick} className="btn-sub">{t('home.home-29', { framework: 'React' })}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default HomePage