import React , {useState} from 'react';
import './App.css';
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { getLibrary, Web3ProviderNetwork } from "./context/web3provider";
import { RefreshContextProvider } from "./context/RefreshContext";
import Providers from "./Providers";
import Main from './component/main';
import HomePage from './pages/home-page';
import IdoLaunchPad from './pages/ido-launchpad';
import IDOPools from './pages/ido-pool';
import DetailsIDO from './pages/ido-launchpad/details-launchpad';
import FullSearchProject from './pages/ido-launchpad/fulsearch-project';
import StartPool from './pages/staking';
import FarmPool from './pages/farm-pool';
import ProfileUser from './pages/profile-user';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

function App() {
    i18next.init({
        interpolation: { escapeValue: false },
    });

    const [chainHeader , setChainHeader]:any = useState()

    const callbackFunction = (childData: any) => {

        setChainHeader(childData)
    };

    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ProviderNetwork getLibrary={getLibrary}>
                <RefreshContextProvider>
                    <div className="App">
                        <I18nextProvider i18n={i18next}>
                            <Main chainHeader={chainHeader}>
                                <Routes>
                                    <Route path="/" element={<HomePage />} />
                                    {/* <Route path="/ido-pools" element={<Providers><IDOPools /></Providers>}/> */}
                                    <Route
                                        path="/ido-pools"
                                        element={
                                            <Providers>
                                                <IDOPools />
                                            </Providers>
                                        }
                                    />
                                    <Route path="/start-pool" element={<Providers><StartPool /></Providers>}/>
                                    <Route path="/farms" element={<FarmPool />}/>
                                    <Route path="/ido" element={<IdoLaunchPad />} />
                                    <Route path="/ido-details" element={<DetailsIDO parentCallback={callbackFunction} />} />
                                    <Route path="/full-search-project" element={<FullSearchProject />} />
                                    <Route path="/profile" element={<ProfileUser />} />
                                </Routes>
                            </Main>
                        </I18nextProvider>
                    </div>
                </RefreshContextProvider>
            </Web3ProviderNetwork>
        </Web3ReactProvider>
    );
}

export default App;
