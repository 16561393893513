import React , { useEffect } from 'react';
import BoxApply from '../../component/box-apply';
import { useHookProjects } from "./store";
import { useNavigate } from "react-router-dom";
import { NETWORK } from "./index.d";
import { useActiveWeb3React } from "../../hooks";
import ConnectWallet from '../../component/connect-wallet/ConnectWallet';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message } from 'antd';
import { useTranslation } from "react-i18next";


const TabsOngoing = () => {
    const { t, i18n }: any = useTranslation('common');
    let langApi:any = localStorage.getItem("lang")
    const navigate = useNavigate()
    const { account, chainId } = useActiveWeb3React();
    const [state, actions]: any = useHookProjects();

    useEffect(() => {
        actions.getOpenIDO();
    }, []);

    const ether = (
        <>
            <img src="./images/ether.png" alt="" />
        </>
    );
    const poly = (
        <>
            <img src="./images/poly.png" alt="" />
        </>
    );
    const bsc = (
        <>
            <img src="./images/icon-bnb-ido.png" alt="" />
        </>
    );

    const blast = (
        <>
            <img src="./images/blast.svg" alt="" />
        </>
    );

    const brc = (
        <>
            <img src="./images/icon-bit.png" alt="" />
        </>
    );

    const arb = (
        <>
            <img src="./images/arb.png" alt="" />
        </>
    );
    const base = (
        <>
            <img src="./images/base.svg" alt="" />
        </>
    );
    const avax = (
        <>
            <img src="./images/avax.svg" alt="" />
        </>
    );

    const fillIconChain = (network: any) => {
        switch (network) {
            case NETWORK.BSC:
                return bsc;
            case NETWORK.Poly:
                return poly;
            case NETWORK.Ethereum:
                return ether;
            case NETWORK.BLAST:
                return blast;
            case NETWORK.ARB:
                return arb;
            case NETWORK.BRC:
                return brc;
            case NETWORK.BASE:
                return base;
            case NETWORK.Avax:
                return avax;
            default:
                return "---";
            break;
        }
    };

    const fillNameChain = (network: any) => {
        switch (network) {
            case NETWORK.BSC:
                return 'BSC';
            case NETWORK.Poly:
                return 'POLYGON';
            case NETWORK.Ethereum:
                return 'ETHEREUM';
            case NETWORK.BLAST:
                return 'BLAST';
            case NETWORK.ARB:
                return 'ARB';
            case NETWORK.BRC:
                return 'BRC';
            case NETWORK.BASE:
                return 'BASE';
            case NETWORK.Avax:
                return 'AVALANCHE';
            default:
                return "---";
            break;
        }
    };

    const Item = ({ item }: any) => {
        const handleOpenDetails = () => {
            window.location.replace(`/ido-details?key=${item?.keywork}`)
        }
        return (
            <>
                <div className="colum w-4">
                    <div className="guide-project">
                        <div className="bg-project" onClick={handleOpenDetails}>
                            <img src="./images/bg-project.png" alt="" />
                        </div>
                        <div className="box-img" onClick={handleOpenDetails}>
                            <img src={item?.bannerUrl} alt="" />
                        </div>
                        <div className="row-bottom">
                            <div className="icon" onClick={handleOpenDetails}>
                                <img src={item?.claimTokenInfo?.logoUrl} alt="" />
                            </div>
                            <div className="gr-social-ido">
                                    {item?.socical?.website !== null ?
                                        <>
                                            <div className="item" onClick={()=>(window.open(`${item?.socical?.website}`))}>
                                                <img src="./images/ido-web.png" alt="" />
                                            </div>
                                        </>
                                        :
                                        <>
                                            {""}
                                        </>
                                    }
                                    {item?.socical?.twitter !== null ?
                                        <>
                                            <div className="item" onClick={()=>(window.open(`${item?.socical?.twitter}`))}>
                                                <img src="./images/ido-x.png" alt="" />
                                            </div>
                                        </>
                                        :
                                        <>
                                            {""}
                                        </>
                                    }
                                    {item?.socical?.telegram !== null ?
                                        <>
                                            <div className="item" onClick={()=>(window.open(`${item?.socical?.telegram}`))}>
                                                <img src="./images/ido-tele.png" alt="" />
                                            </div> 
                                        </>
                                        :
                                        <>
                                            {""}
                                        </>
                                    }
                                    {item?.socical?.medium !== null ?
                                        <>
                                            <div className="item" onClick={()=>(window.open(`${item?.socical?.medium}`))}>
                                                <img src="./images/ido-m.png" alt="" />
                                            </div>
                                        </>
                                        :
                                        <>
                                            {""}
                                        </>
                                    }
                                </div>
                            <div className="title-name" onClick={handleOpenDetails}>
                                {langApi === "cn" ? item?.name_cn : langApi === "ru" ? item?.name_ru : item?.name}
                            </div>
                            <div className="desc" onClick={handleOpenDetails}>
                                {langApi === "cn" ? item?.description_cn : langApi === "ru" ? item?.description_ru : item?.description}
                            </div>
                            <div className="box-info-ido" onClick={handleOpenDetails}>
                                <div className="item">
                                    <div className="l">
                                        {t('ido.ido-10', { framework: 'React' })}:
                                    </div>
                                    <div className="r">
                                        {item?.swapAmount}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="l">
                                        {t('ido.ido-19', { framework: 'React' })}:
                                    </div>
                                    <div className="r">
                                        {langApi === "cn" ? item?.totalRaise_cn : langApi === "ru" ? item?.totalRaise_ru : item?.totalRaise} {item?.claimTokenInfo.symbol}
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="l">
                                        {t('ido.ido-12', { framework: 'React' })}:
                                    </div>
                                    <div className="r">
                                        {item?.totalSupply} {item?.claimTokenInfo.symbol}
                                    </div>
                                </div>
                            </div>
                            <ul className="list-info-project">
                            <li>
                                    <div className="txt-l">
                                        {t('ido.ido-13-1', { framework: 'React' })}:
                                    </div>
                                    <div className="txt-r color-main">
                                        {fillIconChain(item?.joinPoolInfo?.network)} {fillNameChain(item?.joinPoolInfo?.network)}
                                    </div>
                                </li>
                                <li>
                                    <div className="txt-l">
                                        {t('ido.ido-13', { framework: 'React' })}:
                                    </div>
                                    <div className="txt-r color-main">
                                        {fillIconChain(item?.claimTokenInfo?.network)} {fillNameChain(item?.claimTokenInfo?.network)}
                                    </div>
                                </li>
                                <li>
                                    <div className="txt-l">
                                        {t('ido.ido-14', { framework: 'React' })}:
                                    </div>
                                    <div className="txt-r color-main">
                                        {item?.claimTokenInfo?.tokenAddress?.slice(0, 8)}...{item?.claimTokenInfo?.tokenAddress?.slice(-8)}
                                        <CopyToClipboard
                                            text={`${item?.claimTokenInfo?.tokenAddress}`}
                                            onCopy={() => {
                                                message.success({
                                                    type: "success",
                                                    content: `${t('ido.ido-61', { framework: 'React' })}`,
                                                    className: "custom-class",
                                                    duration: 2,
                                                });
                                            }}
                                        >
                                            <img src="./images/copy.png" alt="" className="mar-l-5" />
                                        </CopyToClipboard>
                                    </div>
                                </li>
                            </ul>
                            <div className="gr-view">
                                {account ?
                                    <>
                                        <button className="btn btn-view-pool" onClick={handleOpenDetails}>
                                            {t('ido.ido-15', { framework: 'React' })}
                                        </button>
                                    </>
                                    :
                                    <>
                                        <div className="text-center-gr">
                                            <ConnectWallet />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="group-upcoming">
                <div className="row-content">
                    <div className="row-upcoming">
                        {state.openIDO.length === 0 ?
                            <>
                                <div className="empty-data">
                                    <img src="./images/empty-data-1.png" alt="" />
                                </div>
                            </>
                            :
                            <>
                                <div className="columns align-center">
                                    {state.openIDO.map((item: any, index: any) => (
                                        <>
                                            <Item item={item} />
                                        </>
                                    ))}
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className="box-apply-row">
                    <BoxApply />
                </div>
            </div>
        </>
    )
}
export default TabsOngoing