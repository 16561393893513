import React from 'react';
import BannerPool from  "./images/banner-pool.png"
import TopFunction from './topFunction';
import BottomFunction from './bottomFunction';
import "./style.scss"

const IdoLaunchPad = () => {
    return (
        <>
            <section className="section-ido">
                <div className="row-ido-launchpad">
                    <div className="top-function" style={{backgroundImage: `url(${BannerPool})`}}>
                        <TopFunction />
                    </div>
                    <div className="bottom-function">
                        <BottomFunction />
                    </div>
                </div>
            </section>
        </>
    )
}
export default IdoLaunchPad