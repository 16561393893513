/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import Web3 from "web3";
import { getERC20Contract } from "../utilsST/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import { useWallet } from "use-wallet";
import { approveSousChef } from "../utilsST/callHelpers";
import stakeFinal from "../abi/stakeFinal.json";
import { useContract } from "../hooks/useContract";

const useSousApprove = (tokenAddress, poolAddress) => {
  const { account } = useWeb3React();
  const web3 = new Web3(window.ethereum);
  const contract = useContract(tokenAddress, stakeFinal);
  const handleApprove = useCallback(async () => {

    const txHash = await approveSousChef(contract, poolAddress, account);
    return txHash;
  }, [account, poolAddress, tokenAddress, web3]);

  return { onApprove: handleApprove };
};

export default useSousApprove;
