import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { BscConnector } from './bsc';
import { WalletConnector } from './walletConnect';

const NETWORK_URL = 43114;
const NETWORK_CHAIN_ID = 43114;

export const walletconnect = new WalletConnectConnector({
	//@ts-ignore
	rpc: { [NETWORK_CHAIN_ID]: NETWORK_URL },
	infuraId:'6b8d72d49ea944a487fbb532f17c0009',
	bridge: 'https://bridge.walletconnect.org',
	qrcode: true,
	pollingInterval: 15000
});

export const walletconnect1 = new WalletConnector({});

const avax = 43114
const okc = 66
const poly = 137
const eth = 1
const bnb = 56
const ftm = 250
const arb = 42161
const op =10
const celo = 42220
const bnb_test = 97
const cro = 25
const moon = 1284
const metis = 1088
const heco = 128
const velas = 106
const multi =62621
const blast = 81457
const base = 8453


export const injected = new InjectedConnector({
	supportedChainIds: [eth, 3, 4, 5, 42, bnb, bnb_test,multi,
		poly, 80001, okc , ftm, avax,arb, op,celo,cro,moon,metis,heco,velas , blast, base]
});

export const bsc = new BscConnector({
	supportedChainIds: [eth, 3, 4, 5, 42, bnb, bnb_test,multi,
		poly, 80001, okc , ftm, avax,arb, op,celo,cro,moon,metis,heco,velas , blast, base]
});
