import React, { useMemo, useState, useCallback } from 'react'
import UnstakingFeeCountdownRow from './UnstakingFeeCountdownRow'

import { getFullDisplayBalance } from '../../utilsST/formatBalance'
import Input from '../../componentsST/InputV2/index'
import Modal from '../../componentsST/Modal/index'
import Button from '../../componentsST/ButtonV2/index'

import '../../componentsST/StakeInPoolModal/index.less'
import { useContract } from "../../hooks/useContract";
import sousChefAbi from "../../config/abi/sousChef.json";
import poolsConfig from '../../constantsST/pools'
import { sousUnstake, sousUnstakeUserLock } from "../../utilsST/callHelpers";
import { message, Spin } from "antd";
import { useWeb3React } from '@web3-react/core'
import { BaseApi } from '../../config/api/BaseApi'
import { useTranslation } from 'react-i18next'

const WithdrawModal = (props) => {
	const { t, i18n } = useTranslation('common');
	const { account } = useWeb3React();
	const [pendingTx, setPendingTx] = useState(false)
	const {
		sousId,
		decimals,
		pool,
		onConfirm,
		onDismiss,
		max,
		stakingToken,
		fees,
		lastStakingBlock,
		blockPeriod,
	} = props

	const fullBalance = useMemo(() => {
		return getFullDisplayBalance(max)
	}, [max])

	const [val, setVal] = useState(fullBalance)

	const handleChange = useCallback(
		(e) => {
			setVal(e.currentTarget.value)
		},
		[setVal],
	)

	const handleSelectMax = useCallback(() => {
		setVal(fullBalance)
	}, [fullBalance, setVal])

	// const pool = poolsConfig.find((pool) => pool.sousId === sousId);
	const contract = useContract(pool.contractAddress, sousChefAbi);
	const handleUnstake = async () => {
    try {
      setPendingTx(true);
      await sousUnstakeUserLock(contract, val, decimals).then((res) => {
        setPendingTx(true);
        res.wait().then(async (res2) => {
			if(res2 != null ){

				let payload = {
					"ownerAddress": account.toLowerCase(),
					"poolContract": pool.contractAddress,
					"txHash": res?.hash
				}

				await BaseApi.poolSet(payload).then((res)=>{
					message.success({
						type: "success",
						content: t('staking.unstaking_success', { framework: 'React' }),
						className: "custom-class",
						duration: 3,
						
					});
					onDismiss()
					setPendingTx(true);
					setTimeout(() => {
						window.location.reload();
					}, 1000);
				}).catch((error)=>{
					message.success({
						type: "error",
						content: t('staking.unstaking_failed', { framework: 'React' }),
						className: "custom-class",
						duration: 3,
						
					});
					setPendingTx(true);
				});
					
				// setPendingTx(false);
				// onDismiss()
				// message.success({
				//   type: "success",
				//   content: "Unstaking success!",
				//   className: "custom-class",
				//   duration: 3,
				  
				// });
			  }
         
        }).catch((error) => {
			setPendingTx(false);
			
			if (error.code == 4001 && error.message) {
				
				message.error({
					type: "error",
					content: error.message,
					className: "custom-class",
					duration: 3,
				});
			} else if (error.reason) {
				message.error({
					type: "error",
					content: error.reason,
					className: "custom-class",
					duration: 3,
				});
			} else {
				if (error.data && error.data.message) {
					message.error({
						type: "error",
						content: error.data.message,
						className: "custom-class",
						duration: 3,
					});
				}
			}
			
		});
      })
      .catch((error) => {
        setPendingTx(false);
		if (error.code == 4001 && error.message) {
				
			message.error({
				type: "error",
				content: error.message,
				className: "custom-class",
				duration: 3,
			});
		} else if (error.reason) {
			message.error({
				type: "error",
				content: error.reason,
				className: "custom-class",
				duration: 3,
			});
		} else {
			if (error.data && error.data.message) {
				message.error({
					type: "error",
					content: error.data.message,
					className: "custom-class",
					duration: 3,
				});
			}
		}
      });
    } catch (e) {
      message.error({
        type: "error",
        content: e?.message,
        className: "custom-class",
        duration: 3,
      });
      setPendingTx(false);
    }
  };
	return (
		<Modal>
			<div className="bsc-stake_in_pool-modal">
				<div className="bsc-stake_in_pool-modal-header">
					<span>{t('staking.unstake_in_pool', { framework: 'React' })}</span>
					<span>{t('staking.unstake', { framework: 'React' })} {` ${stakingToken.symbol}`}</span>
				</div>
				<div className="bsc-stake_in_pool-modal-content">
					<div className="bsc-stake_in_pool-modal-content-top">
						<span>{new Intl.NumberFormat("ja-JP").format(Number(getFullDisplayBalance(max, stakingToken.decimals)).toFixed(1))}</span>
						<span>{`${stakingToken.symbol}`} {t('staking.available', { framework: 'React' })}</span>
					</div>
					<div className="bsc-stake_in_pool-modal-content-mid">
						<Input
							change={handleChange}
							type="number"
							readonly={true}
							value={val}
							// right={
							// 	<>
							// 		<span>{stakingToken.symbol}</span>
							// 		<button onClick={handleSelectMax} className="btn-max-deposit">Max</button>
							// 	</>
							// }
						/>
					</div>
					<div className="mx-4 mb-2">
						<UnstakingFeeCountdownRow
							fees={fees}
							lastStakingBlock={lastStakingBlock}
							blockPeriod={blockPeriod}
						/>
					</div>
					<div className="bsc-stake_in_pool-modal-content-bottom mb-4">
						
						
						<button onClick={onDismiss} className="btn-color-default mr-5">{t('staking.cancel', { framework: 'React' })}</button>
						<button
							className="btn-color"
							disabled={pendingTx || val <= 0}
							onClick={handleUnstake}
						>{t('staking.confirm', { framework: 'React' })} {pendingTx && (<Spin />)}</button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default WithdrawModal
