import React , {useEffect} from "react";
import { Layout } from "antd";
import Header from "../header";
import Footer from "../footer";
import { useActiveWeb3React } from "../../hooks";
import { useCookies } from "react-cookie";

const { Content } = Layout;
function Main({ children , chainHeader}: any) {
    const [cookies, setCookie] = useCookies(["user", "account"]);
    const { account } = useActiveWeb3React();

    useEffect(() => {
        if (account !== undefined && account) {
            setCookie("user", account);
        } else {
            setCookie("user", undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);
    return (
        <Layout>
            <Header chainHeader={chainHeader}/>
            <Content>{children}</Content>
            <Footer />
        </Layout>
    );
}

export default Main;