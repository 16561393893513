import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Progress, Popover, message, Spin, Modal, Empty } from 'antd';
import { useActiveWeb3React } from "../../../hooks";
import ConnectWallet from '../../../component/connect-wallet/ConnectWallet';
import { AbiItem } from "web3-utils";
import { Link } from "react-router-dom";
import Web3 from "web3";
import { useHookProjects } from "../store";
import switchNetworkChain from "../../../utils/walletChain";
import { MAPPING_CHAINID, NAMEBYCHAINID } from "../../../constants";
import {
    _approveBUSD,
    convertWeiToToken,
    _joinPoolNew,
    _isJoined,
    _refundChange,
    _isClaimChange,
    _claimTokens,
    _isRefundedRC,
    _showClaimBtn,
    _totalRefunded,
    _totalClaimed,
    _refund,
    totalJoinPool
} from '../utilsIdo';
import Countdown from "react-countdown";
import { STATUS, NETWORK } from "../index.d";
import { useContract } from "../../../hooks/useContract";
import abiBUSD from "../../../abi/abiBUSD.json";
import abiJoinPool from "../../../abi/abiJoinPoolNew.json";
import abiClaimOneButton from "../../../abi/abiClaimOneButton.json";
import abiClaimNew from "../../../abi/abiClaimNew.json";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

const web3 = new Web3("https://bsc-dataseed1.binance.org:443");

const Item = ({ item }: any) => {
    let langApi:any = localStorage.getItem("lang")
    const { t, i18n }: any = useTranslation('common');
    const { account, chainId } = useActiveWeb3React();
    let navigate = useNavigate()
    let location = useLocation();
    const [state, actions]: any = useHookProjects();
    let nameChainId = chainId ? NAMEBYCHAINID[chainId] : '';
    const [isModalOpenDis, setIsModalOpenDis] = useState(false);

    const showModalDis = () => {
        setIsModalOpenDis(true);
    };

    const handleOkDis = () => {
        setIsModalOpenDis(false);
    };

    const handleCancelDis = () => {
        setIsModalOpenDis(false);
        
    };

    const rendererCountDown = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }:any) => {
        // Render a countdown
        if (completed) {
            return <span>{t('ido.ido-79', { framework: 'React' })}</span>
        }
        if (days > 1) {
            return (
                <span>
                    {days}{t('ido.ido-80', { framework: 'React' })} : {hours}{t('ido.ido-81', { framework: 'React' })} : {minutes}{t('ido.ido-82', { framework: 'React' })} : {seconds}{t('ido.ido-83', { framework: 'React' })}
                </span>
            );
        } else if (days === 1) {
            return (
                <span>
                    {days}{t('ido.ido-80', { framework: 'React' })} : {hours}{t('ido.ido-81', { framework: 'React' })} : {minutes}{t('ido.ido-82', { framework: 'React' })} : {seconds}{t('ido.ido-83', { framework: 'React' })}
                </span>
            );
        } else {
            return (
                <span>
                    {hours}{t('ido.ido-81', { framework: 'React' })} : {minutes}{t('ido.ido-82', { framework: 'React' })} : {seconds}{t('ido.ido-83', { framework: 'React' })}
                </span>
            );
        }
    };


    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPool, setIsLoadingPool] = useState(false);
    const [reCheck, setReCheck] = useState(false);
    const [isJoined, setIsJoined] = useState(false);
    const [isRefundedRC, setRefundedFromSC] = useState(false);
    const [isApproveJoin, setApproveJoin] = useState(false);
    const [disnableJoin, setDisnableJoin] = useState(false);
    const [isShowClaim, setIsShowClaim] = useState(false);
    const [totalRefunded, setTotalRefunded] = useState(0);
    const [isLoadingRefundAll, setLoadingRefundAll] = useState(false);
    const is_commit_fund = item?.allocation && item?.allocation?.claimChange?.refundContract !== null;
    const [valueSubmit, setValueSubmit] = useState<any>(0);
    const [totalClaimed, setTotalClaimed] = useState<any>(0);
    const [isSubmit, setIsSubmit] = useState(false);
    const addressUsdt = state.detailsIDO?.joinPoolInfo?.tokenAddress
    const addressJoin = item?.commitFundContract
    const addressRefundChange = item?.allocation?.claimChange?.refundContract
    const contractUsdt: any = useContract(addressUsdt, abiBUSD);
    const joinPoolContract = useContract(addressJoin, abiJoinPool);

    const claimChangeContract = useContract(addressRefundChange, abiClaimOneButton);
    const scClaimAddress = item?.allocation?.claimbles?.claimContract
    const claimContract = useContract(scClaimAddress, abiClaimNew);
    const [isLoadingClaimChange, setLoadingClaimChange] = useState(false);
    const [isClaimChangeRC, setClaimChangeFromSC] = useState(false);
    const is_allow_joinpool = item.allocation && item.allocation.commitFunds?.commitFundSignature !== null && item.status === 3;
    const [amounJoinNotConnect, setamounJoinNotConnect] = useState(0);
    const [totalJoined, setTotalJoined] = useState<any>(0);
    let isFullSlot = false;

    const handleChangeSubmit = (e: any) => {
        setValueSubmit(e.target.value)
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const submitAllocation = async (value: any) => {
        try {
            setIsLoadingSubmit(true);

            let params = {
                ownerAddress: account,
                roundId: item?.id,
                amount: Number(value),
            };

            actions.submitAllocation(params).then((res: any) => {
                if (res.data.data) {
                    setIsSubmit(res);
                }
                if (res.data.data) {
                    setIsLoadingSubmit(false);
                    window.location.reload();
                } else {
                    message.error({
                        type: "error",
                        content: res.data.message,
                        className: "custom-class",
                        duration: 3,
                    });
                    setIsLoadingSubmit(false);
                }
            });
        } catch (error) {
            message.error({
                type: "error",
                content: "Submit error",
                className: "custom-class",
                duration: 3,
            });
            setIsLoadingSubmit(false);
        }
    };

    const _approveJoinPool = async () => {
        setIsLoading(true);
        await _approveBUSD(
            contractUsdt,
            addressJoin,
            item?.allocation?.commitFunds?.commitFund * 10,
            item?.allocation?.commitFunds?.commitFundTokenDecimal
        )
            .then((res: any) => {
                if (res.hash !== null) {
                    res.wait().then((res1: any) => {
                        if (res1 !== null) {
                            setIsLoading(false);
                            setReCheck(!reCheck);
                            if (!is_allow_joinpool) {
                                setDisnableJoin(true);
                            }
                            message.success({
                                type: "success",
                                content: `${t('ido.ido-62', { framework: 'React' })}`,
                                className: "custom-class",
                                duration: 2,
                            });
                        } else {
                            setIsLoading(false);
                            setApproveJoin(false);

                            message.error({
                                type: "error",
                                content: "Error",
                                className: "custom-class",
                                duration: 2,
                            });
                        }
                    });
                }
            })
            .catch((error: any) => {
                message.error({
                    type: "error",
                    content: error?.code,
                    className: "custom-class",
                    duration: 2,
                });
                setIsLoading(false);
            });
    };

    const _handleJoinPool = async () => {
        setIsLoadingPool(true);
        await _joinPoolNew(
            joinPoolContract,
            item?.allocation?.commitFunds?.commitFund,
            item.id,
            item?.allocation?.commitFunds?.commitFundSignature,
            chainId,
            item?.allocation?.commitFunds?.commitFundTokenDecimal
        )
            .then((res: any) => {
                if (res.hash !== null) {
                    // call api Join
                    const paramsJoined = {
                        ownerAddress: account,
                        roundId: item?.id,
                        txHash: res.hash,
                    };
                    actions.commitedJoined(paramsJoined);
                    // end call api
                    res.wait().then((res1: any) => {
                        if (res1 !== null) {
                            setIsJoined(true);
                            setIsLoadingPool(false);
                            message.success({
                                type: "success",
                                content: `${t('ido.ido-63', { framework: 'React' })}`,
                                className: "custom-class",
                                duration: 2,
                            });
                        }
                    });
                } else {
                    message.error({
                        type: "error",
                        content: "Error",
                        className: "custom-class",
                        duration: 2,
                    });
                }
            })
            .catch((error: any) => {
                message.error({
                    type: "error",
                    content: error?.data?.message || error?.reason,
                    className: "custom-class",
                    duration: 2,
                });
                // setIsLoadingPool(false);
            });
    };

    // check approve when reload page
    useEffect(() => {
        if (account) {
            contractUsdt.allowance(account, addressJoin).then((res: any) => {
                if (
                    Number(convertWeiToToken(res, item?.allocation?.commitFunds?.commitFundTokenDecimal)) > 0 &&
                    Number(convertWeiToToken(res, item?.allocation?.commitFunds?.commitFundTokenDecimal) >= item?.allocation?.commitFunds?.commitFund)
                ) {
                    setApproveJoin(true);
                } else {
                    setApproveJoin(false);
                }
            });
        }
    }, [account, addressJoin, isSubmit, reCheck, item?.allocation?.commitFunds]);

    useEffect(() => {
        if (account) {
            _isClaimChange(claimChangeContract, account).then((res: any) => {
                setClaimChangeFromSC(res);
            });

            if (item?.allocation?.claimbles) {

                _totalClaimed(claimContract, account, item?.allocation?.commitFunds?.commitFundTokenDecimal).then(
                    (res: any) => {
                        setTotalClaimed(res);
                    }
                );

                _totalRefunded(claimContract, account, item?.allocation?.claimbles?.tokenRefundDecimal).then(
                    (res: any) => {
                        setTotalRefunded(res);
                    }
                );

                _isRefundedRC(
                    claimContract,
                    account,
                    item?.allocation?.claimbles?.refundAllUsd,
                    item?.allocation?.claimbles?.tokenRefundDecimal
                ).then((res: any) => {
                    setRefundedFromSC(res);
                });
            }

            if (item?.allocation?.joinStatus === true) {
                setIsJoined(true);
                setApproveJoin(true)
            } else if (addressJoin !== null && addressJoin !== "") {
                _isJoined(joinPoolContract, account, item.id, item.id).then(
                    (res: any) => {
                        setIsJoined(res);
                    }
                );
            }
        }
    }, [account, addressJoin, isApproveJoin, totalRefunded, isJoined, isShowClaim, isRefundedRC, item?.allocation?.joinStatus]);

    useEffect(() => {
        if (account && item?.allocation?.claimbles) {
            _showClaimBtn(
                claimContract,
                account,
                item.allocation?.claimbles?.claimToken,
                item.allocation?.claimbles?.tokenClaimDecimal
            ).then((res: any) => {
                setIsShowClaim(res);
            }).catch(err => {
                console.log('err', err)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, claimContract, scClaimAddress, item.allocation, item.allocation?.claimbles?.tokenClaimDecimal]);

    const _handleClaimChange = async () => {
        try {
            setLoadingClaimChange(true);
            await _refundChange(
                claimChangeContract,
                item?.allocation?.claimChange?.refundBusd,
                item?.allocation?.claimChange?.signRefund,
                item?.allocation?.commitFunds?.commitFundTokenDecimal
            )
                .then((res: any) => {
                    res.wait().then((res1: any) => {
                        if (res1 !== null) {
                            setLoadingClaimChange(false);
                            setClaimChangeFromSC(true);
                            message.success({
                                type: "success",
                                content: `${t('ido.ido-64', { framework: 'React' })}`,
                                className: "custom-class",
                                duration: 2,
                            });
                        }
                    });
                })
                .catch((err: any) => {
                    message.warning({
                        type: "warning",
                        content: err.error?.data?.message,
                        className: "custom-class",
                        duration: 2,
                    });
                    setLoadingClaimChange(false);
                });

            return true;
        } catch (error: any) {
            setLoadingClaimChange(false);
            if (error?.data) {
                message.warning({
                    type: "warning",
                    content: error?.data?.message,
                    className: "custom-class",
                    duration: 2,
                });
            }
            return false;
        }
    };

    const _handleClaim = async () => {
        console.log('1', 1)
        console.log('first',  claimContract,
        item.allocation.claimbles,
        item?.allocation?.claimbles?.tokenClaimDecimal,
        item?.allocation?.claimbles?.tokenRefundDecimal)
        if (item.allocation.claimbles) {
            try {
                setIsLoading(true);
                await _claimTokens(
                    claimContract,
                    item.allocation.claimbles,
                    item?.allocation?.claimbles?.tokenClaimDecimal,
                    item?.allocation?.claimbles?.tokenRefundDecimal
                ).then((res: any) => {
                    res.wait().then((res1: any) => {
                        if (res1 !== null) {
                            setIsLoading(false);
                            setIsShowClaim(false);
                            setRefundedFromSC(false);
                            if (state.detailsIDO?.claimTokenInfo?.network === "brc") {
                                setIsModalOpenDis(true)
                                message.success({
                                    type: "success",
                                    content: `${t('ido.ido-65', { framework: 'React' })}`,
                                    className: "custom-class",
                                    duration: 2,
                                });
                            } else {
                                message.success({
                                    type: "success",
                                    content: `${t('ido.ido-66', { framework: 'React' })}`,
                                    className: "custom-class",
                                    duration: 2,
                                });
                            }
                        } else {
                            setIsLoading(false);
                            message.success({
                                type: "error",
                                content: `${t('ido.ido-67', { framework: 'React' })}`,
                                className: "custom-class",
                                duration: 2,
                            });
                        }
                    });
                });
            } catch (error: any) {
                console.log('error', error)
                setIsLoading(false);
                if (error?.error?.data) {
                    message.error({
                        type: "error",
                        content: error?.error?.data?.message,
                        className: "custom-class",
                        duration: 2,
                    });
                }
            }
        } else {
            message.success({
                type: "error",
                content: `${t('ido.ido-67', { framework: 'React' })}`,
                className: "custom-class",
                duration: 2,
            });
        }
    };

    const _handleRefund = async () => {
        try {
            setLoadingRefundAll(true);
            await _refund(
                claimContract,
                item.allocation?.claimbles?.refundAllUsd,
                item.allocation?.claimbles?.refundAllToken,
                item?.allocation?.claimbles?.signBusd,
                item?.allocation?.claimbles?.tokenClaimDecimal,
                item?.allocation?.claimbles?.tokenRefundDecimal
            ).then((res: any) => {
                res.wait().then((res1: any) => {
                    if (res1 !== null) {
                        setLoadingRefundAll(false);
                        setRefundedFromSC(false);
                        setIsShowClaim(false);
                        message.success({
                            type: "success",
                            content: `${t('ido.ido-68', { framework: 'React' })}`,
                            className: "custom-class",
                            duration: 2,
                        });
                    } else {
                        message.success({
                            type: "error",
                            content: `${t('ido.ido-69', { framework: 'React' })}`,
                            className: "custom-class",
                            duration: 2,
                        });
                    }
                });
            });
        } catch (error: any) {
            setLoadingRefundAll(false);
            if (error?.error?.message) {
                message.error({
                    type: "error",
                    content: error?.error?.data?.message,
                    className: "custom-class",
                    duration: 2,
                });
            }
            return false;
        }
    };

    useEffect(() => {
        totalJoinPool(joinPoolContract).then((res: any) => {
            const _totalJoined = convertWeiToToken(res, state.detailsIDO?.joinPoolInfo?.tokenDecimal);
            setTotalJoined(Number(_totalJoined).toFixed(1));
        });
    }, [addressJoin, joinPoolContract]);

    const getTotalJoin = async () => {
        const scJoin = new web3.eth.Contract(
            abiJoinPool as unknown as AbiItem,
            addressJoin
        );
        const amountCheck = await scJoin.methods.totalJoined().call()
        setamounJoinNotConnect(amountCheck / (1 * 10 ** item?.allocation?.commitFunds?.commitFundTokenDecimal) || 0);
    };

    useEffect(() => {
        getTotalJoin();
    }, []);

    let numberPrice = item?.totalRaise * item?.price
    let _percentProcess = 0;
    let totalJoinedBe = 0

    const fillterChain: any = (() => {
        switch (chainId) {
            case 56:
                return "bep";
            case 81457:
                return "blast";
            case 42161:
                return "arb";
            case 8453:
                return "base";
            case 43114:
                return "avax";
            default:
                return "---";
        }
    });

    let totalJoinedSC = totalJoined

    if (item?.totalRaise > 0) {
        if (!item?.ignoreFullSlot && totalJoined >= item?.totalRaise) isFullSlot = true;
        const _percent = account ? (totalJoined / numberPrice) * 100 : (amounJoinNotConnect / item?.totalRaise) * 100;

        if ((Number(item.percentProcess) === 0) && state.detailsIDO?.joinPoolInfo.network === fillterChain(chainId) ) {
            if (_percent > item.percentProcess) {
                _percentProcess = _percent;
                totalJoinedBe = item.percentProcess * numberPrice / 100;
            }
        } else {
            totalJoinedSC = 0
            _percentProcess = item.percentProcess;
            totalJoinedBe = item.percentProcess * numberPrice / 100;
        }
         
        // if(Number(item.percentProcess) > 0 && state.detailsIDO?.joinPoolInfo.network === fillterChain(chainId)){
        //     _percentProcess = item.percentProcess;
        //     totalJoinedBe = item.percentProcess * numberPrice / 100;
        // }else{
        //     if (_percent > item.percentProcess) {
        //         _percentProcess = _percent;
        //     } else {
        //         _percentProcess = item.percentProcess;
        //         totalJoinedBe = item.percentProcess * numberPrice / 100;
        //     }
        // }
    }
    
    let percentProcess: any = Number(_percentProcess).toFixed(0);

    const ItemModal = ({ items }: any) => {
        return (
            <>
                <tr>
                    <td>
                        {items?.claimToken}
                    </td>
                    <td>
                        {items?.claimTime}
                    </td>
                    <td>
                        {items?.percentage}
                    </td>
                </tr>
            </>
        )
    }

    return (
        <>
            <div className="round-item-ido">
                <div className="round-item-content">
                    <div className="top-row">
                        <div className="title">
                            {langApi === "cn" ? item?.name_cn : langApi === "ru" ? item?.name_ru : item?.name} 
                        </div>
                        <div className="open-time">
                            {item.status === STATUS.COMING ? (
                                <>
                                    <img src="./images/icon-time.png" alt="" /> {t('ido.ido-33', { framework: 'React' })} : TBA
                                </>
                            ) : item.status === STATUS.GOING && item.startOpenIdo !== null ? (
                                <>
                                    {is_commit_fund ? (
                                        <>
                                            <img src="./images/icon-time.png" alt="" /> {t('ido.ido-33', { framework: 'React' })}:
                                        </>
                                    ) : (
                                        <>
                                            <img src="./images/icon-time.png" alt="" /> {t('ido.ido-33', { framework: 'React' })}:
                                        </>
                                    )}
                                    <span className="times-join-detail">
                                        <Countdown
                                            date={item.startOpenIdo * 1000}
                                            renderer={rendererCountDown}
                                        />
                                    </span>
                                </>
                            ) : item.status === STATUS.OPEN && item.closeJoinIdo !== null ? (
                                <>
                                    {is_commit_fund ? (
                                        <>
                                            <img src="./images/icon-time.png" alt="" /> {t('ido.ido-34', { framework: 'React' })}:
                                        </>
                                    ) : (
                                        <>
                                            <img src="./images/icon-time.png" alt="" /> {t('ido.ido-34', { framework: 'React' })}:
                                        </>
                                    )}{" "}
                                    <span className="times-join-detail">
                                        <Countdown
                                            date={item.closeJoinIdo * 1000}
                                            renderer={rendererCountDown}
                                        />
                                    </span>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className="center-row">
                        <div className="item">
                            <div className="text-l">
                                {t('ido.ido-19', { framework: 'React' })}:
                            </div>
                            <div className="text-r">
                                ${langApi === "cn" ? item?.totalRaise_cn : langApi === "ru" ? item?.totalRaise_ru : new Intl.NumberFormat('ja-JP').format(item?.totalRaise * item?.price)}
                            </div>
                        </div>
                        <div className="item">
                            <div className="text-l">
                                {t('ido.ido-21', { framework: 'React' })}:
                            </div>
                            <div className="text-r">
                                {item?.swapAmount}
                            </div>
                        </div>
                        <div className="item">
                            <div className="text-l">
                                {t('ido.ido-35', { framework: 'React' })}:
                            </div>
                            <div className="text-r">
                                {langApi === "cn" ? item?.startDate_cn : langApi === "ru" ? item?.startDate_ru : item?.startDate}
                            </div>
                        </div>
                        <div className="item">
                            <div className="text-l">
                                {t('ido.ido-37', { framework: 'React' })}:
                            </div>
                            <div className="text-r res">
                                <Popover content={langApi === "cn" ? item?.idoVesting_cn : langApi === "ru" ? item?.idoVesting_ru : item?.idoVesting} title="Title">
                                    {langApi === "cn" ? item?.idoVesting_cn : langApi === "ru" ? item?.idoVesting_ru : item?.idoVesting}
                                </Popover>
                            </div>
                        </div>
                        <div className="item">
                            <div className="text-l">
                                {t('ido.ido-36', { framework: 'React' })}:
                            </div>
                            <div className="text-r">
                                {langApi === "cn" ? item?.endDate_cn : langApi === "ru" ? item?.endDate_ru : item?.endDate}
                            </div>
                        </div>
                    </div>
                    <div className="bottom-row">
                        <div className="commit-progess">
                            <div className="txt-commit">
                                {t('ido.ido-38', { framework: 'React' })}:
                            </div>
                            <div className="progess-bar">
                                <Progress percent={_percentProcess} status="active" />
                            </div>
                            <div className="txt-percent">
                                <span className="percent">
                                    {percentProcess}%
                                </span>
                                <span className="number">
                                    {new Intl.NumberFormat('ja-JP').format(totalJoinedSC > totalJoinedBe ? totalJoinedSC : Number(totalJoinedBe).toFixed(1))} {state.detailsIDO?.joinPoolInfo?.symbol} / {new Intl.NumberFormat('ja-JP').format(item?.totalRaise * item?.price)} {state.detailsIDO?.joinPoolInfo?.symbol}
                                </span>
                            </div>
                        </div>
                        {account ?
                            <>
                                {item?.allocation?.commitFunds?.commitFund === 0 && item?.allocation?.commitFunds?.minCommitUsd > 0 && item?.allocation?.commitFunds?.maxCommitUsd > 0 ?
                                    <>
                                        {(!item?.isFcfs && item.status !== STATUS.CLOSE) || (item?.isFcfs && item.status === STATUS.OPEN) ?
                                            <>
                                                <div className="commit-fund">
                                                    <div className="txt-fund">
                                                        <span>{t('ido.ido-71', { framework: 'React' })}: {item?.allocation?.commitFunds?.minCommitUsd} USDT</span>
                                                        <span>{t('ido.ido-72', { framework: 'React' })}: {item?.allocation?.commitFunds?.maxCommitUsd} USDT</span>
                                                    </div>
                                                    <div className="input-commit">
                                                        <input onChange={handleChangeSubmit} type="text" placeholder={`${t('ido.ido-84', { framework: 'React' })}`} className="input-enter-amount" />
                                                        <span className="dollar">$</span>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                {""}
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        {item?.allocation?.commitFunds?.commitFund > 0 ?
                                            <>
                                                {item?.allocation?.commitFunds?.minCommitUsd > 0 && item?.allocation?.commitFunds?.maxCommitUsd > 0 ? (
                                                    <div className="txt-commit-funds">
                                                        {t('ido.ido-70', { framework: 'React' })}: <span>{item?.allocation?.commitFunds?.commitFund} {state.detailsIDO?.joinPoolInfo?.symbol}</span>
                                                    </div>
                                                ) : (
                                                    <div className="txt-commit-funds">
                                                        {t('ido.ido-42', { framework: 'React' })}: <span>{item?.allocation?.commitFunds?.commitFund} {state.detailsIDO?.joinPoolInfo?.symbol}</span>
                                                    </div>
                                                )}
                                            </>
                                            :
                                            <>
                                                {""}
                                            </>
                                        }
                                    </>
                                }
                                {isJoined && item.status === STATUS.CLOSE && item?.allocation ?
                                    <>
                                        <div className="commit-allocation">
                                            <div className="txt-allo">
                                                {t('ido.ido-25', { framework: 'React' })}:
                                            </div>
                                            {is_commit_fund ?
                                                <>
                                                    {item.status === STATUS.CLOSE && item?.allocation?.claimChange?.signRefund !== null ?
                                                        <>
                                                            {isClaimChangeRC ?
                                                                <>
                                                                    <div className="remaining-fund">
                                                                        <div className="text-l">
                                                                            {t('ido.ido-39', { framework: 'React' })}:
                                                                        </div>
                                                                        <div className="text-r">
                                                                            {Number(item?.allocation?.claimChange?.refundBusd).toFixed(2)} {state.detailsIDO?.joinPoolInfo?.symbol}
                                                                            <span>
                                                                                <button className="btn-claim disible">{t('ido.ido-40', { framework: 'React' })}:</button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    {item?.allocation?.claimChange?.allowClaimChange === false ?
                                                                        <>
                                                                            <div className="remaining-fund">
                                                                                <div className="text-l">
                                                                                    {t('ido.ido-39', { framework: 'React' })}:
                                                                                </div>
                                                                                <div className="text-r">
                                                                                    {Number(item?.allocation?.claimChange?.refundBusd).toFixed(2)} {state.detailsIDO?.joinPoolInfo?.symbol}
                                                                                    <span>
                                                                                        <button className="btn-claim disible">{t('ido.ido-59', { framework: 'React' })}</button>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="remaining-fund">
                                                                                <div className="text-l">
                                                                                    {t('ido.ido-39', { framework: 'React' })}:
                                                                                </div>
                                                                                <div className="text-r">
                                                                                    {Number(item?.allocation?.claimChange?.refundBusd).toFixed(2)} {state.detailsIDO?.joinPoolInfo?.symbol}
                                                                                    <span>
                                                                                        {isLoadingClaimChange ?
                                                                                            <>
                                                                                                <button className="btn-claim disible"> <Spin /> {t('ido.ido-59', { framework: 'React' })}</button>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <button className="btn-claim" onClick={_handleClaimChange}>{t('ido.ido-59', { framework: 'React' })}</button>
                                                                                            </>
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <div className="remaining-fund">
                                                                <div className="text-l">
                                                                    {t('ido.ido-39', { framework: 'React' })}:
                                                                </div>
                                                                <div className="text-r">
                                                                    {Number(item?.allocation?.claimChange?.refundBusd).toFixed(2)} {state.detailsIDO?.joinPoolInfo?.symbol}
                                                                    <span>
                                                                        <button className="btn-claim disible">{t('ido.ido-41', { framework: 'React' })}:</button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {""}

                                                </>
                                            }
                                            <div className="group-allocation">
                                                <div className="item">
                                                    <div className="top" onClick={showModal}>
                                                        {t('ido.ido-24', { framework: 'React' })}: &nbsp; 🕰
                                                    </div>
                                                    <div className="bottom">
                                                        {item?.allocation?.nextClaim !== null ? item?.allocation?.nextClaim : "---"}
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="top">
                                                        {t('ido.ido-40', { framework: 'React' })}
                                                    </div>
                                                    <div className="bottom">
                                                        {totalClaimed}
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="top">
                                                        {t('ido.ido-25', { framework: 'React' })}
                                                    </div>
                                                    <div className="bottom">
                                                        {item?.allocation?.tokenAllocation} {state.detailsIDO?.claimTokenInfo?.symbol}
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="top">
                                                        {t('ido.ido-42', { framework: 'React' })}
                                                    </div>
                                                    <div className="bottom">
                                                        {item?.allocation?.commitFunds?.commitFund} {state.detailsIDO?.joinPoolInfo?.symbol}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {""}
                                    </>
                                }
                            </>
                            :
                            <>
                                {""}
                            </>
                        }
                    </div>
                    <div className="action-row">
                        {account ?
                            <>
                                {item?.allocation === null && account ?
                                    <>
                                        <button className="btn btn-action disible">
                                            {t('ido.ido-43', { framework: 'React' })}
                                        </button>
                                    </>
                                    :
                                    <>
                                        {item?.allocation?.commitFunds?.commitFund === 0 && item?.allocation?.commitFunds?.minCommitUsd > 0 && item?.allocation?.commitFunds?.maxCommitUsd > 0 ?
                                            <>
                                                {item.status == STATUS.GOING || item.status == STATUS.OPEN ? (
                                                    <>
                                                        {item?.isFcfs && item.status == STATUS.GOING ? (
                                                            <button className="btn btn-action disible">
                                                                {t('ido.ido-43', { framework: 'React' })}
                                                            </button>
                                                        ) : (
                                                            <>
                                                                {item?.allocation?.commitFunds?.commitFundContract === null ?
                                                                    <>
                                                                        <button className="btn btn-action disible">
                                                                            {t('ido.ido-44', { framework: 'React' })}
                                                                        </button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="button-action">
                                                                            {/* action disble: disible */}
                                                                            {isLoadingSubmit ?
                                                                                <>
                                                                                    <button className="btn btn-action disible">
                                                                                        <Spin /> {t('ido.ido-44', { framework: 'React' })}
                                                                                    </button>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <button className="btn btn-action" onClick={() => submitAllocation(valueSubmit)}>
                                                                                        {t('ido.ido-44', { framework: 'React' })}
                                                                                    </button>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                }
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {item?.isFcfs ? (
                                                            <>
                                                                <button className="btn btn-action disible">
                                                                    {t('ido.ido-45', { framework: 'React' })}
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {""}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                            :
                                            <>
                                                {!isApproveJoin ?
                                                    <>
                                                        {item.status === STATUS.CLOSE ?
                                                            <>
                                                                <button className="btn btn-action disible">
                                                                    {t('ido.ido-45', { framework: 'React' })}
                                                                </button>
                                                            </>
                                                            :
                                                            <>
                                                                {isLoading ?
                                                                    <>
                                                                        <button className="btn btn-action disible">
                                                                            <Spin /> {t('ido.ido-46', { framework: 'React' })}
                                                                        </button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <button className="btn btn-action" onClick={_approveJoinPool}>
                                                                            {t('ido.ido-46', { framework: 'React' })}
                                                                        </button>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {isJoined ?
                                                            <>
                                                                {item.status === STATUS.CLOSE ?
                                                                    <>
                                                                        <div className="round-claim-after-joined">
                                                                            {!isShowClaim ?
                                                                                <>
                                                                                    {Number(totalRefunded) > 0 ?
                                                                                        <>
                                                                                            <button className="btn btn-action disible">
                                                                                                {t('ido.ido-47', { framework: 'React' })}   
                                                                                            </button>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {item?.allocation?.claimbles === null ?
                                                                                                <>
                                                                                                    <button className="btn btn-action disible">
                                                                                                        {t('ido.ido-48', { framework: 'React' })}
                                                                                                    </button>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {state.detailsIDO?.claimTokenInfo?.network === "brc" ?
                                                                                                        <>
                                                                                                            <button className="btn btn-action disible">
                                                                                                                {state.detailsIDO?.tokenDistributed === null ?
                                                                                                                    <>
                                                                                                                        {t('ido.ido-49', { framework: 'React' })} 
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        {state.detailsIDO?.tokenDistributed}
                                                                                                                    </>
                                                                                                                }
                                                                                                            </button>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <button className="btn btn-action disible">
                                                                                                                {t('ido.ido-40', { framework: 'React' })} 
                                                                                                            </button>
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {state.detailsIDO?.claimTokenInfo?.network === "brc" ?
                                                                                        <>
                                                                                            {isLoading ?
                                                                                                <>
                                                                                                    <button className="btn btn-action disible">
                                                                                                        <Spin /> {t('ido.ido-50', { framework: 'React' })} 
                                                                                                    </button>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {isLoadingRefundAll ?
                                                                                                        <>
                                                                                                            <button className="btn btn-action disible">
                                                                                                                {t('ido.ido-50', { framework: 'React' })} 
                                                                                                            </button>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <button className="btn btn-action" onClick={_handleClaim}>
                                                                                                                {t('ido.ido-50', { framework: 'React' })} 
                                                                                                            </button>
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {isLoading ?
                                                                                                <>
                                                                                                    <button className="btn btn-action disible">
                                                                                                        <Spin /> {t('ido.ido-59', { framework: 'React' })}
                                                                                                    </button>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {isLoadingRefundAll ?
                                                                                                        <>
                                                                                                            <button className="btn btn-action disible">
                                                                                                            {t('ido.ido-59', { framework: 'React' })}
                                                                                                            </button>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <button disabled={state.detailsIDO?.network !== nameChainId} className="btn btn-action" onClick={_handleClaim}>
                                                                                                            {t('ido.ido-59', { framework: 'React' })}
                                                                                                            </button>
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    }

                                                                                    {item?.allocation?.claimbles !== null && item?.allocation?.claimbles?.signBusd !== null ?
                                                                                        <>
                                                                                            {isLoadingRefundAll ?
                                                                                                <>
                                                                                                    <button className="btn btn-action disible">
                                                                                                        <Spin /> {t('ido.ido-60', { framework: 'React' })}
                                                                                                    </button>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {isLoading ?
                                                                                                        <>
                                                                                                            <button className="btn btn-action disible">
                                                                                                            {t('ido.ido-60', { framework: 'React' })}
                                                                                                            </button>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <button disabled={state.detailsIDO?.network !== nameChainId} className="btn btn-action" onClick={_handleRefund}>
                                                                                                            {t('ido.ido-60', { framework: 'React' })}
                                                                                                            </button>
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {""}
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <button className="btn btn-action disible">
                                                                        {t('ido.ido-48', { framework: 'React' })}
                                                                        </button>
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {item.status === STATUS.CLOSE ? (
                                                                    <>
                                                                        <button className="btn btn-action disible">
                                                                        {t('ido.ido-46', { framework: 'React' })}
                                                                        </button>
                                                                    </>
                                                                ) : item.status === STATUS.GOING ? (
                                                                    <>
                                                                        <button className="btn btn-action disible">
                                                                        {t('ido.ido-51', { framework: 'React' })}
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {is_allow_joinpool ?
                                                                            <>
                                                                                {isLoadingPool ?
                                                                                    <>
                                                                                        <button className="btn btn-action disible">
                                                                                            <Spin /> {t('ido.ido-52', { framework: 'React' })}
                                                                                        </button>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <button className="btn btn-action" onClick={_handleJoinPool}>
                                                                                        {t('ido.ido-52', { framework: 'React' })}
                                                                                        </button>
                                                                                    </>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <button className="btn btn-action disible">
                                                                                    {t('ido.ido-53', { framework: 'React' })}
                                                                                </button>
                                                                            </>
                                                                        }
                                                                    </>
                                                                )}
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                            :
                            <>
                                <ConnectWallet />
                            </>
                        }
                    </div>
                </div>
            </div>
            <Modal title={t('ido.ido-74', { framework: 'React' })} className="modal-claim-progress" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="main-table">
                    <table className="table-ido">
                        <tr>
                            <th>
                                {t('ido.ido-54', { framework: 'React' })}
                            </th>
                            <th>
                                {t('ido.ido-55', { framework: 'React' })}
                            </th>
                            <th>
                                %
                            </th>
                        </tr>
                        {item?.allocation?.schedules !== null && item?.allocation?.schedules.map((items: any, index: any) => (
                            <>
                                <ItemModal items={items} />
                            </>
                        ))}
                    </table>
                </div>
            </Modal>
            <Modal title="Success" className="modal-warning" open={isModalOpenDis} onOk={handleOkDis} onCancel={handleCancelDis}>
                <div className="text-row">
                {t('ido.ido-56', { framework: 'React' })}
                </div>
                <button className="btn-goto-account" type="button" onClick={handleOkDis}>
                {t('ido.ido-57', { framework: 'React' })}
                </button>
            </Modal>
        </>
    )
}

const DetailsIDO = (props:any) => {
    const { t, i18n }: any = useTranslation('common');
    const { account, chainId } = useActiveWeb3React();
    let navigate = useNavigate()
    let location = useLocation();
    const [state, actions]: any = useHookProjects();

    const sendData = (value: any) => {
        props.parentCallback(value);
    };

    useEffect(() => {
        if (state.detailsIDO?.network) {
            sendData({ chain: state.detailsIDO?.network});  
        }
    }, [state.detailsIDO?.network]);

    let langApi:any = localStorage.getItem("lang")

    const handleOpenSearch = () => {
        navigate(`/full-search-project?key=${state.detailsIDO?.keywork}`)
    }

    const [isModalOpenWarning, setIsModalOpenWarning] = useState(false);

    const handleOk = () => {
        setIsModalOpenWarning(false);
    };

    const handleCancel = () => {
        setIsModalOpenWarning(false);
    };

    const [isModalOpenDis, setIsModalOpenDis] = useState(false);

    const showModalDis = () => {
        setIsModalOpenDis(true);
    };

    const handleOkDis = () => {
        setIsModalOpenDis(false);
    };

    const handleCancelDis = () => {
        setIsModalOpenDis(false);
    };

    useEffect(() => {
        if (account && state.detailsIDO?.claimTokenInfo?.network === "brc" && state.detailsIDO?.ownerAddress === null) {
            setIsModalOpenWarning(true);
        } else {
            setIsModalOpenWarning(false);
        }
    }, [state.detailsIDO?.claimTokenInfo?.network, state.detailsIDO?.ownerAddress, account]);

    const tabURL = location.search.split("=");
    const tabSymbol = tabURL[1]

    const params = {
        address: account || "",
        keywork: tabSymbol
    }

    useEffect(() => {
        actions.getDetailsIDO(params);
    }, [account]);

    const ether = (
        <>
            <img src="./images/ether.png" alt="" />
        </>
    );
    const poly = (
        <>
            <img src="./images/poly.png" alt="" />
        </>
    );
    const bsc = (
        <>
            <img src="./images/icon-bnb-ido.png" alt="" />
        </>
    );
    const brc = (
        <>
            <img src="./images/icon-bit.png" alt="" />
        </>
    );
    const blast = (
        <>
            <img src="./images/blast.svg" alt="" />
        </>
    );
    const arb = (
        <>
            <img src="./images/arb.png" alt="" />
        </>
    );
    const base = (
        <>
            <img src="./images/base.svg" alt="" />
        </>
    );
    const avax = (
        <>
            <img src="./images/avax.svg" alt="" />
        </>
    );

    const fillIconChain = (network: any) => {
        switch (network) {
            case NETWORK.BSC:
                return bsc;
            case NETWORK.Poly:
                return poly;
            case NETWORK.Ethereum:
                return ether;
            case NETWORK.BRC:
                return brc;
            case NETWORK.BLAST:
                return blast;
            case NETWORK.ARB:
                return arb;
            case NETWORK.BASE:
                return base;
            case NETWORK.Avax:
                return avax;
            default:
                return "---";
                break;
        }
    };

    const fillNameChain = (network: any) => {
        switch (network) {
            case NETWORK.BSC:
                return 'BSC';
            case NETWORK.Poly:
                return 'POLYGON';
            case NETWORK.Ethereum:
                return 'ETHEREUM';
            case NETWORK.BLAST:
                return 'BLAST';
            case NETWORK.ARB:
                return 'ARB';
            case NETWORK.BRC:
                return 'BRC';
            case NETWORK.BASE:
                return 'BASE';
            case NETWORK.Avax:
                return 'AVALANCHE';
            default:
                return "---";
            break;
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    // useEffect(() => {
    //     if (account !== undefined && state.detailsIDO?.network === "bep" || state.detailsIDO?.network === "blast")
    //         switchNetworkChain(
    //             MAPPING_CHAINID[state.detailsIDO?.network],
    //             state.detailsIDO?.network
    //         );
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [state.detailsIDO?.network, account, chainId]);

    return (
        <>
            <div className="group-details-box">
                <div className="container">
                    <div className="detail-box">
                        <div className="left">
                            <div className="guide-project not-hover">
                                <div className="time-ido">
                                    {state.detailsIDO?.isShielded === true ? t('ido.ido-58', { framework: 'React' }) : t('ido.ido-75', { framework: 'React' })}
                                </div>
                                <div className="bg-project">
                                    <img src="./images/bg-project.png" alt="" />
                                </div>
                                <div className="box-img">
                                    <img src={state.detailsIDO?.bannerUrl} alt="" />
                                </div>
                                <div className="row-bottom">
                                    <div className="icon">
                                        <img src={state.detailsIDO?.claimTokenInfo?.logoUrl} alt="" />
                                    </div>
                                    <div className="gr-social-ido">
                                        {state.detailsIDO?.socical?.website !== null ?
                                            <>
                                                <div className="item" onClick={() => (window.open(`${state.detailsIDO?.socical?.website}`))}>
                                                    <img src="./images/ido-web.png" alt="" />
                                                </div>
                                            </>
                                            :
                                            <>
                                                {""}
                                            </>
                                        }
                                        {state.detailsIDO?.socical?.twitter !== null ?
                                            <>
                                                <div className="item" onClick={() => (window.open(`${state.detailsIDO?.socical?.twitter}`))}>
                                                    <img src="./images/ido-x.png" alt="" />
                                                </div>
                                            </>
                                            :
                                            <>
                                                {""}
                                            </>
                                        }
                                        {state.detailsIDO?.socical?.telegram !== null ?
                                            <>
                                                <div className="item" onClick={() => (window.open(`${state.detailsIDO?.socical?.telegram}`))}>
                                                    <img src="./images/ido-tele.png" alt="" />
                                                </div>
                                            </>
                                            :
                                            <>
                                                {""}
                                            </>
                                        }
                                        {state.detailsIDO?.socical?.medium !== null ?
                                            <>
                                                <div className="item" onClick={() => (window.open(`${state.detailsIDO?.socical?.medium}`))}>
                                                    <img src="./images/ido-m.png" alt="" />
                                                </div>
                                            </>
                                            :
                                            <>
                                                {""}
                                            </>
                                        }
                                    </div>
                                    <div className="title-name-ido">
                                        {langApi === "cn" ? state.detailsIDO?.name_cn : langApi === "ru" ? state.detailsIDO?.name_ru : state.detailsIDO?.name}
                                    </div>
                                    <div className="desc">
                                        {langApi === "cn" ? state.detailsIDO?.description_cn : langApi === "ru" ? state.detailsIDO?.description_ru : state.detailsIDO?.description}
                                    </div>
                                    <div className="box-info-ido">
                                        <div className="item">
                                            <div className="l">
                                                {t('ido.ido-13-1', { framework: 'React' })}:
                                            </div>
                                            <div className="r">
                                                {fillIconChain(state.detailsIDO?.joinPoolInfo?.network)} {fillNameChain(state.detailsIDO?.joinPoolInfo?.network)}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="l">
                                                {t('ido.ido-13', { framework: 'React' })}:
                                            </div>
                                            <div className="r">
                                                {fillIconChain(state.detailsIDO?.claimTokenInfo?.network)} {fillNameChain(state.detailsIDO?.claimTokenInfo?.network)}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="l">
                                                {t('ido.ido-29', { framework: 'React' })}
                                            </div>
                                            <div className="r">
                                                {state.detailsIDO?.claimTokenInfo?.symbol}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="l">
                                                {t('ido.ido-19', { framework: 'React' })}:
                                            </div>
                                            <div className="r">
                                                {langApi === "cn" ? state.detailsIDO?.totalRaise_cn : langApi === "ru" ? state.detailsIDO?.totalRaise_ru : state.detailsIDO?.totalRaise} {state.detailsIDO?.claimTokenInfo?.symbol}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="l">
                                                {t('ido.ido-12', { framework: 'React' })}:
                                            </div>
                                            <div className="r">
                                                {state.detailsIDO?.totalSupply} {state.detailsIDO?.claimTokenInfo?.symbol}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="l">
                                                {t('ido.ido-14', { framework: 'React' })}
                                            </div>
                                            <div className="r">
                                                {state.detailsIDO?.claimTokenInfo?.tokenAddress?.slice(0, 8)}...{state.detailsIDO?.claimTokenInfo?.tokenAddress?.slice(-8)}
                                                <CopyToClipboard
                                                    text={`${state.detailsIDO?.claimTokenInfo?.tokenAddress}`}
                                                    onCopy={() => {
                                                        message.success({
                                                            type: "success",
                                                            content: `${t('ido.ido-61', { framework: 'React' })}`,
                                                            className: "custom-class",
                                                            duration: 2,
                                                        });
                                                    }}
                                                >
                                                    <img src="./images/copy.png" alt="" className="mar-l-5" />
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                    {state.detailsIDO?.isFullResearch ?
                                        <>
                                            <div className="gr-view">
                                                <button className="btn btn-view-pool" onClick={handleOpenSearch}>
                                                    {t('ido.ido-30', { framework: 'React' })}
                                                </button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {""}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            {state.detailsIDO && state.detailsIDO?.details?.map((item: any, index: any) => (
                                <>
                                    <Item item={item} />
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {!isModalOpenWarning ? (
                <>
                    {""}
                </>
            ) : (
                <>
                    <Modal title="Warning!" className="modal-warning" open={isModalOpenWarning} onOk={handleOk} onCancel={handleCancel}>
                        <img src="./images/warning.png" alt="" />
                        <div className="text">
                            {t('ido.ido-31', { framework: 'React' })}
                        </div>
                        <Link to="/profile">
                            <button className="btn-goto-account" type="button">
                                {t('ido.ido-32', { framework: 'React' })}
                            </button>
                        </Link>
                    </Modal>
                </>
            )}
        </>
    )
}
export default DetailsIDO