import React , { useEffect } from 'react';
import BoxApply from '../../../component/box-apply';
import { useNavigate , useLocation} from "react-router-dom";
import { useHookProjects } from "../store";
import { NETWORK } from "../index.d";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message } from 'antd';
import { useTranslation } from "react-i18next";

const FullSearchProject = () => {
    const { t, i18n }: any = useTranslation('common');
    const navigate = useNavigate()
    const location = useLocation();

    const [state, actions]: any = useHookProjects();

    const handleOpenDetails = () => {
        window.location.replace(`/ido-details?key=${state.dataFullSearch?.data?.keywork}`)
    }

    const tabURL = location.search.split("=");
    const tabSymbol = tabURL[1]

    const params = {
        keywork: tabSymbol,
    }

    useEffect(() => {
        actions.fullSearchIDO(params);
    }, []);

    const description = (item:any) => {
        return <div dangerouslySetInnerHTML={{ __html: item }} />;
    };

    const ether = (
        <>
            <img src="./images/ether.png" alt="" />
        </>
    );
    const poly = (
        <>
            <img src="./images/poly.png" alt="" />
        </>
    );
    const bsc = (
        <>
            <img src="./images/icon-bnb-ido.png" alt="" />
        </>
    );

    const blast = (
        <>
            <img src="./images/blast.svg" alt="" />
        </>
    );

    const brc = (
        <>
            <img src="./images/icon-bit.png" alt="" />
        </>
    );

    const fillIconChain = (network: any) => {
        switch (network) {
            case NETWORK.BSC:
                return bsc;
            case NETWORK.Poly:
                return poly;
            case NETWORK.Ethereum:
                return ether;
            case NETWORK.BRC:
                return brc;
            case NETWORK.BLAST:
                return blast;
            default:
                return "---";
                break;
        }
    };

    return (
        <>
            <div className="group-details-box">
                <div className="container">
                    <div className="detail-box">
                        <div className="left">
                            <div className="guide-project not-hover">
                                <div className="time-ido">
                                    {state.dataFullSearch?.data?.isShielded === true ? t('ido.ido-58', { framework: 'React' }) : t('ido.ido-75', { framework: 'React' })}
                                </div>
                                <div className="bg-project">
                                    <img src="./images/bg-project.png" alt="" />
                                </div>
                                <div className="box-img">
                                    <img src={state.dataFullSearch?.data?.bannerUrl} alt="" />
                                </div>
                                <div className="row-bottom">
                                    <div className="icon">
                                        <img src={state.dataFullSearch?.data?.claimTokenInfo?.logoUrl} alt="" />
                                    </div>
                                    <div className="gr-social-ido">
                                        {state.dataFullSearch?.data?.socical?.website !== null ?
                                            <>
                                                <div className="item" onClick={() => (window.open(`${state.dataFullSearch?.data?.socical?.website}`))}>
                                                    <img src="./images/ido-web.png" alt="" />
                                                </div>
                                            </>
                                            :
                                            <>
                                                {""}
                                            </>
                                        }
                                        {state.dataFullSearch?.data?.socical?.twitter !== null ?
                                            <>
                                                <div className="item" onClick={() => (window.open(`${state.dataFullSearch?.data?.socical?.twitter}`))}>
                                                    <img src="./images/ido-x.png" alt="" />
                                                </div>
                                            </>
                                            :
                                            <>
                                                {""}
                                            </>
                                        }
                                        {state.dataFullSearch?.data?.socical?.telegram !== null ?
                                            <>
                                                <div className="item" onClick={() => (window.open(`${state.dataFullSearch?.data?.socical?.telegram}`))}>
                                                    <img src="./images/ido-tele.png" alt="" />
                                                </div>
                                            </>
                                            :
                                            <>
                                                {""}
                                            </>
                                        }
                                        {state.dataFullSearch?.data?.socical?.medium !== null ?
                                            <>
                                                <div className="item" onClick={() => (window.open(`${state.dataFullSearch?.data?.socical?.medium}`))}>
                                                    <img src="./images/ido-m.png" alt="" />
                                                </div>
                                            </>
                                            :
                                            <>
                                                {""}
                                            </>
                                        }
                                    </div>
                                    <div className="title-name-ido">
                                        {state.dataFullSearch?.data?.name}
                                    </div>
                                    <div className="desc">
                                        {state.dataFullSearch?.data?.description}
                                    </div>
                                    <div className="box-info-ido">
                                        <div className="item">
                                            <div className="l">
                                                {t('ido.ido-13', { framework: 'React' })}
                                            </div>
                                            <div className="r">
                                                {fillIconChain(state.dataFullSearch?.data?.joinPoolInfo?.network)} {state.dataFullSearch?.data?.joinPoolInfo?.network.toUpperCase()}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="l">
                                                {t('ido.ido-29', { framework: 'React' })}
                                            </div>
                                            <div className="r">
                                                {state.dataFullSearch?.data?.claimTokenInfo?.symbol}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="l">
                                                {t('ido.ido-11', { framework: 'React' })}
                                            </div>
                                            <div className="r">
                                                {state.dataFullSearch?.data?.totalRaise} {state.dataFullSearch?.data?.claimTokenInfo?.symbol}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="l">
                                                {t('ido.ido-12', { framework: 'React' })}
                                            </div>
                                            <div className="r">
                                                {state.dataFullSearch?.data?.totalSupply} {state.dataFullSearch?.data?.claimTokenInfo?.symbol}
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="l">
                                            {t('ido.ido-14', { framework: 'React' })}
                                            </div>
                                            <div className="r">
                                                {state.dataFullSearch?.data?.claimTokenInfo?.tokenAddress?.slice(0, 8)}...{state.dataFullSearch?.data?.claimTokenInfo?.tokenAddress?.slice(-8)}
                                                <CopyToClipboard
                                                    text={`${state.dataFullSearch?.data?.claimTokenInfo?.tokenAddress}`}
                                                    onCopy={() => {
                                                        message.success({
                                                            type: "success",
                                                            content: `${t('ido.ido-61', { framework: 'React' })}`,
                                                            className: "custom-class",
                                                            duration: 2,
                                                        });
                                                    }}
                                                >
                                                    <img src="./images/copy.png" alt="" className="mar-l-5" />
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gr-view">
                                        <button className="btn btn-view-pool" onClick={handleOpenDetails}>
                                            {t('ido.ido-15', { framework: 'React' })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="row-search-project">
                                <div className="content-intro">
                                    <div className="txt">
                                        {state.dataFullSearch.message !== null ?
                                            <>
                                                {description(
                                                    state.dataFullSearch.message &&
                                                    state.dataFullSearch.message
                                                )}
                                            </>
                                            :
                                            <>
                                                <div className="empty-data">
                                                    <img src="./images/empty-data-1.png" className="img-data" alt="" />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BoxApply />
                </div>
            </div>
        </>
    )
}
export default FullSearchProject