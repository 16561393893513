import React, { useMemo, useState, useCallback, useEffect } from "react";

import { getFullDisplayBalance } from "../../utilsST/formatBalance";
import Input from "../../componentsST/InputV2/index";
// import Modal from "../../componentsST/Modal/index";
import Button from "../../componentsST/ButtonV2/index";
import useSousStake from "../../hooksST/useSousStake";
import { Modal, message, Spin } from "antd";
import { sousStake } from "../../utilsST/callHelpers";
import "../../componentsST/StakeInPoolModal/index.less";
import { useContract } from "../../hooks/useContract";
import sousChefAbi from "../../config/abi/sousChef.json";
import poolsConfig from '../../constantsST/pools'
import { BaseApi } from "../../config/api/BaseApi";
import { useWeb3React } from "@web3-react/core";
import { useTranslation } from "react-i18next";

const DepositModal = (props) => {
  const { t, i18n } = useTranslation('common');
  const { account } = useWeb3React();
  const [val, setVal] = useState("");
  const [pendingTx, setPendingTx] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const {
    sousId,
    pool,
    decimals,
    onConfirm,
    onDismiss,
    max,
    stakingToken,
    addressToken,
    isPool = false,
    // isModalOpen,
  } = props;
  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max);
  }, [max]);

  const handleChange = useCallback(
    (e) => {
      setVal(Number(e.currentTarget.value));
    },
    [setVal]
  );

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance);
  }, [fullBalance, setVal]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = async () => {
    setIsModalOpen(false);
  };

  // const pool = poolsConfig.find((pool) => pool.id === sousId);
  const contract = useContract(pool.contractAddress, sousChefAbi);
  const handleStake = async () => {
    
      setPendingTx(true);
      await sousStake(contract, val, decimals).then(async (res) => {
        
        res.wait()
        .then(async (res2) => {
          if (res2?.status === 1) {
            setPendingTx(false);
            onDismiss();

            // call api update
            // console.log('res?.hash', res?.hash);
            let payload = {
              "ownerAddress": account.toLowerCase(),
              "poolContract": pool.contractAddress,
              "txHash": res?.hash
            }

            await BaseApi.poolSet(payload).then((res)=>{
              message.success({
                type: "success",
                content: t('staking.staking_success', { framework: 'React' }),
                className: "custom-class",
                duration: 3,
                
              });
              setPendingTx(true);
              setTimeout(() => {
                window.location.reload();
              }, 1400);
            }).catch((error)=>{
              message.success({
                type: "error",
                content: t('staking.staking_failed', { framework: 'React' }),
                className: "custom-class",
                duration: 3,
                
              });
              setPendingTx(true);
            });

            
          }
          
        }).catch((error) => {
        setPendingTx(false);
        
        
        if (error.code == 4001 && error.message) {
            
          message.error({
            type: "error",
            content: error.message,
            className: "custom-class",
            duration: 3,
          });
        } else if (error.reason) {
            message.error({
              type: "error",
              content: error.reason,
              className: "custom-class",
              duration: 3,
            });
        } else {
            if (error.data && error.data.message) {
              message.error({
                type: "error",
                content: error.data.message,
                className: "custom-class",
                duration: 3,
              });
            }
        }
        
      });;
        
      })
      .catch((error) => {
        setPendingTx(false);
        
        
        if (error.code == 4001 && error.message) {
            
          message.error({
            type: "error",
            content: error.message,
            className: "custom-class",
            duration: 3,
          });
        } else if (error.reason) {
            message.error({
              type: "error",
              content: error.reason,
              className: "custom-class",
              duration: 3,
            });
        } else {
            if (error.data && error.data.message) {
                
                message.error({
                  type: "error",
                  content: error.data.message,
                  className: "custom-class",
                  duration: 3,
                });
            }
        }
        
      });
    
  };
  return (
    <Modal
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className="modal-cus-pool"
    >
      <div className="bsc-stake_in_pool-modal">
        <div className="bsc-stake_in_pool-modal-header">
          <span>{t('staking.stake_in_pool', { framework: 'React' })}</span>
          <span>{t('staking.stake', { framework: 'React' })} {`${stakingToken.symbol}`}</span>
        </div>
        <div className="bsc-stake_in_pool-modal-content">
          <div className="bsc-stake_in_pool-modal-content-top">
            <span>{new Intl.NumberFormat("ja-JP").format(Number(getFullDisplayBalance(max, stakingToken.decimals)).toFixed(2))}</span>
            <span>{`${stakingToken.symbol}`} {t('staking.available', { framework: 'React' })}</span>
          </div>
          <div className="bsc-stake_in_pool-modal-content-mid">
            <Input
              change={handleChange}
              type="number"
              value={val}
              right={
                <>
                  <span>{stakingToken.symbol}</span>
                  <button onClick={handleSelectMax} className="btn-max-deposit">{t('staking.max', { framework: 'React' })}</button>
                </>
              }
            />
          </div>
          <div className="bsc-stake_in_pool-modal-content-bottom mb-4">
            <button onClick={onDismiss} className="btn-color-default mr-5">{t('staking.cancel', { framework: 'React' })}</button>
            <button
              className="btn-color"
              disabled={pendingTx || val <= 0}
              onClick={handleStake}
            >{t('staking.confirm', { framework: 'React' })} {pendingTx && (<Spin />)}</button>
          </div>
          <div className="bsc-stake_in_pool-modal-content-bottom mb-4 text-getmore-stake">
            <a
              href={`https://pancakeswap.finance/swap?outputCurrency=${addressToken.toLowerCase()}`}
              target="_blank"
              rel="noreferrer"
            >
              {t('staking.get_more', { framework: 'React' })} {`${stakingToken.symbol} >>`}
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DepositModal;
