
import React, { useEffect, useCallback, useRef, useState } from "react";
import { Tabs, Tooltip } from "antd";
import { QuestionCircleOutlined, CheckOutlined } from "@ant-design/icons";
import IDOList from "./IDOList";
import { useDispatch, useSelector } from "react-redux";
import { useFetchPublicPoolsData, usePollBlockNumber } from "../../store/hooks";
import { usePoolsIDO } from "../../store/hooks";

import "./style.css";
// import { useHookPrice } from "../../components/common/Store";
import { useWeb3React } from "@web3-react/core";
import { BaseApi } from "../../config/api/BaseApi";
import { setPoolsList } from "../../store/pools";
import switchNetworkChain from "../../utils/walletChain";
import { useActiveWeb3React } from "../../hooks";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

const IdoPools = () => {
  // const [state1, actions1] = useHookPrice();
  const { t, i18n } = useTranslation('common');
  const dispatch = useDispatch();
  const [poolData, setPoolData] = useState([]);
  const { account, chainId } = useActiveWeb3React();

  useEffect(() => {
      if (account && chainId && chainId !== 56)
          switchNetworkChain(56,"bep");
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId]);
  
  const getPoolData = async ()=>{
    let poolList = [];
    let poolListApi = await BaseApi.getPoolList();

    if(poolListApi.status == 200){
      poolList = poolListApi?.data?.data;
      localStorage.setItem('poolList', JSON.stringify(poolList));
      setPoolData(poolList);
      dispatch(setPoolsList(poolList));
    }
  }
  useEffect(() => {
    getPoolData();
  }, []);



  usePollBlockNumber();
  useFetchPublicPoolsData();
  
  

  const { pools, userDataLoaded } = usePoolsIDO(account);

  const handleTabChange = (key) => {
    
  };

  const textTooltip = (
    <div>
      <div className="text-tooltip">
        <span className="icon">
          <CheckOutlined />
        </span>
        <span className="text">{t('staking.tooltip_title', { framework: 'React' })}</span>
      </div>
      <div className="text-tooltip">
        <span className="icon">
          <CheckOutlined />
        </span>
        <span className="text">
          {t('staking.tooltip_des', { framework: 'React' })}
        </span>
      </div>
    </div>
  );
  const contentTop = (
    <div className="main-round-pool">
      <div className="round-slide IDO">
        <div className="item">
          <span className="name">{t('staking.project_token', { framework: 'React' })}</span>
        </div>
        <div className="item">
          <span className="name d-flex">
            {t('staking.apr', { framework: 'React' })}
            <Tooltip overlayClassName="tooltip-cus" title={textTooltip}>
              <span className="text-apr icon pd-left-3">
                <QuestionCircleOutlined />
              </span>
            </Tooltip>
          </span>
        </div>
        <div className="item">
          <span className="name">{t('staking.lock_up', { framework: 'React' })}</span>
        </div>
        <div className="item">
          <span className="name">{t('staking.total_amount_staked', { framework: 'React' })}</span>
        </div>
        <div className="item">
          <span className="name">{t('staking.earned', { framework: 'React' })}</span>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="startpool-banner">
        <div className="pool-title">{t('staking.staking_title', { framework: 'React' })}</div>
        <div className="pool-des">
          <p className="mb-5">{t('staking.staking_des_1', { framework: 'React' })}</p>
          <p className="mb-5">{t('staking.staking_des_2', { framework: 'React' })}</p>
        </div>
      </div>
      <div className="container">
        <div className="main-startpool">
          <div className="content-startpool">
            <div className="bottom-startpool">
              <div className="tabs-startpool">
                <Tabs className="tabs-round" defaultActiveKey='1' onChange={handleTabChange} >
                  <TabPane tab={t('staking.live', { framework: 'React' })} key="1">
                    {contentTop}
                    
                    <>
                      {pools
                        .filter((pool) => pool.isFinished === false)
                        .map((pool, index) => (
                          <IDOList
                            key={index}
                            pool={pool}
                            userDataLoaded={userDataLoaded}
                          />
                        )
                      )}
                    </>
                  </TabPane>
                  <TabPane tab={t('staking.finished', { framework: 'React' })} key="2">
                    <>
                    {contentTop}
                      
                
                    <>
                      {pools
                        .filter((pool) => pool.isFinished === true)
                        .map((pool, index) => (
                          <IDOList
                            key={index}
                            pool={pool}
                            userDataLoaded={userDataLoaded}
                          />
                      ))}
                    </>
                        
                    </>
                  </TabPane>
                  
                </Tabs>
              </div>
            </div>
          </div>
          </div>
        
      </div>
    </>
  );
};
export default IdoPools;
