import React, { useEffect } from 'react';
import { Tooltip } from 'antd';
import { Link } from "react-router-dom";
import { useHookProjects } from "./store";
import { useActiveWeb3React } from "../../hooks";
import { TIER } from "./index.d";
import { useTranslation } from "react-i18next";


const TopFunction = () => {
    const { t, i18n }: any = useTranslation('common');
    const [state, actions]: any = useHookProjects();
    const { account } = useActiveWeb3React();

    const params = {
        ownerAddress: account
    }

    useEffect(() => {
        account && actions.getOwnerAddress(params);
    }, [account]);

    const TierText:any = (() => {
        switch (state.dataOwner.currentTier) {
            case 1:
                return "IRON";
            case 2:
                return "BRONZE";
            case 3:
                return "SILVER";
            case 4:
                return "GOLD";
            case 5:
                return "PLATINUM";
            case 6:
                return "DIAMOND";
            default:
            return "N/A";
        }
    });

    const ImgTier:any = (() => {
        switch (state.dataOwner.currentTier) {
            case 1:
                return <img src="./images/r-1.png" alt="" />;
            case 2:
                return <img src="./images/r-2.png" alt="" />;
            case 3:
                return <img src="./images/r-3.png" alt="" />;
            case 4:
                return <img src="./images/r-4.png" alt="" />;
            case 5:
                return <img src="./images/r-5.png" alt="" />;
            case 6:
                return <img src="./images/res-6.png" alt="" />;
            default:
            return <img src="./images/r-de.png" alt="" />;
        }
    });


    const TierLv1 = (
        <>
            <div className="box-tooltip">
                <div className="title">
                    Current Tier
                </div>
                <div className="level">
                    IRON
                </div>
                <div className="number-stake">
                    {state?.dataOwner?.balance}
                </div>
            </div>
        </>
    )

    const TierLv2 = (
        <>
            <div className="box-tooltip">
                <div className="title">
                    Current Tier
                </div>
                <div className="level">
                    BRONZE
                </div>
                <div className="number-stake">
                    {state?.dataOwner?.balance}
                </div>
            </div>
        </>
    )

    const TierLv3 = (
        <>
            <div className="box-tooltip">
                <div className="title">
                    Current Tier
                </div>
                <div className="level">
                    SILVER
                </div>
                <div className="number-stake">
                    {state?.dataOwner?.balance}
                </div>
            </div>
        </>
    )

    const TierLv4 = (
        <>
            <div className="box-tooltip">
                <div className="title">
                    Current Tier
                </div>
                <div className="level">
                    GOLD
                </div>
                <div className="number-stake">
                    {state?.dataOwner?.balance}
                </div>
            </div>
        </>
    )

    const TierLv5 = (
        <>
            <div className="box-tooltip">
                <div className="title">
                    Current Tier
                </div>
                <div className="level">
                    PLATINUM
                </div>
                <div className="number-stake">
                    {state?.dataOwner?.balance}
                </div>
            </div>
        </>
    )

    const TierLv6 = (
        <>
            <div className="box-tooltip">
                <div className="title">
                    Current Tier
                </div>
                <div className="level">
                    DIAMOND
                </div>
                <div className="number-stake">
                    {state?.dataOwner?.balance}
                </div>
            </div>
        </>
    )
    

    return (
        <>
            <div className="container">
                <div className="group-top-banner">
                    <div className="left-guide">
                        <div className="title">
                            {t('ido.ido-1', { framework: 'React' })}
                        </div>
                        <div className="desc">
                            {t('ido.ido-2', { framework: 'React' })}
                        </div>
                        <div className="gr-button">
                            <Link to="/ido-pools">
                                <button className="btn btn-main mar-0-5">
                                    {t('ido.ido-3', { framework: 'React' })}
                                </button>
                            </Link>
                        </div>
                        <div className="info-fvlaunch">
                            <div className="item">
                                <div className="txt-top">
                                    {state.dataOwner?.currentTier ? TierText(state.dataOwner?.currentTier) : "TBA"}
                                </div>
                                <div className="txt-bot">
                                    {t('ido.ido-4', { framework: 'React' })}
                                </div>
                            </div>
                            <div className="item">
                                <div className="txt-top">
                                    {account ? state.dataOwner?.staking : "TBA"}
                                </div>
                                <div className="txt-bot">
                                    {t('ido.ido-5', { framework: 'React' })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-guide">
                        <div className="group-tier">
                            <div className="box-img">
                                <img src="./images/tier-bg.png" alt="" />
                                <div className="img-tier">
                                    {ImgTier(state.dataOwner?.currentTier)}
                                </div>
                                <div className={state.dataOwner?.currentTier === 0 ? "list-tier tier-0" : state.dataOwner?.currentTier === 1 ? "list-tier tier-1" : state.dataOwner?.currentTier === 2 ? "list-tier tier-2" : state.dataOwner?.currentTier === 3 ? "list-tier tier-3" : state.dataOwner?.currentTier === 4 ? "list-tier tier-4" : state.dataOwner?.currentTier === 5 ? "list-tier tier-5" : "list-tier tier-6"}>
                                    {state.dataOwner?.currentTier === 0 ?
                                        <>
                                            <div className="item">
                                                <img src="./images/tier-1.png" alt="" />
                                            </div>
                                            <div className="item">
                                                <img src="./images/tier-2.png" alt="" />
                                            </div>
                                            <div className="item">
                                                <img src="./images/tier-3.png" alt="" />
                                            </div>
                                            <div className="item">
                                                <img src="./images/tier-4.png" alt="" />
                                            </div>
                                            <div className="item">
                                                <img src="./images/tier-5.png" alt="" />
                                            </div>
                                            <div className="item">
                                                <img src="./images/res-6.png" alt="" />
                                            </div>
                                        </>
                                        :
                                        <>
                                            {state.dataOwner?.currentTier === 1 ? 
                                                <>
                                                    <Tooltip title={TierLv1}>
                                                        <div className="item">
                                                            <img src="./images/tier-1.png" alt="" />
                                                        </div>
                                                    </Tooltip>
                                                    <div className="item">
                                                        <img src="./images/tier-2.png" alt="" />
                                                    </div>
                                                    <div className="item">
                                                        <img src="./images/tier-3.png" alt="" />
                                                    </div>
                                                    <div className="item">
                                                        <img src="./images/tier-4.png" alt="" />
                                                    </div>
                                                    <div className="item">
                                                        <img src="./images/tier-5.png" alt="" />
                                                    </div>
                                                    <div className="item">
                                                        <img src="./images/res-6.png" alt="" />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {state.dataOwner?.currentTier === 2 ? 
                                                        <>
                                                            <div className="item">
                                                                <img src="./images/tier-1.png" alt="" />
                                                            </div>
                                                            <Tooltip title={TierLv2}>
                                                                <div className="item">
                                                                    <img src="./images/tier-2.png" alt="" />
                                                                </div>
                                                            </Tooltip>
                                                            <div className="item">
                                                                <img src="./images/tier-3.png" alt="" />
                                                            </div>
                                                            <div className="item">
                                                                <img src="./images/tier-4.png" alt="" />
                                                            </div>
                                                            <div className="item">
                                                                <img src="./images/tier-5.png" alt="" />
                                                            </div>
                                                            <div className="item">
                                                                <img src="./images/res-6.png" alt="" />
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            {state.dataOwner?.currentTier === 3 ? 
                                                                <>
                                                                    <div className="item">
                                                                        <img src="./images/tier-1.png" alt="" />
                                                                    </div>
                                                                    <div className="item">
                                                                        <img src="./images/tier-2.png" alt="" />
                                                                    </div>
                                                                    <Tooltip title={TierLv3}>
                                                                        <div className="item">
                                                                            <img src="./images/tier-3.png" alt="" />
                                                                        </div>
                                                                    </Tooltip>
                                                                    <div className="item">
                                                                        <img src="./images/tier-4.png" alt="" />
                                                                    </div>
                                                                    <div className="item">
                                                                        <img src="./images/tier-5.png" alt="" />
                                                                    </div>
                                                                    <div className="item">
                                                                        <img src="./images/res-6.png" alt="" />
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    {state.dataOwner?.currentTier === 4 ? 
                                                                        <>
                                                                            <div className="item">
                                                                                <img src="./images/tier-1.png" alt="" />
                                                                            </div>
                                                                            <div className="item">
                                                                                <img src="./images/tier-2.png" alt="" />
                                                                            </div>
                                                                            <div className="item">
                                                                                <img src="./images/tier-3.png" alt="" />
                                                                            </div>
                                                                            <Tooltip title={TierLv4}>
                                                                                <div className="item">
                                                                                    <img src="./images/tier-4.png" alt="" />
                                                                                </div>
                                                                            </Tooltip>
                                                                            <div className="item">
                                                                                <img src="./images/tier-5.png" alt="" />
                                                                            </div>
                                                                            <div className="item">
                                                                                <img src="./images/res-6.png" alt="" />
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {state.dataOwner?.currentTier === 5 ? 
                                                                                <>
                                                                                    <div className="item">
                                                                                        <img src="./images/tier-1.png" alt="" />
                                                                                    </div>
                                                                                    <div className="item">
                                                                                        <img src="./images/tier-2.png" alt="" />
                                                                                    </div>
                                                                                    <div className="item">
                                                                                        <img src="./images/tier-3.png" alt="" />
                                                                                    </div>
                                                                                    <div className="item">
                                                                                        <img src="./images/tier-4.png" alt="" />
                                                                                    </div>
                                                                                    <Tooltip title={TierLv5}>
                                                                                        <div className="item">
                                                                                            <img src="./images/tier-5.png" alt="" />
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                    <div className="item">
                                                                                        <img src="./images/res-6.png" alt="" />
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <div className="item">
                                                                                        <img src="./images/tier-1.png" alt="" />
                                                                                    </div>
                                                                                    <div className="item">
                                                                                        <img src="./images/tier-2.png" alt="" />
                                                                                    </div>
                                                                                    <div className="item">
                                                                                        <img src="./images/tier-3.png" alt="" />
                                                                                    </div>
                                                                                    <div className="item">
                                                                                        <img src="./images/tier-4.png" alt="" />
                                                                                    </div>
                                                                                    <div className="item">
                                                                                        <img src="./images/tier-5.png" alt="" />
                                                                                    </div>
                                                                                    <Tooltip title={TierLv6}>
                                                                                        <div className="item">
                                                                                            <img src="./images/res-6.png" alt="" />
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TopFunction