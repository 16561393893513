import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { HelpCircle } from "react-feather";
import { BSC_BLOCK_TIME } from "../../config/index";
import { useCurrentBlock } from "../../store/hooks";
import { useWeb3React } from "@web3-react/core";
import Countdown, { zeroPad } from "react-countdown";
import { useTranslation } from "react-i18next";

const WithdrawalFeeTimer = ({ lastStakingBlock, blockPeriod }) => {
  const currentBlock = useCurrentBlock();

  const timer =
    (lastStakingBlock + blockPeriod - currentBlock) * BSC_BLOCK_TIME * 1000 +
    Date.now();

  const renderCountdown = ({ days, hours, minutes, completed }) => {
    if (completed) return <p className="mb-0">0d-0h-0m</p>;

    return (
      <p className="mb-0">
        {zeroPad(days)}d-{zeroPad(hours)}h-{zeroPad(minutes)}m
      </p>
    );
  };
  return <Countdown zeroPadTime={2} date={timer} renderer={renderCountdown} />;
};

const UnstakingFeeCountdownRow = ({ fees, lastStakingBlock, blockPeriod }) => {

  const { t, i18n } = useTranslation('common');

  const currentBlock = useCurrentBlock();
  const { account } = useWeb3React();

  const hasUnstakingFee = lastStakingBlock + blockPeriod > currentBlock;

  // The user has made a deposit, but has no fee
  const noFeeToPay = lastStakingBlock + blockPeriod < currentBlock;

  // Show the timer if a user is connected, has deposited, and has an unstaking fee
  const shouldShowTimer = account && lastStakingBlock && hasUnstakingFee;
  const getRowText = () => {
    if (noFeeToPay) {
      return t('staking.unstaking_fee', { framework: 'React' });
    }
    if (shouldShowTimer) {
      return t('staking.unstaking_fee_until', { framework: 'React' });
    }
    return "Unstaking fee if withdrawn within 72h";
  };

  return (
    <div className="d-flex text-white mt-15">
      <div className="text details mr-5 mt-2">{getRowText()} </div>
      <OverlayTrigger
        placement="bottom"
        overlay={
          <div
            className="bg-white p-2"
            style={{
              borderRadius: 15,
              maxWidth: 350,
            }}
          >
            <p>Unstaking fee: {fees}%</p>
            <p>
              Only applies within 3 days of staking. Unstaking after 3 days will
              not include a fee. Timer resets every time you stake in the pool.
            </p>
          </div>
        }
      >
        <span>
          <img src="/images/pool/p-help.svg" alt="" />
        </span>
      </OverlayTrigger>

      {fees === 0 ? (
        "0"
      ) : (
        <>
          {lastStakingBlock === 0 ? (
            ""
          ) : (
            <>
              {" "}
              {shouldShowTimer && (
                <WithdrawalFeeTimer
                  lastStakingBlock={lastStakingBlock}
                  blockPeriod={blockPeriod}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UnstakingFeeCountdownRow;
