import React , {useState , useEffect } from 'react';
import BoxApply from '../../component/box-apply';
import { useNavigate } from "react-router-dom";
import { useHookProjects } from "./store";
import { Empty , Input , Spin} from 'antd';
import { NETWORK } from "./index.d";
import { useTranslation } from "react-i18next";

const TabsEnded = () => {
    const { t, i18n }: any = useTranslation('common');
    let langApi:any = localStorage.getItem("lang")
    const { Search } = Input;
    const navigate = useNavigate()
    const [state, actions]: any = useHookProjects();
    const [enableLoadMore, setEnableLoadMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const params = {
        symbol: "",
        lastTime: 0
    }

    const paramsMore = {
        symbol: "",
        lastTime: state.endedIDO.length > 0 ? state.endedIDO[state.endedIDO.length - 1].idoCloseTime : null,
    }

    useEffect(() => {
        actions.getEndedIDO(params);
    }, []);

    const handleMore = () => {
        setLoadingMore(true);
        if (state.listEndedMore.length > 0) {
            paramsMore.lastTime =
                state.listEndedMore[state.listEndedMore.length - 1].idoCloseTime;
        }
        actions.getListEndedMore(paramsMore).then((data: any) => {
            setLoadingMore(false);
            if (data.length === 0) setEnableLoadMore(false);
        });
    };

    const onSearch = (value: string) => {
        actions.getEndedIDO({
            symbol: value,
            lastTime: 0
        });
    };

    const ether = (
        <>
            <img src="./images/ether.png" alt="" />
        </>
    );
    const poly = (
        <>
            <img src="./images/poly.png" alt="" />
        </>
    );
    const bsc = (
        <>
            <img src="./images/icon-bnb-ido.png" alt="" />
        </>
    );
    const brc = (
        <>
            <img src="./images/icon-bit.png" alt="" />
        </>
    );

    const blast = (
        <>
            <img src="./images/blast.svg" alt="" />
        </>
    );

    const arb = (
        <>
            <img src="./images/arb.png" alt="" />
        </>
    );
    const avax = (
        <>
            <img src="./images/avax.svg" alt="" />
        </>
    );

    const fillIconChain = (network: any) => {
        switch (network) {
            case NETWORK.BSC:
                return bsc;
                break;
            case NETWORK.Poly:
                return poly;
                break;
            case NETWORK.Ethereum:
                return ether;
            case NETWORK.BRC:
                return brc;
            case NETWORK.ARB:
                return arb;
            case NETWORK.BLAST:
                return blast;
            case NETWORK.Avax:
                return avax;
            default:
                return "---";
            break;
        }
    };

    const Item = ({item}:any) => {
        const handleOpenDetails = () => {
            window.location.replace(`/ido-details?key=${item?.keywork}`)
        }
        return (
            <>
                <tr className="tr-bottom">
                    <td>
                        <div className="box-name-pj" onClick={handleOpenDetails}>
                            <img src={item?.claimTokenInfo?.logoUrl} alt="" /> {langApi === "cn" ? item?.name_cn : langApi === "ru" ? item?.name_ru : item?.name} <span>{item?.claimTokenInfo?.symbol}</span>
                        </div> 
                    </td>
                    <td>
                        <div className="row-chain">
                            {fillIconChain(item?.claimTokenInfo?.network)} {item?.claimTokenInfo?.network.toUpperCase()}
                        </div>
                    </td>
                    <td>
                        {langApi === "cn" ? item?.totalRaise_cn : langApi === "ru" ? item?.totalRaise_ru : item?.totalRaise} {item?.claimTokenInfo?.symbol}
                    </td>
                    <td>
                        {item?.swapAmount}
                    </td>
                    <td>
                        <span className="main-color">
                            {item?.currentPrice}
                        </span>
                    </td>
                    <td>
                        <div className="row-roi-up">
                            {item?.athRoi}%
                        </div>
                    </td>
                </tr>
            </>
        )
    }

    return (
        <>
            <div className="group-upcoming res">
                <div className="row-content">
                    <div className="search-project-row">
                        <Search type="search" onSearch={onSearch} className="input-search" placeholder={`${t('ido.ido-16', { framework: 'React' })}`} />
                    </div>
                    <div className="main-table">
                        <table className="table-ido">
                            <tr>
                                <th>
                                    {t('ido.ido-17', { framework: 'React' })}
                                </th>
                                <th>
                                    {t('ido.ido-18', { framework: 'React' })}
                                </th>
                                <th>
                                    {t('ido.ido-19', { framework: 'React' })}
                                </th>
                                <th>
                                    {t('ido.ido-20', { framework: 'React' })}
                                </th>
                                <th>
                                    {t('ido.ido-21', { framework: 'React' })}
                                </th>
                                <th>
                                    {t('ido.ido-22', { framework: 'React' })}
                                </th>
                            </tr>   
                            {state.endedIDO.length === 0 ?
                                <>
                                    <tr>
                                        <td colSpan={6}>
                                            <div className="empty-data">
                                                <img src="./images/empty-data-1.png" alt="" />
                                            </div>
                                        </td>
                                    </tr>
                                </>
                                :
                                <>
                                    {state.endedIDO.map((item: any, index: any) => (
                                        <>
                                            <Item item={item} />
                                        </>
                                    ))}
                                    {state.listEndedMore.map((item: any, index: any) => (
                                        <>
                                            <Item item={item} />
                                        </>
                                    ))}
                                </>
                            }
                        </table>
                        {enableLoadMore && state?.endedIDO.length !== 0 ?
                            <>
                                <div className="btn-more-row">
                                    {loadingMore ?
                                        <>
                                            <button className="bnt-more">
                                                <Spin />   {t('ido.ido-23', { framework: 'React' })}
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button className="bnt-more" onClick={handleMore}>
                                                {t('ido.ido-23', { framework: 'React' })}
                                            </button>
                                        </>
                                    }
                                </div>
                            </>
                            :
                            <>
                                {""}
                            </>
                        }
                    </div>
                </div>
                <div className="box-apply-row">
                    <BoxApply />
                </div>
            </div>
        </>
    )
}
export default TabsEnded