import React, { useEffect, useState } from 'react';
import { useActiveWeb3React } from "../../../hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Table } from 'antd';
import { useTranslation } from "react-i18next";
import { useHookAccount } from '../store';

const RefIdo = () => {
    const { t, i18n }: any = useTranslation('common');
    const { account, chainId }: any = useActiveWeb3React();
    const [state , actions]: any = useHookAccount();

    const dataSource = state.dataMyIdo?.map((item: any, index: any) => ({
        idoName : item.idoName,
        point : item.point,
        idoDate : item.idoDate,
    }));

    const columns = [
        {
            title: 'IDO',
            dataIndex: 'idoName',
            key: 'name',
        },
        {
            title: t('ref.ref-31', { framework: 'React' }),
            dataIndex: 'point',
            key: 'point',
        },
        {
            title: t('ref.ref-34', { framework: 'React' }),
            dataIndex: 'idoDate',
            key: 'date',
        },
    ];

    let params = {
        ownerAddress: account,
        pageIndex: 0,
        pageSize: 0
    }

    useEffect(() => {
        if (account) {
            actions.getMyIdo(params);
        }
    }, [account]);
    
    return (
        <>
            <div className='ref-block main-table'>
                <div className='ref-ido-title'>{t('ref.ref-32', { framework: 'React' })}</div>
                <div className='ref-ido-desc'>{t('ref.ref-33', { framework: 'React' })}</div>
                <Table className='table-ido' dataSource={dataSource} columns={columns} />;
            </div>
        </>
    )
}
export default RefIdo