import React, { useEffect, useState } from 'react';
import BoxApply from '../../component/box-apply';
import { useNavigate } from "react-router-dom";
import { useHookProjects } from "./store";
import { useActiveWeb3React } from "../../hooks";
import { Input , Spin} from 'antd';
import { useTranslation } from "react-i18next";


const TabsJoined = () => {
    const { t, i18n }: any = useTranslation('common');
    let langApi:any = localStorage.getItem("lang")
    const { Search } = Input;
    const navigate = useNavigate()
    const { account } = useActiveWeb3React();
    const [state, actions]: any = useHookProjects();
    const [enableLoadMore, setEnableLoadMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const paramsMore = {
        symbol: "",
        address: account,
        lastTime: state.joinedIDO.length > 0 ? state.joinedIDO[state.joinedIDO.length - 1].created : null,
    }

    const params = {
        symbol: "",
        address: account,
        lastTime: 0
    }

    useEffect(() => {
        if (account) {
            actions.getJoinedIDO(params);
        }
    }, [account]);

    const onSearch = (value: string) => {
        actions.getJoinedIDO({
            symbol: value,
            address: account,
            lastTime: 0
        });
    };

    const handleMore = () => {
        setLoadingMore(true);
        if (state.listJoinedMore.length > 0) {
            paramsMore.lastTime =
                state.listJoinedMore[state.listJoinedMore.length - 1].created;
        }
        actions.getListJoinedMore(paramsMore).then((data: any) => {
            setLoadingMore(false);
            if (data.length === 0) setEnableLoadMore(false);
        });
    };

    const Item = ({ item }: any) => {
        const handleOpenDetails = () => {
            window.location.replace(`/ido-details?key=${item?.keywork}`)
        }

        return (
            <>
                <tr className="tr-bottom">
                    <td>
                        <div className="box-name-pj" onClick={handleOpenDetails}>
                            <img src={item?.tokenInfo?.logoUrl} alt="" /> {langApi === "cn" ? item?.name_cn : langApi === "ru" ? item?.name_ru : item?.name}
                        </div>
                    </td>
                    <td>
                        {item?.nextClaim}
                    </td>
                    <td>
                        {item?.allocation} {item?.keywork}
                    </td>
                    <td>
                        <span className="main-color">{item?.claimed} {item?.keywork}</span>
                    </td>
                    <td>
                        <div className="gr-action">
                            <button className="bnt-action" onClick={handleOpenDetails}>
                                {t('ido.ido-28', { framework: 'React' })}
                            </button>
                        </div>
                    </td>
                </tr>
            </>
        )
    }

    return (
        <>
            <div className="group-upcoming res">
                <div className="row-content">
                    <div className="search-project-row">
                        <Search type="search" onSearch={onSearch} className="input-search" placeholder={`${t('ido.ido-16', { framework: 'React' })}`} />
                    </div>
                    <div className="main-table">
                        <table className="table-ido">
                            <tr>
                                <th>
                                    {t('ido.ido-17', { framework: 'React' })}
                                </th>
                                <th>
                                    {t('ido.ido-24', { framework: 'React' })}
                                </th>
                                <th>
                                    {t('ido.ido-25', { framework: 'React' })}
                                </th>
                                <th>
                                    {t('ido.ido-26', { framework: 'React' })}
                                </th>
                                <th>
                                    {t('ido.ido-27', { framework: 'React' })}
                                </th>
                            </tr>
                            {state.joinedIDO.length === 0 ?
                                <>
                                    <tr>
                                        <td colSpan={6}>
                                            <div className="empty-data">
                                                <img src="./images/empty-data-1.png" alt="" />
                                            </div>
                                        </td>
                                    </tr>
                                </>
                                :
                                <>
                                    {state.joinedIDO.map((item: any, index: any) => (
                                        <>
                                            <Item item={item} />
                                        </>
                                    ))}
                                    {state.listJoinedMore.map((item: any, index: any) => (
                                        <>
                                            <Item item={item} />
                                        </>
                                    ))}
                                </>
                            }
                        </table>
                        {enableLoadMore && state?.joinedIDO.length !== 0 ?
                            <>
                                <div className="btn-more-row">
                                    {loadingMore ?
                                        <>
                                            <button className="bnt-more">
                                                <Spin /> {t('ido.ido-23', { framework: 'React' })}
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button className="bnt-more" onClick={handleMore}>
                                            {t('ido.ido-23', { framework: 'React' })}
                                            </button>
                                        </>
                                    }
                                </div>
                            </>
                            :
                            <>
                                {""}
                            </>
                        }
                    </div>
                </div>
                <div className="box-apply-row">
                    <BoxApply />
                </div>
            </div>
        </>
    )
}
export default TabsJoined