import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { BIG_TEN } from "./bigNumber";
import web3 from "web3";

const convertNumber = (value) => {
  return web3.utils.toWei(value.toString(), "ether");
};
export const approve = async (lpContract, masterChefContract, account) => {
  return lpContract.methods
    .approve(masterChefContract.options.address, ethers.constants.MaxUint256)
    .send({ from: account });
};

export const approveSousChef = async (contract, poolAddress, account) => {
  
    const result = await contract.approve(
      poolAddress,
      ethers.constants.MaxUint256
    );
    return result;
  
};
export const sousStake = async (souschefContract, amount, decimals) => {
  
    const result = await souschefContract.deposit(
      new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed()
    );
    return result;
  
};

export const soushHarvestUserLock = async (souschefContract, account) => {
  
    const result = await souschefContract.withdrawNew(true);
    return result;
  
};

export const soushHarvestNew = async (souschefContract, account) => {
  
    const result = await souschefContract.withdrawNew(true);
    return result;
  
};

export const soushHarvest = async (souschefContract, account) => {
  
    const result = await souschefContract.withdraw("0");
    return result;
  
};

export const soushHarvestV3 = async (souschefContract, account) => {
  
    const result = await souschefContract.withdraw(true);
    return result;
  
};

export const sousUnstake = async (souschefContract, amount, decimals) => {
  
    const result = await souschefContract.withdraw(
      new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed()
    );
    return result;
  
};

export const sousUnstakeUserLock = async (
  souschefContract,
  amount,
  decimals
) => {
  
    const result = await souschefContract.withdrawNew(false);
    return result;
  
};
export const sousUnstakeV3 = async (souschefContract, amount, decimals) => {
  
    const result = await souschefContract.withdraw(false);
    return result;
  
};
export const getTokenStake = async (contract,pid, account) => {
  
    const result = await contract.userInfo(account);
    return result
  
};
export const getRewards = async (contract,pid, account) => {
  
    const result = await contract.pendingReward(account);
    return result
  
};
export const stake = async (masterChefContract, pid, amount, account, ref) => {
  return masterChefContract.methods
    .deposit(pid, ethers.utils.parseUnits(amount, 18))
    .send({ from: account })
    .on("transactionHash", (tx) => {
      return tx.transactionHash;
    });
};

export const sousStakeV3 = async (
  souschefContract,
  amount,
  decimals,
  account
) => {
  return souschefContract.methods
    .deposit(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed())
    .send({ from: account })
    .on("transactionHash", (tx) => {
      return tx.transactionHash;
    });
};

export const unstake = async (masterChefContract, pid, amount, account) => {
  return masterChefContract.methods
    .withdraw(pid, ethers.utils.parseUnits(amount, 18))
    .send({ from: account })
    .on("transactionHash", (tx) => {
      return tx.transactionHash;
    });
};

export const harvest = async (masterChefContract, pid, account) => {
  return masterChefContract.methods
    .deposit(pid, "0")
    .send({ from: account })
    .on("transactionHash", (tx) => {
      return tx.transactionHash;
    });
};

export const getEarned = async (masterChefContract, pid, account) => {
  return masterChefContract.methods.pendingRewards(pid, account).call();
};

export const getRewardPerBlock = async (masterChefContract) => {
  return masterChefContract.methods.rewardPerBlock().call();
};

export const getStaked = async (masterChefContract, pid, account) => {
  try {
    const { amount } = await masterChefContract.methods
      .userInfo(pid, account)
      .call();
    return new BigNumber(amount);
  } catch {
    return new BigNumber(0);
  }
};
