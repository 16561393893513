import React , {useEffect} from 'react';
import BannerPac from  "../../pages/home-page/images/banner-parker.png"
import { useTranslation } from "react-i18next";

const BoxApply = () => {
    const { t, i18n }: any = useTranslation('common');
    const handleClickOpen = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLScrFmz9q3MS3Ylgo23JETiaV3qA0WV2iehByMEzObxJokbREQ/viewform")
    }
    return (
        <>
            <div className="box-parker" style={{backgroundImage: `url(${BannerPac})`}}>
                <div className="parker-content">
                    <div className="logo">
                        <img src="./images/logo.png" alt="" />
                    </div>
                    <div className="txt">
                    {t('home.home-25', { framework: 'React' })}
                    </div>
                    <div className="gr-button-parker">
                        <button className='btn btn-parker' type="button" onClick={handleClickOpen}>
                        {t('home.home-26', { framework: 'React' })}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BoxApply