import { instance } from '../../apiBase/instance';

export const BaseApi = {
	getPoolList() {
		return instance.get(`pool/all`, {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	poolSet(data) {
		return instance.post(`pool/set`, data, {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	getPartnerList() {
		return instance.get(`pool/partners`, {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
};
