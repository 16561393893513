import BigNumber from 'bignumber.js'
import { getSousChefContract } from '../../utilsST/contractHelpers'
import poolsConfig from '../../constantsST/pools'
import erc20Abi from '../../constantsST/abi/erc20.json'
import multicall from '../../utilsST/multicall'
import { BIG_ZERO } from '../../utilsST/bigNumber'

export const fetchPoolsTotalStaking = async (poolList) => {
	
	const callsNonBnbPools = poolList.map((poolConfig) => {
		return {
			address: poolConfig.stakingToken.address,
			name: 'balanceOf',
			params: [poolConfig.contractAddress],
		}
	})

	const poolsTotalStaked = await multicall(erc20Abi, callsNonBnbPools)

	return [
		...poolList.map((p, index) => ({
			sousId: p.id,
			totalStaked: new BigNumber(poolsTotalStaked[index]).toJSON(),
		})),
	]
}

export const fetchPoolStakingLimit = async (sousId) => {
	try {
		const sousContract = getSousChefContract(sousId)
		const stakingLimit = await sousContract.methods.poolLimitPerUser().call()
		return new BigNumber(stakingLimit.toString())
	} catch (error) {
		return BIG_ZERO
	}
}

export const fetchPoolsStakingLimits = async (poolsWithStakingLimit) => {
	const validPools = poolsConfig
		.filter((p) => p.stakingToken.symbol !== 'BNB' && !p.isFinished)
		.filter((p) => !poolsWithStakingLimit.includes(p.sousId))

	// Get the staking limit for each valid pool
	// Note: We cannot batch the calls via multicall because V1 pools do not have "poolLimitPerUser" and will throw an error
	const stakingLimitPromises = validPools.map((validPool) =>
		fetchPoolStakingLimit(validPool.sousId),
	)
	const stakingLimits = await Promise.all(stakingLimitPromises)

	return stakingLimits.reduce((accum, stakingLimit, index) => {
		return {
			...accum,
			[validPools[index].sousId]: stakingLimit,
		}
	}, {})
}
