import React, { useEffect, useState } from 'react';
import { useActiveWeb3React } from "../../hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip, message, Modal, Tabs, Row, Col, Select } from 'antd';
import { DownCircleOutlined } from '@ant-design/icons';
import type { TabsProps } from 'antd';
import { useHookAccount } from './store';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next";
import RefIdo from './ref/ref-ido';
import RefRedeem from './ref/ref-redeem';
import RefLeaderBoard from './ref/ref-leaderboard';
import RefReferral from './ref/ref-referral';
import QRCode from 'react-qr-code';

const Referrals = () => {
    const { t, i18n }: any = useTranslation('common');
    const { Option } = Select;
    const { account, chainId }: any = useActiveWeb3React();
    const [state, actions]: any = useHookAccount();
    let hisTab = localStorage.getItem('activeTabs');
    const [activeTabs, setActiveTabs] = useState(hisTab ? hisTab : 'ido');
    const [show , setShow] = useState(true)
    const [valueBind , setValueBind] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [valueFilterFirst, setValueFilterFirst]:any = useState(-1);
    const [valueFilterSecond, setValueFilterSecond]:any = useState(-1);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const itemsTabs: TabsProps['items'] = [
        {
            key: 'referrals-child',
            label: 'Referrals',
            children: <><RefReferral /></>,
        },
        {
            key: 'ido',
            label: 'IDO',
            children: <><RefIdo /></>,
        },
        {
            key: 'redeem',
            label: 'Redeem',
            children: <><RefRedeem /></>,
        },
        {
            key: 'leaderboard',
            label: 'Leaderboard',
            children: <><RefLeaderBoard /></>,
        },
    ];

    const onChangeTab = (key: string) => {
        console.log(key);
        localStorage.setItem('activeTabs', key);
        setActiveTabs(key);
    };

    let params = {
        ownerAddress: account
    }

    useEffect(() => {
        if (account) {
            actions.getRefCode(params)
        }
    }, [account]);

    const onChangeBind = (e:any) => {
        setValueBind(e.target.value)
    }

    const handleBind = () => {
        let paramsRef = {
            ownerAddress: account,
            refCode: valueBind,
            isForce: true
        }
        actions.getUseRefCode(paramsRef).then((res:any) => {
            console.log('res', res.data.message)
            if(res.data.message === null) {
                message.success({
                    type: "success",
                    content: "Bind success",
                    className: "custom-class",
                    duration: 2,
                });
                actions.getRefCode(params)
            } else {
                message.error({
                    type: "error",
                    content: res.data.message,
                    className: "custom-class",
                    duration: 2,
                });
            }
        })

    }

    const handleChange = (value: string) => {
        setValueFilterSecond(value)
        let paramsDashboard = {
            ownerAddress: account,
            idoId: Number(valueFilterFirst),
            range: Number(value)
        }
        actions.getDashboard(paramsDashboard)
    };

    const handleChangeIdo = (value:any) => {
        setValueFilterFirst(value)
        let paramsDashboard = {
            ownerAddress: account,
            idoId: Number(value),
            range: Number(valueFilterSecond)
        }
        actions.getDashboard(paramsDashboard)
    }

    let paramsDashboard = {
        ownerAddress: account,
        idoId: -1,
        range: -1
    }

    useEffect(() => {
        if(account) {
            actions.getDashboard(paramsDashboard)
        }
    }, [account]);

    useEffect(() => {
        actions.getListIdoFilter()
    }, []);

    const handleShow = () => {
        setShow(!show)
    }

    return (
        <>
            <div className="referrals-user mt-30">
                <Row gutter={30}>
                    <Col xs={24} sm={12}>
                        <div className='referrals-title'>
                            {t('ref.ref-1', { framework: 'React' })}
                        </div>
                        <div className='referrals-desc'>
                            {t('ref.ref-2', { framework: 'React' })}
                        </div>
                    </Col>
                    <Col xs={24} sm={12}>
                        <div className='my-refferal'>
                            <div className='title'>
                                {t('ref.ref-3', { framework: 'React' })}
                            </div>
                            <div className='body'>
                                <div className='link'>
                                    {state.dataRef.useRefCode === null ?
                                        <>
                                            <div className="text-warning">
                                                {t('ref.ref-5', { framework: 'React' })}
                                            </div>
                                        </>
                                        :
                                        <>
                                        <div className='ref'>{window.location.origin}/profile?ref={state.dataRef.refCode?.slice(0, 4)}...{state.dataRef.refCode?.slice(-4)}</div>
                                        <CopyToClipboard
                                            text={`${window.location.origin}/profile?ref=${state.dataRef.refCode}`}
                                            onCopy={() => {
                                                message.success({
                                                    type: "success",
                                                    content: `${t('ido.ido-61', { framework: 'React' })}`,
                                                    className: "custom-class",
                                                    duration: 2,
                                                });
                                            }}
                                        >
                                            <div className='copy'><img src='../images/copy.svg' /></div>
                                        </CopyToClipboard>
                                            </>
                                        }
                                </div>
                                <p className='my-ref' onClick={handleShow}>
                                    <span>{t('ref.ref-4', { framework: 'React' })}</span>
                                    {show ? <img src="./images/arrow-circle-up.png" alt="" /> : <img src="./images/arrow-circle-down.png" alt="" />}
                                </p>
                                {show ?
                                    <>
                                        {state.dataRef.useRefCode === null ?
                                            <>
                                                <div className="ref-add-hand">
                                                    <input type="text" value={valueBind === null && valueBind === "" && valueBind === undefined ? valueBind : state.dataRef.useRefCode} onChange={onChangeBind} placeholder={t('ref.ref-5', { framework: 'React' })} className='f-control-add' />
                                                    {state.dataRef.useRefCode === null ?
                                                        <>
                                                            <button className='btn-bind' onClick={handleBind}>
                                                            {t('ref.ref-6', { framework: 'React' })}
                                                            </button>
                                                        </>
                                                        :
                                                        <>
                                                            <button className='btn-bind dis'>
                                                            {t('ref.ref-7', { framework: 'React' })}
                                                            </button>
                                                        </>
                                                    }
                                                </div>
                                            </>
                                            :
                                            <>
                                            <div className="ref-add-hand custome">
                                                 <input type="text" value={state.dataRef.useRefCode} placeholder={t('ref.ref-5', { framework: 'React' })} className='f-control-add' />
                                                 </div>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        {""}
                                    </>
                                }
                            </div>
                            <div className='share-to'>
                                <p className='text-blue'>{t('ref.ref-8', { framework: 'React' })}</p>
                                <div className='dflex-left gap-10'>
                                    <a onClick={showModal}><img src='../images/share-1.svg' /></a>
                                    <a href={`https://twitter.com/intent/tweet?hashtags=https://${window.location.origin}/profile?ref=${state.dataRef.refCode}`} target='blank'><img src='../images/x.svg' /></a>
                                    <a href={`https://telegram.me/share/url?text=&url=https://${window.location.origin}/profile?ref=${state.dataRef.refCode}`} target='blank'><img src='../images/tele.svg' /></a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col xs={24} sm={10}>
                        <div className='block-ref-border'>
                            <div className='dflex-center'>
                                <span className='text-white weight-500 font-16'>{t('ref.ref-9', { framework: 'React' })}</span>
                                <div className='dflex-center gap-15'>
                                    <div className='slt-filter slt-custom'>
                                        <Select
                                            defaultValue="-1"
                                            style={{ width: 130 }}
                                            onChange={handleChangeIdo}
                                        >
                                            <Option value="-1">
                                                <span className="text-chain">{t('ref.ref-12', { framework: 'React' })}</span>
                                            </Option>
                                            {state.dataGetListIdoFilter.length > 0 && state.dataGetListIdoFilter?.map((item: any, index: any) => (
                                                <Option value={item?.id}>
                                                    <span className="text-chain">{item?.name}</span>
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className='slt-filter slt-custom row'>
                                        <Select
                                            defaultValue={t('ref.ref-12', { framework: 'React' })}
                                            style={{ width: 130 }}
                                            onChange={handleChange}
                                            options={[
                                                { value: -1, label: t('ref.ref-12', { framework: 'React' }) },
                                                { value: 1, label: t('ref.ref-13', { framework: 'React' }) },
                                                { value: 2, label: t('ref.ref-14', { framework: 'React' }) },
                                                { value: 3, label: t('ref.ref-15', { framework: 'React' }) }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='dashboard-info'>
                                <div className='dflex-left gap-10'>
                                    <div className=''>
                                        <img src='../images/d1.svg' />
                                    </div>
                                    <div className='d-grid'>
                                        <span className='text-white font-18 weight-500'>{state.dataDashboard.totalRefPoint}</span>
                                        <span className='text-white'>{t('ref.ref-10', { framework: 'React' })}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='dashboard-info'>
                                <div className='dflex-left gap-10'>
                                    <div className=''>
                                        <img src='../images/d1.svg' />
                                    </div>
                                    <div className='d-grid'>
                                        <span className='text-white font-18 weight-500'>{state.dataDashboard.totalUser}</span>
                                        <span className='text-white'>{t('ref.ref-11', { framework: 'React' })}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={14}>
                        <div className='block-ref-border'>
                            <span className='text-white weight-500 font-16'>{t('ref.ref-16', { framework: 'React' })}</span>
                            <Row gutter={30}>
                                <Col xs={24} sm={7}>
                                    <div className='hiw'>
                                        <img src='../images/hiw1.svg' />
                                        <div className='title'>{t('ref.ref-17', { framework: 'React' })}</div>
                                        <div className='desc'>{t('ref.ref-18', { framework: 'React' })}</div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={1}>
                                    <div className='has-arrow'>
                                        <img src='../images/hiw-arrow.svg' />
                                    </div>
                                </Col>
                                <Col xs={24} sm={7}>
                                    <div className='hiw'>
                                        <img src='../images/hiw2.svg' />
                                        <div className='title'>{t('ref.ref-19', { framework: 'React' })}</div>
                                        <div className='desc'>{t('ref.ref-20', { framework: 'React' })}</div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={1}>
                                    <div className='has-arrow'>
                                        <img src='../images/hiw-arrow.svg' />
                                    </div>
                                </Col>
                                <Col xs={24} sm={7}>
                                    <div className='hiw'>
                                        <img src='../images/hiw3.svg' />
                                        <div className='title'>{t('ref.ref-21', { framework: 'React' })}</div>
                                        <div className='desc'>{t('ref.ref-20', { framework: 'React' })}</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div className='mt-40 text-left'>
                    <Tabs className='ref-tabs' activeKey={activeTabs} items={itemsTabs} onChange={onChangeTab} />
                </div>
            </div>
            <Modal title="Warning!" className="modal-redeem res" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="title-modal">
                    {t('ref.ref-44', { framework: 'React' })}
                </div>
                <div className="modal-code">
                    <QRCode value={`${window.location.origin}/profile?ref=${state.dataRef.refCode}`} />
                </div>
                <div className="gr-button-qr">
                    <button className="btn btn-main mar-0-5" onClick={handleOk}>
                        {t('ref.ref-45', { framework: 'React' })}
                    </button>
                    <button className="btn btn-main mar-0-5" onClick={handleCancel}>
                        {t('ref.ref-46', { framework: 'React' })}
                    </button>
                </div>
            </Modal>
        </>
    )
}
export default Referrals