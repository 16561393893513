import React, { forwardRef, memo, useCallback } from 'react'

import { InputBSCProps, InputBSCRef } from './index.d'
import './index.less'

const Input = memo<InputBSCProps>(
	forwardRef<InputBSCRef, InputBSCProps>((props) => {
		const { left, right, value, placeholder, change, type="number", readonly } = props
		const handleChange = useCallback(
			(e: any) => {
				if (change) {
					change(e)
				}
			},
			[change],
		)
		return (
			<div className="bsc-input">
				{left && <div className="bsc-input-left">{left}</div>}
				<input
					{...(value ? { value } : {})}
					onChange={handleChange}
					type={type}
					placeholder="0"
					readOnly={readonly}
				/>

				{right && <div className="bsc-input-right">{right}</div>}
			</div>
		)
	}),
)
export * from './index.d'
export default Input
