/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_URL } from "../../constants/index";
import { createStore, createHook, createContainer, createSubscriber } from "react-sweet-state";

const StoreProjects = createStore({
    initialState: {
        upcomingIDO: [],
        openIDO: [],
        endedIDO: [],
        joinedIDO: [],
        detailsIDO: [],
        dataFullSearch: {},
        isSubmit: false,
        dataCommitedJoined: {},
        listJoinedMore:[],
        listEndedMore: [],
        dataOwner:{}
    },
    actions: {
        getUpcomingIDO: (params) =>
            async ({ setState }) => {
                let res = null;
                try {
                    res = await axios.post(`${API_URL}launchpad/upcoming`, params);
                    setState({ upcomingIDO: res?.data?.data || [] });
                } catch (err) {
                    // TODO
                }
                return res;
            },
        getOpenIDO: () =>
            async ({ setState }) => {
                let res = null;
                try {
                    res = await axios.get(`${API_URL}launchpad/open_idos`);
                    setState({ openIDO: res?.data?.data || [] });
                } catch (err) {
                    // TODO
                }
                return res;
            },
        getEndedIDO: (params) =>
            async ({ setState }) => {
                let res = null;
                try {
                    res = await axios.post(`${API_URL}launchpad/ended_idos`, params);
                    setState({ endedIDO: res?.data?.data || [] });
                } catch (err) {
                    // TODO
                }
                return res;
            },
        getJoinedIDO: (params) =>
            async ({ setState }) => {
                let res = null;
                try {
                    res = await axios.post(`${API_URL}launchpad/joined_idos`, params);
                    setState({ joinedIDO: res?.data?.data || [] });
                } catch (err) {
                    // TODO
                }
                return res;
            },
        getDetailsIDO: (params) =>
            async ({ setState }) => {
                let res = null;
                try {
                    res = await axios.post(`${API_URL}launchpad/detail`, params ,);
                    setState({ detailsIDO: res?.data?.data || [] });
                } catch (err) {
                    // TODO
                }
                return res;
            },
        fullSearchIDO: (params) =>
            async ({ setState }) => {
                let res = null;
                try {
                    res = await axios.post(`${API_URL}launchpad/full_reasearch`, params);
                    setState({ dataFullSearch: res?.data || [] });
                } catch (err) {
                    // TODO
                }
                return res;
            },
        submitAllocation: (params) =>
            async ({ setState }) => {
                let res = null;
                try {
                    res = await axios.post(`${API_URL}launchpad/commit_fund`, params);
                    setState({ isSubmit: res.data.succeeded });
                } catch (err) {
                    // TODO
                }
                return res;
            },
        commitedJoined: (params) =>
            async ({ setState }) => {
                let res = null;
                try {
                    res = await axios.post(`${API_URL}launchpad/commited`, params);
                    setState({ dataCommitedJoined: res.data.data });
                } catch (err) {
                    // TODO
                }
                return res;
            },
        getListJoinedMore:
        (params) =>
        async ({ setState, getState }) => {
            return new Promise((resolve, reject) => {
                axios.post(`${API_URL}launchpad/joined_idos`, params).then((res) => {
                    setState({
                        listJoinedMore:
                            getState().listJoinedMore.concat(res.data.data) || [],
                    });

                    const {
                        data: { data },
                    } = res;
                    resolve(data);
                });
            });
        },
        getListEndedMore:
        (params) =>
        async ({ setState, getState }) => {
            return new Promise((resolve, reject) => {
                axios.post(`${API_URL}launchpad/ended_idos`, params).then((res) => {
                    setState({
                        listJoinedMore:
                            getState().listEndedMore.concat(res.data.data) || [],
                    });

                    const {
                        data: { data },
                    } = res;
                    resolve(data);
                });
            });
        },
        getOwnerAddress: (params) =>
        async ({ setState }) => {
            let res = null;
            try {
                res = await axios.post(`${API_URL}launchpad/owner`, params);
                setState({ dataOwner: res?.data.data || {} });
            } catch (err) {
                // TODO
            }
            return res;
        },
    }
});
export const useHookProjects = createHook(StoreProjects);
export const Container = createContainer(StoreProjects, {
    onInit:
        () =>
            ({ setState }, props) => {
                setState({ ...props });
            },
});
export const Subscriber = createSubscriber(StoreProjects);