import axios from "axios";
import { Cookies } from "react-cookie";
import encodeAddress from "./utils/encodeData";
axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8";

function ignore(config: any) {
    return config.url.indexOf("https://fullnode") === -1;
}

let now = new Date;
let utc_timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(),now.getUTCDate(),now.getUTCHours(),now.getUTCMinutes(), now.getUTCSeconds()-now.getUTCSeconds()%10); 
let time_header = utc_timestamp/1000

// set interceptors requests
axios.interceptors.request.use(
    function (config: any) {
        if (config && config.url?.indexOf("bscscan") !== -1) {
            return config;
        }
        const cookies = new Cookies();
        let cookieHeader = (cookies.get("user") || "") + time_header

        config.headers["Token-Authentication"] = encodeAddress(cookieHeader.toLowerCase());
        return config;
    },
    function (error) {
        return Promise.reject(error);

    },

    { runWhen: ignore }

);

// set interceptors responses
axios.interceptors.response.use(
    function (response) {
        return response;
    }
);

