
import React, { useEffect, useCallback, useRef, useState } from "react";
import { Tabs, Collapse, Input, Switch, Select, Tooltip, Empty } from "antd";
import bgMainheader from "./images/pools.png";
import { useFetchPublicPoolsData, usePollBlockNumber } from "../../store/hooks";
import ErrorPage from "../ErrorPage";
import { usePools } from "../../store/hooks";
import { QuestionCircleOutlined, CheckOutlined } from "@ant-design/icons";
import "./style.css";
import { useWeb3React } from "@web3-react/core";
import FarmItem from "./FarmlItem";
// import { useHookPrice } from "../../components/common/Store";

const { TabPane } = Tabs;
const { Search } = Input;

const FarmPool = () => {
  // const [state1, actions1] = useHookPrice();
  const { account } = useWeb3React();
  // const { pools, userDataLoaded } = usePools(account);

  
  // usePollBlockNumber();
  // useFetchPublicPoolsData();
  let curentChain = localStorage.getItem("chain");
  

  const [checked, setchecked] = useState(false);
  const [defaultChecked, setdefaultChecked] = useState(false);
  function onChange(checked) {
    if (checked === true) {
      setchecked(true);
      setdefaultChecked(true);
    } else {
      setchecked(false);
      setdefaultChecked(false);
    }
  }
  const [searchQuery, setSearchQuery] = useState("");

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const [searchChain, setSearchChain] = useState(0);

  const textTooltip = (
    <div>
      <div className="text-tooltip">
        <span className="icon">
          <CheckOutlined />
        </span>
        <span className="text">Calculated based on current rates.</span>
      </div>
      <div className="text-tooltip">
        <span className="icon">
          <CheckOutlined />
        </span>
        <span className="text">
          All APY are estimation and by it does not represent guaranteed
          returns.
        </span>
      </div>
    </div>
  );

  const contentTop = (
    <>
      <div className="main-round-pool">
        <div className="round-slide LP">
          <div className="item">
            <span className="name">Project Token</span>
          </div>
          <div className="item">
            <span className="name">APR</span>
          </div>

          <div className="item">
            <span className="name">Total staked</span>
          </div>
          <div className="item">
            <span className="name">Rewards end in</span>
          </div>
          <div className="item">
            <span className="name">Earned</span>
          </div>
        </div>
      </div>
    </>
  );

  // useEffect(() => {
  //   document.title = "Stake token to earn - BSCS (BSCS)";
  //   document
  //     .getElementsByTagName("meta")
  //     .item(1)
  //     ?.setAttribute(
  //       "content",
  //       "The fully decentralized protocol for launching new ideas An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks."
  //     );
  // }, []);

  const handleTabChange = (key) => {
    if (key === '2') {
      // window.open('https://stake.bscstation.finance/', '_blank');
      
    }
  };
  return (
    <>
      
        
      <div className="startpool-banner">
        <div className="pool-title">Farms</div>
        <div className="pool-des">
          <p className="mb-5">Stake Liquidity Pool (LP) tokens to earn. </p>
          <p className="mb-5">Rewards are calculated per block.</p>
        </div>
      </div>
      <div className="container">
        <div className="main-startpool">
          <div className="content-startpool">
            <div className="bottom-startpool">
              <div className="tabs-startpool">
                <div className="filter-swap">
                  <div className="switch-filter">
                    <Switch onChange={onChange} /> <span>Staked only</span>
                  </div>
                  <div className="box-search-round">
                    <div className="search-project earn">
                      <Search
                        placeholder="Search Staking"
                        type="text"
                        onChange={handleChangeSearchQuery}
                      />
                      <div className="icon-search">
                        <img src="/images/imgido/search-normal.png" alt="" />
                      </div>
                    </div>
                    {/* <div className="filter-project dis-991">
                      <Select
                        defaultValue="Sort by"
                        style={{ width: 125 }}
                        onChange={handleChangeSort}
                      >
                        <Option value={0}>BNB</Option>
                        <Option value={1}>ETH</Option>
                        <Option value={2}>POLY</Option>
                      </Select>
                    </div> */}
                  </div>
                </div>
                <Tabs
                    className="tabs-round"
                    defaultActiveKey="1"
                    onChange={handleTabChange}
                  >
                    <TabPane tab="Live" key="1">
                      <>
                        {contentTop}
                        {defaultChecked === true ? (
                          <>
                            <div className="mt-15 text-center">
                          <img width={200} src="../images/empty.png" alt="" />
                        </div>
                            {/* <FarmItem pool={null} userDataLoaded={null} /> */}
                            {/* {pools
                              .filter(
                                (pool) =>
                                  pool.userData.stakedBalance > 0 &&
                                  // pool.chain === searchChain &&
                                  pool.isFinished === false &&
                                  pool.stakingToken.symbol
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                              )
                              .map((pool, index) => (
                                <FarmList
                                  key={index}
                                  pool={pool}
                                  userDataLoaded={userDataLoaded}
                                />
                              ))} */}
                          </>
                        ) : (
                          <>
                          <div className="mt-15 text-center">
                          <img width={200} src="../images/empty.png" alt="" />
                        </div>
                            {/* <FarmItem pool={null} userDataLoaded={null} /> */}
                            {/* {pools
                              .filter(
                                (pool) =>
                                  pool.isFinished === false &&
                                  // pool.chain === searchChain &&
                                  pool.stakingToken.symbol
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                              )
                              .map((pool, index) => (
                                <FarmList
                                  key={index}
                                  pool={pool}
                                  userDataLoaded={userDataLoaded}
                                />
                              ))} */}
                          </>
                        )}
                      </>
                    </TabPane>
                    <TabPane tab="Finished" key="2">
                      <>
                        {contentTop}
                        <div className="mt-15 text-center">
                          <img width={200} src="../images/empty.png" alt="" />
                        </div>
                        {/* {defaultChecked === true ? (
                          <>
                            {pools
                              .filter(
                                (pool) =>
                                  pool.userData.stakedBalance > 0 &&
                                  // pool.chain === searchChain &&
                                  pool.isFinished === true &&
                                  pool.stakingToken.symbol
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                              )
                              .map((pool, index) => (
                                <FarmList
                                  key={index}
                                  pool={pool}
                                  userDataLoaded={userDataLoaded}
                                />
                              ))}
                          </>
                        ) : (
                          <>
                            {pools
                              .filter(
                                (pool) =>
                                  pool.isFinished === true &&
                                  // pool.chain === searchChain &&
                                  pool.stakingToken.symbol
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                              )
                              .map((pool, index) => (
                                <FarmList
                                  key={index}
                                  pool={pool}
                                  userDataLoaded={userDataLoaded}
                                />
                              ))}
                          </>
                        )} */}
                      </>
                    </TabPane>
                  </Tabs>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      
    </>
  );
};
export default FarmPool;
