import React from 'react';
import "../style.scss"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t, i18n }: any = useTranslation('common');
    const handleClickOpen = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLScrFmz9q3MS3Ylgo23JETiaV3qA0WV2iehByMEzObxJokbREQ/viewform")
    }
    return (
        <>
            <footer className="main-footer">
                <div className="bg-ft">
                    <img src="./images/bg-ft.png" alt="" />
                </div>
                <div className="container">
                    <div className="row-footer">
                        <div className="row-l">
                            <div className="logo">
                                <Link to="/">
                                    <img src="./images/logo-ft.png" alt="" />
                                </Link>
                            </div>
                            <div className="desc">
                                {t('footer.footer-1', { framework: 'React' })}
                            </div>
                            <div className="mail">
                                <a href="mailto:contact@FVLaunch.io"><img src="./images/email.png" alt="" /> contact@FVLaunch.io</a>
                            </div>
                            <div className="social">
                                <div className="item">
                                    <a href="https://twitter.com/fv_launch" target="_blank"><img src="./images/x.png" alt="" /></a>
                                </div>
                                <div className="item">
                                    <a href="https://telegram.me/fvlaunch" target="_blank"><img src="./images/tele.png" alt="" /></a>
                                </div>
                                <div className="item">
                                    <a href="https://www.youtube.com/@FVLaunch" target="_blank"><img src="./images/you.png" alt="" /></a>
                                </div>
                                <div className="item">
                                    <a href="https://linktr.ee/fvlaunch" target="_blank"><img src="./images/lw.png" alt="" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="row-r">
                            <div className="columns">
                                <div className="colum w-4">
                                    <div className="text">
                                        {t('footer.footer-2', { framework: 'React' })}
                                    </div>
                                    <ul className="list-menu-ft">
                                        <li>
                                            <a href="#">{t('footer.footer-3', { framework: 'React' })}</a>
                                        </li>
                                        <li>
                                            <a href="#">{t('footer.footer-4', { framework: 'React' })}</a>
                                        </li>
                                        <li>
                                            <a href="#">{t('footer.footer-5', { framework: 'React' })}</a>
                                        </li>
                                        <li>
                                            <Link to="/ido-pools">{t('footer.footer-6', { framework: 'React' })}</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="colum w-4">
                                    <div className="text">
                                        {t('footer.footer-7', { framework: 'React' })}
                                    </div>
                                    <ul className="list-menu-ft">
                                        <li>
                                            <Link to="/ido">{t('footer.footer-8', { framework: 'React' })}</Link>
                                        </li>
                                        <li>
                                            <Link to="/ido-pools">{t('footer.footer-9', { framework: 'React' })}</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="colum w-4">
                                    <div className="text">
                                        {t('footer.footer-10', { framework: 'React' })}
                                    </div>
                                    <ul className="list-menu-ft">
                                        <li>
                                            <a href="#" onClick={handleClickOpen}>{t('footer.footer-11', { framework: 'React' })}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copy-ft">
                {t('footer.footer-12', { framework: 'React' })}
            </div>
        </>
    )
}
export default Footer 