import React, { useEffect, useState } from 'react';
import { useActiveWeb3React } from "../../hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip, message, Modal, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useHookAccount } from './store';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./style.scss"
import { useTranslation } from "react-i18next";
import MyAccount from './my-acccount';
import Referrals from './ref';



const ProfileUser = () => {
    const { t, i18n }: any = useTranslation('common');
    const { account, chainId }: any = useActiveWeb3React();
    const [state, actions]: any = useHookAccount();
    let hisTab = localStorage.getItem('keyTab');
    const [activeTab, setActiveTab] = useState<any>(hisTab ? hisTab : 'my-account');


    const params = {
        ownerAddress: account
    }

    useEffect(() => {
        account && actions.getOwnerAddress(params);
    }, [account]);

    const paramsLink = {
        ownerAddress: account,
    }

    useEffect(() => {
        account && actions.getWalletAccount(paramsLink)
    }, [account]);

    const itemsTab: TabsProps['items'] = [
        {
            key: 'my-account',
            label: <>{t('profile.pro-8', { framework: 'React' })}</>,
            children: <><MyAccount /></>,
        },
        {
            key: 'referrals',
            label: <>{t('profile.referrals', { framework: 'React' })}</>,
            children: <Referrals />,
        }
    ];
    const onChangeTabs = (key: string) => {
        localStorage.setItem('keyTab', key);
        setActiveTab(key);
    };

    return (
        <>
            <section className="main-profile-user">
                <div className="container">
                    {/* Tabs my account - reffer */}
                    <Tabs className="tabs-custome mt-20" defaultActiveKey={activeTab} centered items={itemsTab} onChange={onChangeTabs} />
                </div>
            </section>
            
            
        </>
    )
}
export default ProfileUser